<app-header></app-header>
<div class="hero">
  <div class="hero__header">
    <div class="hero__header--title">Blogs and Resources</div>
    <app-blog-search></app-blog-search>
  </div>
  <div class="character__right">
    <img src="assets/images/blogs.png" alt="blog-icon" />
  </div>
</div>

@if(category$ | async; as category){
  <div class="page">
    <main>
      <h1 class="categories__title">Explore {{category.name}} blogs</h1>
      @if(blogs$ | async; as blogs){
        @if (blogs.length > 0) {
          <div class="blog__list">
            @for(blogObj of blogs;track blogObj.documentId){
              <app-blog-card 
                [blogObj]="blogObj"
                [categoryId]="typeId"
              ></app-blog-card>
            }

            @if(moreBlogsRemaining){
              <div class="loading-spinner" appInfiniteScroll (scrolled)="loadMoreBlogs()">
                <p>Loading more blogs...</p>
              </div>
            }
          </div>
        }
        @else {
          <div>No blogs available for the category {{category.name}}</div>
          <a class="no-results-button" routerLink="/blogs">
            <button class="btn btn-outline-primary">Go to Blogs Home</button>
          </a>
        }
      }
    </main>
  </div>
}

<!-- <app-trending-searches></app-trending-searches> -->
<app-footer></app-footer>
