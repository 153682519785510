<app-header></app-header>
@if(breadcrumbs$ | async; as breadcrumbs){
  <div class="breadcrumb__wrapper d-none d-sm-block">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="breadcrumbs[0].route">Home</a>
        </li>
        <li class="breadcrumb-item">
          <a 
            [routerLink]="breadcrumbs[1].route" 
            [queryParams]="breadcrumbs[1].queryParams"
          >
            {{breadcrumbs[1].name}}
          </a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{breadcrumbs[2].name}}
        </li>
      </ol>
    </nav>
  </div>
}

@if(blog$ | async; as blogObj){
  <div class="header pt-sm-0 pt-4">
    <div class="header__info">
      <h1 class="header__info--title">
        {{blogObj.title}}
      </h1>
      <div class="header__info--author">
        @if(blogObj.author?.avatar) {
          <img [src]="cmsUrl+blogObj.author.avatar.formats.thumbnail.url" alt="Author Icon" />
        }@else {
          <img src="/assets/icons/teacher-icon.png" alt="Author Icon" />
        }
        <div>
          <span>{{blogObj.author ? blogObj.author.name: 'Anonymous'}}</span>
          <span>{{blogObj.createdAt | date}}</span>
        </div>
      </div>
    </div>
    <div class="header__action">
      <button class="btn btn-outline-primary" (click)="shareViaWhatsApp(blogObj)">
        Share this blog
        <span>
          <i class="fa-solid fa-share-nodes"></i>
        </span>
      </button>
    </div>
  </div>
  @if(blogObj.cover){
    <div class="gallery">
      <div class="carousel__wrapper">
        <div class="carousel__media">
          @if(blogObj.cover.formats.small){
            <img [src]="cmsUrl+blogObj.cover.formats.small.url" alt="Blog Cover" />
          } @else if (blogObj.cover.formats.thumbnail) {
            <img [src]="cmsUrl+blogObj.cover.formats.thumbnail.url" alt="Blog Cover" />
          } @else {
            <img src="/assets/images/Placeholder.png" alt="Blog Cover" />
          }
        </div>
      </div>
    </div>
  }
  <div class="page" [ngClass]="blogObj.cover ? 'page': 'page pt-0'">
    <main>
      @for(block of blogObj.blocks; track block.id){
        @if(block.__component === 'shared.rich-text'){
          <div markdown [data]="block.body" class="rich-text-content"></div>
        }
      }
    </main>
  </div>
}

@if(relatedBlogs$ | async; as relatedBlogs){
  @if(relatedBlogs.length > 0){
    <div class="blog">
      <div class="blog__title">Recommended Blogs</div>
      <div class="blog__list">
        @for(blogObj of relatedBlogs;track blogObj.documentId){
          <app-blog-card 
            class="detail-page"
            [blogObj]="blogObj"
            [categoryId]="typeId"
          ></app-blog-card>
        }
      </div>
    </div>
  }
}

<!-- <app-trending-searches></app-trending-searches> -->
<app-footer></app-footer>
