import { Injectable } from "@angular/core";

export interface ToastInfo {
 message: string,
 header: string,
 autoHide: boolean,
 className?: string,
 delay?: number
}

@Injectable({ providedIn: 'root' })
export class ToastService {
  toasts: ToastInfo[] = [];

  show(obj:ToastInfo) {
    this.toasts.push(obj);
  }

  remove(toast: ToastInfo) {
    this.toasts = this.toasts.filter(t => t != toast);
  }

  showSuccess(message: string, header:string = 'Info') {
    this.show({
      message,
      className: "bg-success text-light",
      delay: 5000,
      autoHide: true,
      header,
    });
  }
  showError(message: string, header:string = "Error") {    
    this.show({
      message,
      className: "bg-danger text-light",
      delay: 5000,
      autoHide: true,
      header,
    });
  }
}