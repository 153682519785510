import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastService } from '../services/toast.service';

@Component({
  selector: 'app-blog-search',
  standalone: true,
  imports: [
    FormsModule
  ],
  templateUrl: './blog-search.component.html',
  styleUrl: './blog-search.component.scss'
})
export class BlogSearchComponent implements OnInit{
  @Input() prefilledSearchTerm:string
  @Input() loading: boolean

  searchTerm: string = ''

  constructor(
    private router: Router,
    private toastService: ToastService
  ){}

  ngOnInit(): void {
    if(this.prefilledSearchTerm){
      this.searchTerm = this.prefilledSearchTerm
    }
  }

  onSearchClick(){
    if(this.searchTerm){
      this.router.navigateByUrl(`/blogs/search?q=${this.searchTerm}`)
    }else {
      this.toastService.showError('Please enter a search term')
    }
  }
}
