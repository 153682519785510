import { isPlatformBrowser } from '@angular/common';
import { Directive, ElementRef, EventEmitter, Output, OnDestroy, AfterViewInit, PLATFORM_ID, Inject } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[appInfiniteScroll]'
})
export class InfiniteScrollDirective implements AfterViewInit, OnDestroy {
  @Output() scrolled = new EventEmitter<void>();

  private observer!: IntersectionObserver;
  private isBrowser: boolean;

  constructor(
    private element: ElementRef,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  ngAfterViewInit() {
    if (this.isBrowser) {
      this.observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this.scrolled.emit();
          }
        });
      });

      this.observer.observe(this.element.nativeElement);
    }
  }

  ngOnDestroy() {
    if (this.isBrowser && this.observer) {
      this.observer.disconnect();
    }
  }
}