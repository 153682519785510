<app-header></app-header>
<div class="hero">
  <div class="hero__header">
    <div class="hero__header--title">Blogs and Resources</div>
    <app-blog-search></app-blog-search>
  </div>
  <div class="character__right">
    <img src="assets/images/blogs.png" alt="blog-icon" />
  </div>
</div>

<div class="page">
  <main>
      <h1 class="categories__title">Explore blogs by categories</h1>
      @if(categories$ | async; as categories){
        <div class="categories__wrapper">
          <div class="categories__grp--list">
              @for(category of categories; track category.documentId){
                <a
                  class="categories__item"
                  [routerLink]="'/blogs/category/'+category.slug"
                  [queryParams]="{'typeId': category.documentId}"
                >
                  <div class="categories__item--media">
                    <img
                      [src]="'assets/images/' + category.name + '.png'"
                      [alt]="category.name+' icon'"
                    />
                  </div>
                  <div class="categories__item--label">{{ category.name }}</div>
                </a>
              }
          </div>
        
        </div>
      } @else {
        <div>Fetching categories...</div>
      }
    

    @if(categoryWiseBlogs$ | async; as categoryWiseBlogs){
      @for(categoryBlogInfo of categoryWiseBlogs; track categoryBlogInfo.name){
        @if(categoryBlogInfo.data.length > 0){
          <div class="blog__list">
            <h2 class="blog__list--title">
              <span>
                <img [src]="'assets/icons/' + categoryBlogInfo.name+ '.png'" [alt]="categoryBlogInfo.name + ' icon'" />
              </span>
              {{categoryBlogInfo.name}}
            </h2>
            @for(blogObj of categoryBlogInfo.data; track blogObj.documentId){
              <app-blog-card 
                  [blogObj]="blogObj"
                  [categoryId]="categoryBlogInfo.documentId"
              ></app-blog-card>
            }
          </div>
        }
      }
    }
  </main>
</div>

<!-- <app-trending-searches></app-trending-searches> -->
<app-footer></app-footer>
