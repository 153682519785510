import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import Faq from './faq'

@Injectable({
  providedIn: 'root',
})
export class FaqService {
  faq$: Observable<any>
  constructor() {
    this.getFaq()
  }

  getFaq() {
   this.faq$ = of(Faq)
  }
}
