import { Component, inject } from '@angular/core';
import { Router, RouterLink } from '@angular/router';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [RouterLink],
  templateUrl: './app-footer.component.html',
  styleUrl: './app-footer.component.scss',
})
export class AppFooterComponent {
  _router = inject(Router);
  currentYear: number

  ngOnInit(){
    this.currentYear = new Date().getFullYear()
  }

  reachOutViaWhatsApp() {
    const msg = 'Hi, I would like to know more about your services.\n'
    const currentUrl = `Source: https://www.gurunearme.com${this._router.url}`;

    const encodedText = encodeURIComponent(msg + currentUrl)

    window.open(
      `https://wa.me/919179328236?text=${encodedText}`,
      '_blank'
    );
  }
}
