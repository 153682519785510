@if(items$ | async; as items){
<div class="trending">
  <div class="trending__title">{{ sectionTitle }}</div>
  @for(item of items; track item){
  <div class="trending__grp--list">
    <div class="trending__grp--title">{{ item.name }}</div>
    <div class="trending__grp">
      @for(subItem of item.subItems; track subItem){
      <a
        class="trending__grp--item"
        [routerLink]="subItem.route"
        [queryParams]="subItem.queryParams"
      >
        {{ subItem.type }} in {{ item.name}}
      </a>

      }
    </div>
  </div>
  }
</div>
}
