<app-header></app-header>
<div class="hero">
    <div class="hero__header">
        <div class="hero__header--title">Blogs and Resources</div>
        <app-blog-search [prefilledSearchTerm]="searchTerm" [loading]="loading"></app-blog-search>
    </div>
    <div class="character__right">
        <img src="assets/images/blogs.png" alt="blog-icon" />
    </div>
</div>

<div class="page">
    <main>
        <h1 class="categories__title">Search Results</h1>
        @if(blogs$ | async; as blogs){
            @if (blogs.length > 0) {
                <div class="blog__list">
                    @for(blogObj of blogs;track blogObj.documentId){
                        <app-blog-card 
                            [blogObj]="blogObj"
                            [categoryId]="blogObj.category.documentId"
                        ></app-blog-card>
                    }
                </div>
            }
            @else if(loading){
                <div>Fetching results...</div>
            }
            @else {
                <div>No posts available for your searched term</div>
                <a class="no-results-button" routerLink="/blogs">
                    <button class="btn btn-outline-primary">Go to Blogs Home</button>
                </a>
            }
        }
    </main>
</div>


<!-- <app-trending-searches></app-trending-searches> -->
<app-footer></app-footer>