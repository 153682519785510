import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { SearchComponent } from '../search/search.component';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-header-search',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './header-search.component.html',
  styleUrl: './header-search.component.scss',
})
export class HeaderSearchComponent extends SearchComponent {
 override selectedLocation: string=''
 override selectedCityId: string=''

  override onSelectCity(city: any): void {
    this.selectedLocation = city.name;
    this.selectedCityId = city._id;
    this.showLocationDropdown = false;
    this.onSearch();
  }
  override onSelectInstitute(institute: any): void {
    this.selectedSearchTerm = institute.name;
    this.selectedInstituteUrlCode = institute.urlCode;
    // Clear selected category so that validation works correctly
    this.selectedCategoryId = '';
    this.showCategoryDropdown = false;
    this.onSearch();
  }

  override onSelectCategory(Category: any): void {
    this.selectedSearchTerm = Category.type
    this.selectedCategoryId = Category._id;

    // Clear selected institute so that validation works correctly
    this.selectedInstituteUrlCode = ''
    this.showCategoryDropdown=false
    this.showLocationDropdown=true
    this.onSearch()
  }
}
