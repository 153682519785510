import { Component, Input } from '@angular/core';


@Component({
  selector: 'app-popular-card',
  standalone: true,
  templateUrl: './popular-card.component.html',
  styleUrl: './popular-card.component.scss',
})
export class PopularCardComponent {
  @Input() instituteName!: string;
  @Input() instituteLogo!: string;
  @Input() instituteAddress!: string;
  @Input() teacherCountAndExperience!: string;
  @Input() typeList!: any;

  getTypeDetails() {
    const typeChip = this.typeList[0]?.type || '';
    const count = this.typeList.length > 1 ? this.typeList.length - 1 : '';
    const title = this.typeList.map((item: any) => item.type).join(', ');
    return {
      typeChip,
      count,
      title,
    };
  }
}
