
<main
style="margin: 30px">

  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <h1
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: center;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun MacChromeBold SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          -webkit-font-smoothing: antialiased;
          font-variant-ligatures: none !important;
          font-size: 16pt;
          line-height: 26.9792px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
          font-weight: bold;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam IT Private Limited - Terms and Conditions</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335551550":2,"335551620":2,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 16pt;
          line-height: 26.9792px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </h1>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: center;
        text-indent: 0px;
      "
    >
      <span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335551550":2,"335551620":2,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 16pt;
          line-height: 26.9792px;
          font-family: Aptos, Aptos_EmbeddedFont, Aptos_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam IT Private Limited, on behalf of itself and its
          affiliates/group companies under the brand &quot;Shikshatam&quot;
          (&ldquo;Shiks</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >hatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&rdquo;), is the author and publisher of the internet resource
          www.</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >.com and the mobile application &lsquo;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&rsquo; (together, &ldquo;Website&rdquo;).</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >1.NATURE AND APPLICABILITY OF TERMS</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Please carefully go through these terms and conditions
          (&ldquo;Terms&rdquo;) and the privacy policy available at
          https://www.</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >.com/privacy (&ldquo;Privacy Policy&rdquo;) before you decide to access
          the Website or avail the services made available on the Website
          by&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >. These Terms and the Privacy Policy together constitute a legal
          agreement (&ldquo;Agreement&rdquo;) between you and&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;in connection with your visit to the Website and your use of the
          Services (as defined below).</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >The Agreement applies to you whether you are -</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >A&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >teacher</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;or&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >education</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;provider (whether an individual professional or an organization)
          or similar institution wishing to be listed, or already listed, on the
          Website, including designated, authorized associates of such&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >teachers</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;or institutions (&ldquo;Practitioner(s)&rdquo;, &ldquo;you&rdquo;
          or &ldquo;User&rdquo;); or</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >A&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >student</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >, his/her&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >representatives,</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;or affiliates, searching for&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >teachers</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;through the Website (&ldquo;End-User&rdquo;, &ldquo;you&rdquo; or
          &ldquo;User&rdquo;); or</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Otherwise a user of the Website (&ldquo;you&rdquo; or
          &ldquo;User&rdquo;).</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >This Agreement applies to those services made available by&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;on the Website, which are offered free of charge to the Users
          (&ldquo;Services&rdquo;), including the following:</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >For&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Teacher</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >s: Listing of&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >teacher</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >s and their profiles and contact details, to be made available to the
          other Users and visitors to the&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Website.</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >For other Users: Facility to (i) create and maintain
          &lsquo;Accounts&rsquo;, (ii) search for&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >teacher</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >s by name, specialty, and geographical area, or any other criteria that
          may be developed and made available by&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >, and (iii) to make appointments with&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >teacher</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >s.</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >The Services may change from time to time, at the sole discretion
          of&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >, and the Agreement will apply to your visit to and your use of the
          Website to avail the Service, as well as to all information provided by
          you on the Website at any given point in time.</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <div
      class="OutlineElement Ltr SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px;
        padding: 0px;
        user-select: text;
        clear: both;
        cursor: text;
        overflow: visible;
        position: relative;
        direction: ltr;
      "
    >
      <p
        class="Paragraph SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px 0px 10.6667px;
          padding: 0px;
          user-select: text;
          overflow-wrap: break-word;
          white-space: pre-wrap;
          font-weight: normal;
          font-style: normal;
          vertical-align: baseline;
          font-kerning: none;
          background-color: transparent;
          color: windowtext;
          text-align: left;
          text-indent: 0px;
        "
      >
        <span
          data-contrast="auto"
          lang="EN-US"
          class="TextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            font-variant-ligatures: none !important;
            font-size: 12pt;
            line-height: 20.5042px;
            font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
              sans-serif;
          "
          ><span
            class="NormalTextRun SCXW82804494 BCX0"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
            "
            >This Agreement defines the terms and conditions under which you are
            allowed to use the Website and describes the manner in which we shall
            treat your account while you are registered as a member with us. If
            you have any questions about any part of the Agreement, feel free to
            contact us at support"&#64;"shikshatm.com</span
          ><span
            class="NormalTextRun SCXW82804494 BCX0"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
            "
            >shikshatam</span
          ><span
            class="NormalTextRun SCXW82804494 BCX0"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
            "
            >.com.</span
          ></span
        ><span
          class="EOP SCXW82804494 BCX0"
          data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            font-size: 12pt;
            line-height: 20.5042px;
            font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
              sans-serif;
          "
          >&nbsp;</span
        >
      </p>
    </div>
    <div
      class="OutlineElement Ltr SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px;
        padding: 0px;
        user-select: text;
        clear: both;
        cursor: text;
        overflow: visible;
        position: relative;
        direction: ltr;
      "
    >
      <p
        class="Paragraph SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px 0px 10.6667px;
          padding: 0px;
          user-select: text;
          overflow-wrap: break-word;
          white-space: pre-wrap;
          font-weight: normal;
          font-style: normal;
          vertical-align: baseline;
          font-kerning: none;
          background-color: transparent;
          color: windowtext;
          text-align: left;
          text-indent: 0px;
        "
      >
        <span
          class="EOP SCXW82804494 BCX0"
          data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            font-size: 12pt;
            line-height: 20.5042px;
            font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
              sans-serif;
          "
          >&nbsp;</span
        >
      </p>
    </div>
    <div
      class="OutlineElement Ltr SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px;
        padding: 0px;
        user-select: text;
        clear: both;
        cursor: text;
        overflow: visible;
        position: relative;
        direction: ltr;
      "
    >
      <p
        class="Paragraph SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px 0px 10.6667px;
          padding: 0px;
          user-select: text;
          overflow-wrap: break-word;
          white-space: pre-wrap;
          font-weight: normal;
          font-style: normal;
          vertical-align: baseline;
          font-kerning: none;
          background-color: transparent;
          color: windowtext;
          text-align: left;
          text-indent: 0px;
        "
      >
        <span
          data-contrast="auto"
          lang="EN-US"
          class="TextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            font-variant-ligatures: none !important;
            font-size: 12pt;
            line-height: 20.5042px;
            font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
              sans-serif;
          "
          ><span
            class="NormalTextRun SCXW82804494 BCX0"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
            "
            >By downloading or accessing the Website to use the Services, you
            irrevocably accept all the conditions stipulated in this Agreement,
            the Subscription Terms of Service and Privacy Policy, as available on
            the Website, and agree to abide by them. This Agreement supersedes all
            previous oral and written terms and conditions (if any) communicated
            to you relating to your use of the Website to avail the Services. By
            availing any Service, you signify your acceptance of the terms of this
            Agreement.</span
          ></span
        ><span
          class="EOP SCXW82804494 BCX0"
          data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            font-size: 12pt;
            line-height: 20.5042px;
            font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
              sans-serif;
          "
          >&nbsp;</span
        >
      </p>
    </div>
    <div
      class="OutlineElement Ltr SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px;
        padding: 0px;
        user-select: text;
        clear: both;
        cursor: text;
        overflow: visible;
        position: relative;
        direction: ltr;
      "
    >
      <p
        class="Paragraph SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px 0px 10.6667px;
          padding: 0px;
          user-select: text;
          overflow-wrap: break-word;
          white-space: pre-wrap;
          font-weight: normal;
          font-style: normal;
          vertical-align: baseline;
          font-kerning: none;
          background-color: transparent;
          color: windowtext;
          text-align: left;
          text-indent: 0px;
        "
      >
        <span
          class="EOP SCXW82804494 BCX0"
          data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            font-size: 12pt;
            line-height: 20.5042px;
            font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
              sans-serif;
          "
          >&nbsp;</span
        >
      </p>
    </div>
    <div
      class="OutlineElement Ltr SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px;
        padding: 0px;
        user-select: text;
        clear: both;
        cursor: text;
        overflow: visible;
        position: relative;
        direction: ltr;
      "
    >
      <p
        class="Paragraph SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px 0px 10.6667px;
          padding: 0px;
          user-select: text;
          overflow-wrap: break-word;
          white-space: pre-wrap;
          font-weight: normal;
          font-style: normal;
          vertical-align: baseline;
          font-kerning: none;
          background-color: transparent;
          color: windowtext;
          text-align: left;
          text-indent: 0px;
        "
      >
        <span
          data-contrast="auto"
          lang="EN-US"
          class="TextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            font-variant-ligatures: none !important;
            font-size: 12pt;
            line-height: 20.5042px;
            font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
              sans-serif;
          "
          ><span
            class="NormalTextRun SCXW82804494 BCX0"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
            "
            >We reserve the right to modify or terminate any portion of the
            Agreement for any reason and at any time, and such modifications shall
            be informed to you in writing You should read the Agreement at regular
            intervals. Your use of the Website following any such modification
            constitutes your agreement to follow and be bound by the Agreement so
            modified.</span
          ></span
        ><span
          class="EOP SCXW82804494 BCX0"
          data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            font-size: 12pt;
            line-height: 20.5042px;
            font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
              sans-serif;
          "
          >&nbsp;</span
        >
      </p>
    </div>
    <div
      class="OutlineElement Ltr SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px;
        padding: 0px;
        user-select: text;
        clear: both;
        cursor: text;
        overflow: visible;
        position: relative;
        direction: ltr;
      "
    >
      <p
        class="Paragraph SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px 0px 10.6667px;
          padding: 0px;
          user-select: text;
          overflow-wrap: break-word;
          white-space: pre-wrap;
          font-weight: normal;
          font-style: normal;
          vertical-align: baseline;
          font-kerning: none;
          background-color: transparent;
          color: windowtext;
          text-align: left;
          text-indent: 0px;
        "
      >
        <span
          class="EOP SCXW82804494 BCX0"
          data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            font-size: 12pt;
            line-height: 20.5042px;
            font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
              sans-serif;
          "
          >&nbsp;</span
        >
      </p>
    </div>
    <div
      class="OutlineElement Ltr SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px;
        padding: 0px;
        user-select: text;
        clear: both;
        cursor: text;
        overflow: visible;
        position: relative;
        direction: ltr;
      "
    >
      <p
        class="Paragraph SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px 0px 10.6667px;
          padding: 0px;
          user-select: text;
          overflow-wrap: break-word;
          white-space: pre-wrap;
          font-weight: normal;
          font-style: normal;
          vertical-align: baseline;
          font-kerning: none;
          background-color: transparent;
          color: windowtext;
          text-align: left;
          text-indent: 0px;
        "
      >
        <span
          data-contrast="auto"
          lang="EN-US"
          class="TextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            font-variant-ligatures: none !important;
            font-size: 12pt;
            line-height: 20.5042px;
            font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
              sans-serif;
          "
          ><span
            class="NormalTextRun SCXW82804494 BCX0"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
            "
            >You acknowledge that you will be bound by this Agreement for availing
            any of the Services offered by us. If you do not agree with any part
            of the Agreement, please do not use the Website or avail any
            Services.</span
          ></span
        ><span
          class="EOP SCXW82804494 BCX0"
          data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            font-size: 12pt;
            line-height: 20.5042px;
            font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
              sans-serif;
          "
          >&nbsp;</span
        >
      </p>
    </div>
    <div
      class="OutlineElement Ltr SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px;
        padding: 0px;
        user-select: text;
        clear: both;
        cursor: text;
        overflow: visible;
        position: relative;
        direction: ltr;
      "
    >
      <p
        class="Paragraph SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px 0px 10.6667px;
          padding: 0px;
          user-select: text;
          overflow-wrap: break-word;
          white-space: pre-wrap;
          font-weight: normal;
          font-style: normal;
          vertical-align: baseline;
          font-kerning: none;
          background-color: transparent;
          color: windowtext;
          text-align: left;
          text-indent: 0px;
        "
      >
        <span
          class="EOP SCXW82804494 BCX0"
          data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            font-size: 12pt;
            line-height: 20.5042px;
            font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
              sans-serif;
          "
          >&nbsp;</span
        >
      </p>
    </div>
    <div
      class="OutlineElement Ltr SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px;
        padding: 0px;
        user-select: text;
        clear: both;
        cursor: text;
        overflow: visible;
        position: relative;
        direction: ltr;
      "
    >
      <p
        class="Paragraph SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px 0px 10.6667px;
          padding: 0px;
          user-select: text;
          overflow-wrap: break-word;
          white-space: pre-wrap;
          font-weight: normal;
          font-style: normal;
          vertical-align: baseline;
          font-kerning: none;
          background-color: transparent;
          color: windowtext;
          text-align: left;
          text-indent: 0px;
        "
      >
        <span
          data-contrast="auto"
          lang="EN-US"
          class="TextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            font-variant-ligatures: none !important;
            font-size: 12pt;
            line-height: 20.5042px;
            font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
              sans-serif;
          "
          ><span
            class="NormalTextRun SCXW82804494 BCX0"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
            "
            >Your access to use of the Website and the Services will be solely at
            the discretion of&nbsp;</span
          ><span
            class="NormalTextRun SCXW82804494 BCX0"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
            "
            >Shikshatam</span
          ><span
            class="NormalTextRun SCXW82804494 BCX0"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
            "
            >.</span
          ></span
        ><span
          class="EOP SCXW82804494 BCX0"
          data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            font-size: 12pt;
            line-height: 20.5042px;
            font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
              sans-serif;
          "
          >&nbsp;</span
        >
      </p>
    </div>
    <div
      class="OutlineElement Ltr SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px;
        padding: 0px;
        user-select: text;
        clear: both;
        cursor: text;
        overflow: visible;
        position: relative;
        direction: ltr;
      "
    >
      <p
        class="Paragraph SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px 0px 10.6667px;
          padding: 0px;
          user-select: text;
          overflow-wrap: break-word;
          white-space: pre-wrap;
          font-weight: normal;
          font-style: normal;
          vertical-align: baseline;
          font-kerning: none;
          background-color: transparent;
          color: windowtext;
          text-align: left;
          text-indent: 0px;
        "
      >
        <span
          class="EOP SCXW82804494 BCX0"
          data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            font-size: 12pt;
            line-height: 20.5042px;
            font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
              sans-serif;
          "
          >&nbsp;</span
        >
      </p>
    </div>
    <div
      class="OutlineElement Ltr SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px;
        padding: 0px;
        user-select: text;
        clear: both;
        cursor: text;
        overflow: visible;
        position: relative;
        direction: ltr;
      "
    >
      <p
        class="Paragraph SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px 0px 10.6667px;
          padding: 0px;
          user-select: text;
          overflow-wrap: break-word;
          white-space: pre-wrap;
          font-weight: normal;
          font-style: normal;
          vertical-align: baseline;
          font-kerning: none;
          background-color: transparent;
          color: windowtext;
          text-align: left;
          text-indent: 0px;
        "
      >
        <span
          data-contrast="auto"
          lang="EN-US"
          class="TextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            font-variant-ligatures: none !important;
            font-size: 12pt;
            line-height: 20.5042px;
            font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
              sans-serif;
          "
          ><span
            class="NormalTextRun SCXW82804494 BCX0"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
            "
            >The Agreement is published in compliance of, and is governed by the
            provisions of Indian law, including but not limited to:</span
          ></span
        ><span
          class="EOP SCXW82804494 BCX0"
          data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            font-size: 12pt;
            line-height: 20.5042px;
            font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
              sans-serif;
          "
          >&nbsp;</span
        >
      </p>
    </div>
    <div
      class="ListContainerWrapper SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px;
        padding: 0px;
        user-select: text;
        position: relative;
      "
    >
      <ul
        class="BulletListStyle1 SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          list-style-type: disc;
          cursor: text;
          font-family: verdana;
          overflow: visible;
        "
      >
        <li
          data-leveltext=""
          data-font="Symbol"
          data-listid="1"
          data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"hybridMultilevel"}'
          aria-setsize="-1"
          data-aria-posinset="1"
          data-aria-level="1"
          class="OutlineElement Ltr SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px 0px 0px 24px;
            padding: 0px;
            user-select: text;
            clear: both;
            cursor: text;
            overflow: visible;
            position: relative;
            direction: ltr;
            display: block;
            font-size: 12pt;
            font-family: Arial, Arial_MSFontService, sans-serif;
            vertical-align: baseline;
          "
        >
          <p
            class="Paragraph SCXW82804494 BCX0"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              overflow-wrap: break-word;
              white-space: pre-wrap;
              font-weight: normal;
              font-style: normal;
              vertical-align: baseline;
              font-kerning: none;
              background-color: transparent;
              color: windowtext;
              text-align: left;
              text-indent: 0px;
            "
          >
            <span
              data-contrast="auto"
              lang="EN-US"
              class="TextRun SCXW82804494 BCX0"
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                font-variant-ligatures: none !important;
                font-size: 12pt;
                line-height: 20.5042px;
                font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
                  sans-serif;
              "
              ><span
                class="NormalTextRun SCXW82804494 BCX0"
                style="
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                "
                >the Indian Contract Act, 1872,</span
              ></span
            ><span
              class="EOP SCXW82804494 BCX0"
              data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                font-size: 12pt;
                line-height: 20.5042px;
                font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
                  sans-serif;
              "
              >&nbsp;</span
            >
          </p>
        </li>
      </ul>
    </div>
    <div
      class="ListContainerWrapper SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px;
        padding: 0px;
        user-select: text;
        position: relative;
      "
    >
      <ul
        class="BulletListStyle1 SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          list-style-type: disc;
          cursor: text;
          font-family: verdana;
          overflow: visible;
        "
      >
        <li
          data-leveltext=""
          data-font="Symbol"
          data-listid="1"
          data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"hybridMultilevel"}'
          aria-setsize="-1"
          data-aria-posinset="2"
          data-aria-level="1"
          class="OutlineElement Ltr SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px 0px 0px 24px;
            padding: 0px;
            user-select: text;
            clear: both;
            cursor: text;
            overflow: visible;
            position: relative;
            direction: ltr;
            display: block;
            font-size: 12pt;
            font-family: Arial, Arial_MSFontService, sans-serif;
            vertical-align: baseline;
          "
        >
          <p
            class="Paragraph SCXW82804494 BCX0"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              overflow-wrap: break-word;
              white-space: pre-wrap;
              font-weight: normal;
              font-style: normal;
              vertical-align: baseline;
              font-kerning: none;
              background-color: transparent;
              color: windowtext;
              text-align: left;
              text-indent: 0px;
            "
          >
            <span
              data-contrast="auto"
              lang="EN-US"
              class="TextRun SCXW82804494 BCX0"
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                font-variant-ligatures: none !important;
                font-size: 12pt;
                line-height: 20.5042px;
                font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
                  sans-serif;
              "
              ><span
                class="NormalTextRun SCXW82804494 BCX0"
                style="
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                "
                >the (Indian) Information Technology Act, 2000, and</span
              ></span
            ><span
              class="EOP SCXW82804494 BCX0"
              data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                font-size: 12pt;
                line-height: 20.5042px;
                font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
                  sans-serif;
              "
              >&nbsp;</span
            >
          </p>
        </li>
      </ul>
    </div>
    <div
      class="ListContainerWrapper SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px;
        padding: 0px;
        user-select: text;
        position: relative;
      "
    >
      <ul
        class="BulletListStyle1 SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          list-style-type: disc;
          cursor: text;
          font-family: verdana;
          overflow: visible;
        "
      >
        <li
          data-leveltext=""
          data-font="Symbol"
          data-listid="1"
          data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"hybridMultilevel"}'
          aria-setsize="-1"
          data-aria-posinset="3"
          data-aria-level="1"
          class="OutlineElement Ltr SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px 0px 0px 24px;
            padding: 0px;
            user-select: text;
            clear: both;
            cursor: text;
            overflow: visible;
            position: relative;
            direction: ltr;
            display: block;
            font-size: 12pt;
            font-family: Arial, Arial_MSFontService, sans-serif;
            vertical-align: baseline;
          "
        >
          <p
            class="Paragraph SCXW82804494 BCX0"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              overflow-wrap: break-word;
              white-space: pre-wrap;
              font-weight: normal;
              font-style: normal;
              vertical-align: baseline;
              font-kerning: none;
              background-color: transparent;
              color: windowtext;
              text-align: left;
              text-indent: 0px;
            "
          >
            <span
              data-contrast="auto"
              lang="EN-US"
              class="TextRun SCXW82804494 BCX0"
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                font-variant-ligatures: none !important;
                font-size: 12pt;
                line-height: 20.5042px;
                font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
                  sans-serif;
              "
              ><span
                class="NormalTextRun SCXW82804494 BCX0"
                style="
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                "
                >the rules, regulations, guidelines and clarifications framed
                there under, including the (Indian) Information Technology
                (Reasonable Security Practices and Procedures and Sensitive
                Personal Information) Rules, 2011 (the &ldquo;SPI Rules&rdquo;),
                and the (Indian) Information Technology (Intermediaries
                Guidelines) Rules, 2011 (the &ldquo;IG Rules&rdquo;).</span
              ></span
            ><span
              class="EOP SCXW82804494 BCX0"
              data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                font-size: 12pt;
                line-height: 20.5042px;
                font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
                  sans-serif;
              "
              >&nbsp;</span
            >
          </p>
        </li>
      </ul>
    </div>
    <div
      class="OutlineElement Ltr SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px;
        padding: 0px;
        user-select: text;
        clear: both;
        cursor: text;
        overflow: visible;
        position: relative;
        direction: ltr;
      "
    >
      <p
        class="Paragraph SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px 0px 10.6667px;
          padding: 0px;
          user-select: text;
          overflow-wrap: break-word;
          white-space: pre-wrap;
          font-weight: normal;
          font-style: normal;
          vertical-align: baseline;
          font-kerning: none;
          background-color: transparent;
          color: windowtext;
          text-align: left;
          text-indent: 0px;
        "
      >
        <span
          data-contrast="auto"
          lang="EN-US"
          class="TextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            font-variant-ligatures: none !important;
            font-size: 12pt;
            line-height: 20.5042px;
            font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
              sans-serif;
          "
          ><span
            class="NormalTextRun SCXW82804494 BCX0"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
            "
            >2.&nbsp;</span
          ><span
            class="NormalTextRun SCXW82804494 BCX0"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
            "
            >CONDITIONS OF USE</span
          ></span
        ><span
          class="EOP SCXW82804494 BCX0"
          data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            font-size: 12pt;
            line-height: 20.5042px;
            font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
              sans-serif;
          "
          >&nbsp;</span
        >
      </p>
    </div>
    <div
      class="OutlineElement Ltr SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px;
        padding: 0px;
        user-select: text;
        clear: both;
        cursor: text;
        overflow: visible;
        position: relative;
        direction: ltr;
      "
    >
      <p
        class="Paragraph SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px 0px 10.6667px;
          padding: 0px;
          user-select: text;
          overflow-wrap: break-word;
          white-space: pre-wrap;
          font-weight: normal;
          font-style: normal;
          vertical-align: baseline;
          font-kerning: none;
          background-color: transparent;
          color: windowtext;
          text-align: left;
          text-indent: 0px;
        "
      >
        <span
          data-contrast="auto"
          lang="EN-US"
          class="TextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            font-variant-ligatures: none !important;
            font-size: 12pt;
            line-height: 20.5042px;
            font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
              sans-serif;
          "
          ><span
            class="NormalTextRun SCXW82804494 BCX0"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
            "
            >By registering, visiting and using the Website or accepting this
            Agreement, you represent and warrant to&nbsp;</span
          ><span
            class="NormalTextRun SCXW82804494 BCX0"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
            "
            >Shikshatam&nbsp;</span
          ><span
            class="NormalTextRun SCXW82804494 BCX0"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
            "
            >that you have the right, authority and capacity to use the Website
            and the Services available through the Website, and agree to and abide
            by this Agreement.</span
          ></span
        ><span
          class="EOP SCXW82804494 BCX0"
          data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            font-size: 12pt;
            line-height: 20.5042px;
            font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
              sans-serif;
          "
          >&nbsp;</span
        >
      </p>
    </div>
    <div
      class="OutlineElement Ltr SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px;
        padding: 0px;
        user-select: text;
        clear: both;
        cursor: text;
        overflow: visible;
        position: relative;
        direction: ltr;
      "
    >
      <p
        class="Paragraph SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px 0px 10.6667px;
          padding: 0px;
          user-select: text;
          overflow-wrap: break-word;
          white-space: pre-wrap;
          font-weight: normal;
          font-style: normal;
          vertical-align: baseline;
          font-kerning: none;
          background-color: transparent;
          color: windowtext;
          text-align: left;
          text-indent: 0px;
        "
      >
        <span
          class="EOP SCXW82804494 BCX0"
          data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            font-size: 12pt;
            line-height: 20.5042px;
            font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
              sans-serif;
          "
          >&nbsp;</span
        >
      </p>
    </div>
    <div
      class="OutlineElement Ltr SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px;
        padding: 0px;
        user-select: text;
        clear: both;
        cursor: text;
        overflow: visible;
        position: relative;
        direction: ltr;
      "
    >
      <p
        class="Paragraph SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px 0px 10.6667px;
          padding: 0px;
          user-select: text;
          overflow-wrap: break-word;
          white-space: pre-wrap;
          font-weight: normal;
          font-style: normal;
          vertical-align: baseline;
          font-kerning: none;
          background-color: transparent;
          color: windowtext;
          text-align: left;
          text-indent: 0px;
        "
      >
        <span
          data-contrast="auto"
          lang="EN-US"
          class="TextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            font-variant-ligatures: none !important;
            font-size: 12pt;
            line-height: 20.5042px;
            font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
              sans-serif;
          "
          ><span
            class="NormalTextRun SCXW82804494 BCX0"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
            "
            >3.</span
          ><span
            class="NormalTextRun SCXW82804494 BCX0"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
            "
            >&nbsp;</span
          ><span
            class="NormalTextRun SCXW82804494 BCX0"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
            "
            >TERMS OF USE APPLICABLE TO ALL USERS</span
          ></span
        ><span
          class="EOP SCXW82804494 BCX0"
          data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            font-size: 12pt;
            line-height: 20.5042px;
            font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
              sans-serif;
          "
          >&nbsp;</span
        >
      </p>
    </div>
    <div
      class="OutlineElement Ltr SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px;
        padding: 0px;
        user-select: text;
        clear: both;
        cursor: text;
        overflow: visible;
        position: relative;
        direction: ltr;
      "
    >
      <p
        class="Paragraph SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px 0px 10.6667px;
          padding: 0px;
          user-select: text;
          overflow-wrap: break-word;
          white-space: pre-wrap;
          font-weight: normal;
          font-style: normal;
          vertical-align: baseline;
          font-kerning: none;
          background-color: transparent;
          color: windowtext;
          text-align: left;
          text-indent: 0px;
        "
      >
        <span
          class="EOP SCXW82804494 BCX0"
          data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            font-size: 12pt;
            line-height: 20.5042px;
            font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
              sans-serif;
          "
          >&nbsp;</span
        >
      </p>
    </div>
    <div
      class="OutlineElement Ltr SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px;
        padding: 0px;
        user-select: text;
        clear: both;
        cursor: text;
        overflow: visible;
        position: relative;
        direction: ltr;
      "
    >
      <p
        class="Paragraph SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px 0px 10.6667px;
          padding: 0px;
          user-select: text;
          overflow-wrap: break-word;
          white-space: pre-wrap;
          font-weight: normal;
          font-style: normal;
          vertical-align: baseline;
          font-kerning: none;
          background-color: transparent;
          color: windowtext;
          text-align: left;
          text-indent: 0px;
        "
      >
        <span
          data-contrast="auto"
          lang="EN-US"
          class="TextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            font-variant-ligatures: none !important;
            font-size: 12pt;
            line-height: 20.5042px;
            font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
              sans-serif;
          "
          ><span
            class="NormalTextRun SCXW82804494 BCX0"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
            "
            >3.1</span
          ><span
            class="NormalTextRun SCXW82804494 BCX0"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
            "
            >&nbsp;</span
          ><span
            class="NormalTextRun SCXW82804494 BCX0"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
            "
            >END-USER ACCOUNT AND DATA PRIVACY</span
          ></span
        ><span
          class="EOP SCXW82804494 BCX0"
          data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            font-size: 12pt;
            line-height: 20.5042px;
            font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
              sans-serif;
          "
          >&nbsp;</span
        >
      </p>
    </div>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >3.1.1 The terms &ldquo;personal information&rdquo; and &ldquo;sensitive
          personal data or information&rdquo; are defined under the SPI Rules, and
          are reproduced in the Privacy Policy.</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >3.1.2&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;may by its Services, collect information relating to the devices
          through which you access the Website, and anonymous data of your usage.
          The collected information will be used only for improving the quality
          of&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam&lsquo;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >s services and to build new services.</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >3.1.3 The Website allows&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;to have access to registered Users&rsquo; personal email or phone
          number, for communication purpose so as to provide you a better way of
          booking appointments and for obtaining feedback in relation to
          the&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >teacher</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >s and their practice.</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >3.1.4 The Privacy Policy sets out, inter-alia:</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >The type of information collected from Users, including sensitive
          personal data or information;</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >The purpose, means and modes of usage of such information;</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >How and to whom&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;will disclose such information; and,</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Other information mandated by the SPI Rules.</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >3.1.5 The User is expected to read and understand the Privacy Policy,
          so as to ensure that he or she has the knowledge of, inter-alia:</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >the fact that certain information is being collected;</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >the purpose for which the information is being collected;</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >the intended recipients of the information;</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >the nature of collection and retention of the information; and</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >the name and address of the agency that is collecting the information
          and the agency that will retain the information; and</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >the various rights available to such Users in respect of such
          information.</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >3.1.6&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;shall not be responsible in any manner for the authenticity of
          the personal information or sensitive personal data or information
          supplied by the User to&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >or to any other person acting on behalf of&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam.</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >3.1.7 The User is responsible for maintaining the confidentiality of
          the User&rsquo;s account access information and password, if the User is
          registered on the Website. The User shall be responsible for all usage
          of the User&rsquo;s account and password, whether or not authorized by
          the User. The User shall immediately notify&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;of any actual or suspected unauthorized use of the User&rsquo;s
          account or password. Although&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;will not be liable for your losses caused by any unauthorized use
          of your account, you may be liable for the losses of&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;or such other parties as the case may be, due to any unauthorized
          use of your account.</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >3.1.8</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >If a User provides any information that is untrue, inaccurate, not
          current or incomplete (or becomes untrue, inaccurate, not current or
          incomplete), or&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;has reasonable grounds to suspect that such information is
          untrue, inaccurate, not current or incomplete,&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;has the right to discontinue the Services to the User at its sole
          discretion.</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >3.1.9</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;may use such information collected from the Users from time to
          time for the purposes of debugging customer support related
          issues.</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >3.2 LISTING CONTENT AND DISSEMINATING INFORMATION</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >3.</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >2.</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >1&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;collects, directly or indirectly, and displays on the Website,
          relevant information regarding the profile and practice of
          the&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >teachers</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;listed on the Website, such as their specialization,
          qualification, fees, location, visiting hours, and similar
          details.&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;takes reasonable efforts to ensure that such information is
          updated at frequent intervals. Although&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;screens and vets the information and photos submitted by
          the&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Teacher</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >s</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >/Institutes</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >, it cannot be held liable for any inaccuracies or incompleteness
          represented from it, despite such reasonable efforts.</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >3.2</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >.2</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >The Services provided by&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;or any of its licensors or service providers are provided on an
          &quot;as is&quot; and &ldquo;as available&rsquo; basis, and without any
          warranties or conditions (express or implied, including the implied
          warranties of merchantability, accuracy, fitness for a particular
          purpose, title and non-infringement, arising by statute or otherwise in
          law or from a course of dealing or usage or trade).&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;does not provide or make any representation, warranty or
          guarantee, express or implied about the Website or the
          Services.&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;does not guarantee the accuracy or completeness of any content or
          information provided by Users on the Website. To the fullest extent
          permitted by law,&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;disclaims all liability arising out of the User&rsquo;s use or
          reliance upon the Website, the Services, representations and warranties
          made by other Users, the content or information provided by the Users on
          the Website, or any opinion or suggestion given or expressed
          by&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;or any User in relation to any User or services provided by such
          User.</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >3.</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >2.</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >3</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >The Website may be linked to the website of third parties, affiliates
          and business partners.&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;has no control over, and not liable or responsible for content,
          accuracy, validity, reliability, quality of such websites or made
          available&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >by/through our Website. Inclusion of any link on the Website does not
          imply that&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;endorses the linked site. User may use the links and these
          services at User&rsquo;s own risk.</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >3.</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >2.</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >4</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;assumes no responsibility, and shall not be liable for, any
          damages to, or viruses that may infect User&rsquo;s equipment on account
          of User&rsquo;s access to, use of, or browsing the Website or the
          downloading of any material, data, text, images, video content, or audio
          content from the Website. If a User is dissatisfied with the Website,
          User&rsquo;s sole remedy is to discontinue using the Website.</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >3.</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >2.</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >5</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >If&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;determines that you have provided fraudulent, inaccurate, or
          incomplete information, including through feedback,&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;reserves the right to immediately suspend your access to the
          Website or any of your accounts with&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;and makes such declaration on the website alongside your
          name/your clinic&rsquo;s name as determined by&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;for the protection of its business and in the interests of Users.
          You shall be liable to indemnify&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;for any losses incurred as a result of your misrepresentations or
          fraudulent feedback that has adversely affected&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;or its Users.</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >3.</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >3 STUDENT</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;STORIES</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >By using this Website, you agree that any information shared by you
          with&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;or with any&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >T</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >eacher</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >/Institute</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;will be subject to our Privacy Policy.</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >You are solely responsible for the content that you choose to submit
          for publication on the Website, including any patient stories or
          recommendations (&ldquo;Critical Content&rdquo;) relating to&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Teacher/Institute</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;or other healthcare professionals. The role of&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >in publishing Critical Content is restricted to that of an
          &lsquo;intermediary&rsquo; under the Information Technology Act,
          2000.&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;disclaims all responsibility with respect to the content of
          Critical Content, and its role with respect to such content is
          restricted to its obligations as an &lsquo;intermediary&rsquo; under the
          said Act.&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;shall not be liable to pay any consideration to any User for
          re-publishing any content across any of its platforms.</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Your publication of patient stories on the Website is governed by
          Clause 5 of these Terms. Without prejudice to the detailed terms stated
          in Clause 5, you hereby agree not to post or publish any content on the
          Website that (a) infringes any third-party intellectual property or
          publicity or privacy rights, or (b) violates any applicable law or
          regulation, including but not limited to the IG Rules and SPI
          Rules.&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >, at its sole discretion, may choose not to publish your patient
          stories, if so required by applicable law, and in accordance with Clause
          5 of these Terms. You agree that&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;may contact you through telephone, email, SMS, or any other
          electronic means of communication for the purpose of:</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Obtaining feedback in relation to Website or&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&rsquo;s services; and/or</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Obtaining feedback in relation to any&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Teacher/Institute</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;listed on the Website; and/or</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Resolving any complaints, information, or queries by&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Teacher/Institute</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;regarding your Critical Content;</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >and you agree to provide your fullest co-operation further to such
          communication by&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >.&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&rsquo;s Feedback Collection and Fraud Detection Policy, is annexed as
          the Schedule hereto, and remains subject always to these Terms.</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >3.</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >4</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >RECORDS</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;may provide End-Users with a free facility known as
          &lsquo;Records&rsquo; on its mobile application &lsquo;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&rsquo;. Information available in your Records is of two types:</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >User-created: Information uploaded by you or information generated
          during your interaction with&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;ecosystem, eg: appointment,&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >message</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;placed by you.</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Teacher</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >-created: Records generated by your interaction with a&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Teacher</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;who uses&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;software.</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >The specific terms relating to such Account are as below, without
          prejudice to the rest of these Terms and the Privacy Policy:</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >3.</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >4</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >.1</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Your Records is only created after you have signed up and explicitly
          accepted these Terms.</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >3.</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >4</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >.2</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Any&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Teacher/Institute</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;created Record is provided on an as-is basis at the sole intent,
          risk and responsibility of the&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Teacher/Institute</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >and&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;does not validate the said information and makes no
          representation in connection therewith. You should contact the
          relevant&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Teacher/Institute</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;in case you wish to point out any discrepancies or add, delete,
          or modify the Health Record in any manner.</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >3.</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >4</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >.3</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >The&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >R</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >ecords are provided on an as-is basis. While we strive to maintain the
          highest levels of service availability,&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;is not liable for any interruption that may be caused to your
          access of the Services.</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >3.</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >4</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >.</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >4&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >It is your responsibility to keep your correct mobile number and email
          ID updated in the Records. The Records will be sent to the Records
          associated with this mobile number and/or email ID. Every time you
          change any contact information (mobile or email), we will send a
          confirmation.&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;is not responsible for any loss or inconvenience caused due to
          your failure in updating the contact details with&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >.</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >3.</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >4</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >.</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >5</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshtam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;does not guarantee to prevent unauthorized access if you lose
          your login credentials or they are otherwise compromised. In the event
          you are aware of&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >any unauthorized use or access, you shall immediately
          inform&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;of such unauthorized use or access. Please safeguard your login
          credentials and report any actual suspected breach of account to
          support"&#64;"shikshatam.com </span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >.com.</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >3.</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >4</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >.</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >5&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >In case you want to delete your Records, you can do so by contacting
          our service support team. However only your account and any associated
          Records will be deleted, and your Records stored by your&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Teacher/Institute</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;will continue to be stored in their respective accounts.</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >3.</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >4</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >.</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >6&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >You may lose your &ldquo;User created&rdquo; record, if the data is not
          synced with the server.</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >4. TERMS OF USE TEACHERS/INSTITUTES</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >The terms in this Clause 4 are applicable only to&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Teacher</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >s</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >/Institutes</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >.</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >4.1 LISTING POLICY</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >4.1.1</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >, directly and indirectly, collects information regarding
          the&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >teacher</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >s&rsquo;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >/Institute</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;profiles, contact details, and&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >skills</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >.&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;reserves the right to take down any&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Teacher</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&rsquo;s</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >/Institute</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;profile as well as the right to display the profile of
          the&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Teacher</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&rsquo;s</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >/Institute</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >, with or without notice to the concerned&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Teacher/Institute</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >. This information is collected for the purpose of facilitating
          interaction with the End-Users and other Users. If any information
          displayed on the Website in connection with you and your profile is
          found to be incorrect, you are required to inform&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;immediately to enable&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;to make the necessary amendments.</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >4.1.2</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;shall not be liable and responsible for the ranking of
          the&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Teacher</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&rsquo;s</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >/Institute</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;on external websites and search engines</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >4.1.3</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;shall not be responsible or liable in any manner to the Users for
          any losses, damage, injuries or expenses incurred by the Users as a
          result of any disclosures or publications made by&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >S</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >hikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >, where the User has expressly or implicitly consented to the making of
          disclosures or publications by&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >. If the User had revoked such consent under the terms of the Privacy
          Policy, then&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;shall not be responsible or liable in any manner to the User for
          any losses, damage, injuries or expenses incurred by the User as a
          result of any disclosures made by&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;prior to its actual receipt of such revocation.</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >4.1.4</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;reserves the right to moderate the suggestions made by
          the&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Teacher</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&rsquo;s</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >/Institute</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;through feedback and the right to remove any abusive or
          inappropriate or promotional content added on the Website.
          However,&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;shall not be liable if any inactive, inaccurate, fraudulent, or
          non- existent profiles of&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Teacher</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&rsquo;s</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >/Institute</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;are added to the Website.</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >4.1.5</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;Teacher</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&rsquo;s</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >/Institute</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;explicitly agree that&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;reserves the right to publish the Content provided by&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Teacher</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&rsquo;s</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >/Institute</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >to a third party including content platforms.</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >4.1.</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >6&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >You as a&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Teacher</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&rsquo;s</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >/Institute</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;hereby represent and warrant that you will use the Services in
          accordance with applicable law. Any contravention of applicable law as a
          result of your use of these Services is your sole responsibility,
          and&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;accepts no liability for the same.</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >4.2</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >PROFILE OWNERSHIP AND EDITING RIGHTS</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;ensures easy access to the&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Teacher</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&rsquo;s</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >/Institute</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;by providing a tool to update your profile
          information.&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;reserves the right of ownership of all the&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Teacher</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&rsquo;s</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >/Institute</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&rsquo;s profile and photographs and to moderate the changes or updates
          requested by&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Teacher</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >s</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >/Institute</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >s. However,&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Teacher</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&rsquo;s</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >/Institute</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;takes the independent decision whether to publish or reject the
          requests submitted for the respective changes or updates. You hereby
          represent and warrant that you are fully entitled under law to upload
          all content uploaded by you as part of your profile or otherwise while
          using&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Teacher</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&rsquo;s</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >/Institute</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&rsquo;s services, and that no such content breaches any third party
          rights, including intellectual property rights. Upon becoming aware of a
          breach of the foregoing representation,&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Teacher</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&rsquo;s</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >/Institute</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;may modify or delete parts of your profile information at its
          sole discretion with or without notice to you.</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >4.3</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;STUDENT</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;STORIES DISPLAY RIGHTS OF&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >SHIKSHATAM</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >4.3.1</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >All Critical Content is content created by the Users of www.</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >.com (&ldquo;Website&rdquo;) and the clients of&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;customers and&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Teacher</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&rsquo;s</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >/Institute</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >, including the End-Users. As a platform,&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;does not take responsibility for Critical Content and its role
          with respect to Critical Content is restricted to that of an
          &lsquo;intermediary&rsquo; under the Information Technology Act, 2000.
          The role of&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;and other legal rights and obligations relating to the Critical
          Content are further detailed in Clauses 3.</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >4</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;and&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >4</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;of these Terms.&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&rsquo;s Feedback Collection and Fraud Detection Policy, is annexed as
          the Schedule hereto, and remains subject always to these Terms.</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >4.3.2</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;reserves the right to collect feedback and Critical Content for
          all the&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Teacher</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&rsquo;s</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >/Institute</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;listed on the Website.</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >4.3.3</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;shall have no obligation to pre-screen, review, flag, filter,
          modify, refuse or remove any or all Critical Content from any Service,
          except as required by applicable law.</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >4.3.4</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >You understand that by using the Services you may be exposed to
          Critical Content or other content that you may find offensive or
          objectionable.&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;shall not be liable for any effect on&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Teacher</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&rsquo;s</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >/Institute</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&rsquo;s business due to Critical Content of a negative nature. In
          these respects, you may use the Service at your own risk.&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;however, as an &lsquo;intermediary, takes steps as required to
          comply with applicable law as regards the publication of Critical
          Content. The legal rights and obligations with respect to Critical
          Content and any other information sought to be published by Users are
          further detailed in Clauses 3.9 and 5 of these Terms.</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >4.3.5</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;will take down information under standards consistent with
          applicable law, and shall in no circumstances be liable or responsible
          for Critical Content, which has been created by the Users. The
          principles set out in relation to third party content in the terms of
          Service for the Website shall be applicable mutatis mutandis in relation
          to Critical Content posted on the Website.</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >4.3.6</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >If&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;determines that you have provided inaccurate information or
          enabled fraudulent feedback,&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;reserves the right to immediately suspend any of your accounts
          with&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;and makes such declaration on the website alongside your
          name/your clinics name as determined by&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;for the protection of its business and in the interests of
          Users.</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >4.4</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >RELEVANCE ALGORITHM</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;has designed the relevance algorithm in the best interest of the
          End-User and may adjust the relevance algorithm from time to time to
          improve the quality of the results given to the patients. It is a pure
          merit driven, proprietary algorithm which cannot be altered for
          specific&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Teacher</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&rsquo;s</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >/Institute</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >.&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;shall not be liable for any effect on the&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Teacher</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&rsquo;s</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >/Institute</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&rsquo;s business interests due to the change in the Relevance
          Algorithm.</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >4.5INDEPENDENT SERVICES</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Your use of each Service confers upon you only the rights and
          obligations relating to such Service, and not to any other service that
          may be provided by&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >.</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >4.6</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >SHIKSHATAM</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;REACH RIGHTS</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;reserves the rights to display sponsored ads on the Website.
          These ads would be marked as &ldquo;Sponsored ads&rdquo;. Without
          prejudice to the status of other content,&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;will not be liable for the accuracy of information or the claims
          made in the Sponsored ads.&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;does not encourage the Users to visit the Sponsored ads page or
          to avail any services from them.&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;will not be liable for the services of the providers of the
          Sponsored ads.</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >You represent and warrant that you will use these Services in
          accordance with applicable law. Any contravention of applicable law as a
          result of your use of these Services is your sole responsibility,
          and&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;accepts no liability for the same.</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >4.</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >7</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Teacher</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&rsquo;s</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >/Institute</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;UNDERTAKING</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >The&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Teacher</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&rsquo;s</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >/Institute</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;is and shall be duly registered, licensed and qualified to
          practice medicine/ provide health care, wellness services, as per
          applicable laws/regulations/guidelines set out by competent authorities
          and the</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Teacher</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&rsquo;s</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >/Institute</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >shall not be part of any arrangement which will prohibit him/her from
          practicing medicine within the territory of India. The&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Teacher</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&rsquo;s</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >/Institute</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;shall at&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >all times ensure that all the applicable laws that govern
          the&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Teacher</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&rsquo;s</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >/Institute</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;shall be followed and utmost care shall be taken in terms of the
          consultation/ services being rendered.</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >4.10 USAGE IN PROMOTIONAL &amp; MARKETING MATERIALS</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >In recognition of the various offerings and services provided
          by&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;to&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Teacher</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&rsquo;s</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >/Institute</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >,&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Teacher</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&rsquo;s</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >/Institute</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;shall (subject to its reasonable right to review and approve):
          (a) allow&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;to include a brief description of the services provided
          to&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Teacher</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&rsquo;s</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >/Institute</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;in&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&rsquo;s marketing, promotional and advertising materials; (b)
          allow&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;to make reference to&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Teacher</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&rsquo;s</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >/Institute</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;in case studies, and related marketing materials; (c) serve as a
          reference to&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&rsquo;s existing and potential clients; (d) provide video logs,
          testimonials, e-mailers, banners, interviews to the news media and
          provide quotes for press releases; (e) make presentations at
          conferences; and/or (f) use the&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Teacher</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&rsquo;s</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >/Institute</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&rsquo;s name and/or logo, brand images, tag lines etc., within product
          literature, e-mailers, press releases, social media and other
          advertising, marketing and promotional materials.</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >7.LIMITATION OF LIABILITY</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >In no event, including but not limited to negligence, shall&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >, or any of its directors, officers, employees, agents or content or
          service providers (collectively, the &ldquo;Protected Entities&rdquo;)
          be liable for any direct, indirect, special, incidental, consequential,
          exemplary or punitive damages arising from, or directly or indirectly
          related to, the use of, or the inability to use, the Website or the
          content, materials and functions related thereto, the Services,
          User&rsquo;s provision of information via the Website, lost business or
          lost End-Users, even if such Protected Entity has been advised of the
          possibility of such damages. In no event shall the Protected Entities be
          liable for:</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >provision of or failure to provide all or any service by&nbsp;</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Teacher</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >s</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >/Institutes</span
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;to End- Users contacted or managed through the Website;</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >any content posted, transmitted, exchanged or received by or on behalf
          of any User or other person on or through the Website;</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >any unauthorized access to or alteration of your transmissions or data;
          or</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >any other matter relating to the Website or the Service.</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW82804494 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        ><span
          class="NormalTextRun SCXW82804494 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >In no event shall the total aggregate liability of the Protected
          Entities to a User for all damages, losses, and causes of action
          (whether in contract or tort, including, but not limited to, negligence
          or otherwise) arising from this Agreement or a User&rsquo;s use of the
          Website or the Services exceed, in the aggregate Rs. 1000/- (Rupees One
          Thousand Only).</span
        ></span
      ><span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW82804494 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW82804494 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        class="EOP SCXW82804494 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService, sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
</main>
