export default {   
  "Home": [
  {
    "question": "What is Guru Near Me and how does it work?",
    "answer": "Guru Near Me is a platform that helps students and parents find the best coaching institutes, home tutors, and educational resources based on location and needs."
  },
  {
    "question": "How can Guru Near Me help me find the best coaching classes near me?",
    "answer": "By entering your location and preferred subject, Guru Near Me will show you a list of top-rated coaching centers near you, with detailed information about courses, fees, and faculty."
  },
  {
    "question": "Can I find home tuition for board exams on Guru Near Me?",
    "answer": "Yes, Guru Near Me lists the best home tutors for board exam preparation. You can filter tutors based on location, subject expertise, and availability."
  },
  {
    "question": "Does Guru Near Me offer online education platforms for students?",
    "answer": "Yes, Guru Near Me connects students with top online learning platforms that offer live classes, recorded lectures, and interactive learning sessions."
  },
  {
    "question": "How can Guru Near Me help me find affordable coaching classes?",
    "answer": "Guru Near Me allows you to compare coaching institutes based on fees, faculty, and results, helping you choose affordable yet high-quality options."
  },
  {
    "question": "Are there any coaching institutes listed for competitive exams like NEET or JEE?",
    "answer": "Yes, Guru Near Me features coaching centers that specialize in NEET, JEE, CAT, UPSC, and other competitive exams with proven track records."
  },
  {
    "question": "How do I know if a coaching center or tutor is reliable?",
    "answer": "Guru Near Me provides reviews, ratings, and testimonials from other students and parents, helping you make an informed decision."
  },
  {
    "question": "Are there resources for teachers and coaches on Guru Near Me?",
    "answer": "Yes, Guru Near Me offers tools for teachers, coaches, and tutors to manage their schedules, classes, and student performance seamlessly."
  },
  {
    "question": "How can I register as a tutor or institute on Guru Near Me?",
    "answer": "If you’re a tutor or coaching center, you can easily register on the Guru Near Me platform, create a profile, and start listing your services for students to find."
  },
  {
    "question": "Is there a mobile app for Guru Near Me?",
    "answer": "Yes! Guru Near Me has a mobile app that allows parents and students to search for educational resources, manage bookings, and stay updated with ease on the go."
  }
  ],
  "k-12-coaching": [
    {
      "question": "What makes your platform the best for finding coaching classes?",
      "answer": "Our platform connects you with top-rated coaching centers that offer personalized learning plans, experienced faculty, and proven strategies to ensure excellent results in your exams."
    },
    {
      "question": "How can I find affordable coaching classes near me?",
      "answer": "Simply enter your location and preferences, and our platform will provide a list of affordable coaching centers nearby, along with details on courses, fees, and schedules."
    },
    {
      "question": "Do the coaching classes listed provide personalized attention for students?",
      "answer": "Yes, many coaching institutes on our platform offer personalized learning with small class sizes, one-on-one sessions, and customized study plans tailored to individual needs."
    },
    {
      "question": "Can I find coaching classes for all subjects or exams?",
      "answer": "Absolutely! Our platform offers coaching options for a variety of subjects and exams, including board exams, competitive exams, and specialized courses like programming and languages."
    },
    {
      "question": "Do coaching centers provide regular test series and mock exams?",
      "answer": "Yes, most coaching centers listed on our platform conduct regular test series and mock exams to help students gauge their preparation and improve their performance."
    },
    {
      "question": "How can I choose the best coaching center for my needs?",
      "answer": "We provide detailed profiles of each coaching center, including ratings, reviews, faculty information, and course structures, making it easy to compare and choose the right one for you."
    },
    {
      "question": "Are flexible batch timings available for students with busy schedules?",
      "answer": "Yes, many coaching institutes offer flexible batch timings, including evening and weekend options, so students can fit their coaching around their school or work commitments."
    },
    {
      "question": "Can I request a demo class before enrolling in a coaching institute?",
      "answer": "Yes, several coaching centers on our platform offer demo classes, allowing you to experience the teaching style and environment before making a final decision."
    },
    {
      "question": "How does the platform help students improve their weak areas?",
      "answer": "We connect students with coaching centers that provide personalized learning plans, which focus on strengthening weak areas through targeted practice and expert guidance."
    },
    {
      "question": "Is there a mobile app available to track progress in coaching classes?",
      "answer": "Yes, our mobile app lets parents and students track class schedules, attendance, test scores, and overall progress, ensuring smooth exam preparation and monitoring."
    }
  ],
  "home-tutor": [
    {
      "question": "How can I find the best home tutors near me?",
      "answer": "Our platform allows you to easily search for top-rated home tutors based on your location, subject, and grade level. Simply enter your preferences to get a list of tutors who fit your needs."
    },
    {
      "question":"Do you offer online home tuition for students of all grades?",
      "answer": "Yes, we provide online home tuition for students from all grades, including elementary, middle, and high school, ensuring expert guidance for every stage of education."
    },
    {
      "question": "Can I find tutors specializing in board exams preparation?",
      "answer": "Absolutely! We connect you with highly qualified tutors who specialize in board exam preparation, offering focused study plans for subjects like Math, Science, and English."
    },
    {
      "question": "How does your platform ensure the quality of home tutors?",
      "answer": "We carefully screen and verify all tutors on our platform, considering their qualifications, teaching experience, and reviews from previous students to ensure you get the best possible support."
    },
    {
        "question": "Can I request a free trial class with a tutor before committing?",
        "answer": "Yes, many of our home tutors offer a free demo session so you can evaluate their teaching style and see if they are a good fit for your learning needs."
      },
      {
        "question": "What is the advantage of online home tuition over traditional coaching centers?",
        "answer": "Online home tuition offers personalized attention, flexible scheduling, and the comfort of learning from home. It allows students to focus on their weak areas with one-on-one guidance."
      },   {
        "question": "Are the home tutors flexible with scheduling?",
        "answer": "Yes, most home tutors on our platform offer flexible scheduling options, allowing you to plan your classes around your school or extracurricular activities."
      },
      {
        "question":"Can I track my child’s progress through your platform?",
        "answer":"Yes, our platform provides progress-tracking tools for parents and students, including attendance, test scores, and tutor feedback, helping you monitor your child's learning journey."
      },
      {
        "question":"Do you offer home tutors for subjects beyond core academics?",
        "answer":"Yes, in addition to core subjects, we offer home tutors for a wide range of extracurricular activities, including music, dance, coding, and more, to help develop all-round skills."
      },
      {
        "question":"How do I choose the right tutor for my child?",
        "answer":"You can compare tutor profiles, read reviews, and filter options by expertise, experience, and price to make an informed decision and choose the best tutor for your child’s needs."
      }
  ],
  "neet-coaching": [
    {
      "question": "How can I find the best NEET coaching near me?",
      "answer": "Our platform helps you locate top NEET coaching institutes near your location, offering detailed information on course structure, faculty, and success rates, making it easier to choose the right coaching center."
    },
    {
      "question": "What makes NEET coaching on this platform stand out?",
      "answer": "We list only the best NEET coaching centers with proven track records of success. Each institute provides personalized study plans, experienced faculty, and comprehensive test preparation to ensure students perform their best."
    },
    {
      "question": "Do you offer affordable NEET coaching classes?",
      "answer": "Yes, our platform features a wide range of NEET coaching options, including affordable classes with flexible payment plans, ensuring high-quality education is accessible to everyone."
    },
    {
      "question": "Can I find NEET coaching for repeaters?",
      "answer": "Absolutely! Many of our listed institutes offer specialized NEET coaching programs for repeaters, focusing on strengthening weak areas and providing intensive exam preparation to boost chances of success."
    },
    {
      "question": "What kind of personalized support can I expect from NEET coaching?",
      "answer": "NEET coaching centers on our platform offer personalized attention through one-on-one doubt-solving sessions, performance tracking, and tailored study materials to suit individual student needs."
    },
    {
      "question": "How are NEET coaching institutes selected on your platform?",
      "answer": "We carefully select NEET coaching centers based on their success rates, faculty qualifications, student feedback, and the quality of their test series and study materials to ensure you get the best options."
    },
    {
      "question": "Are there online NEET coaching options available?",
      "answer": "Yes, we offer a variety of online NEET coaching programs, providing students the flexibility to study from home while receiving the same high-quality instruction and support as traditional coaching centers."
    },
    {
      "question": "What is the success rate of NEET coaching institutes on your platform?",
      "answer": "We provide information on the past performance and success rates of each NEET coaching center listed on our platform, helping you make an informed choice based on their track record of producing top rankers."
    },
    {
      "question": "Can I attend a demo class before enrolling in NEET coaching?",
      "answer": "Yes, many NEET coaching centers offer free demo classes so students can experience the teaching methodology and decide whether the institute meets their expectations before enrolling."
    },
    {
      "question": "How do I choose the right NEET coaching for my child?",
      "answer": "You can compare coaching centers based on faculty, course fees, success rates, and student reviews, allowing you to select the best NEET coaching that matches your child's learning style and goals."
    }
  ],
  "dance-classes": [
    {
      "question": "How can I find the best dance classes near me?",
      "answer": "Our platform helps you discover top-rated dance classes in your area, offering detailed information on class types, instructors, schedules, and reviews, making it easier to find the perfect match."
    },
    {
      "question": "Do you offer dance classes for beginners?",
      "answer": "Yes, we list dance classes specifically designed for beginners, providing step-by-step guidance from professional instructors to help you build confidence and master the basics."
    },
    {
      "question": "Are there online dance class options available?",
      "answer": "Absolutely! We offer a range of online dance classes, allowing students to learn from the comfort of their homes with flexible schedules, expert-led tutorials, and interactive sessions."
    },
    {
      "question": "What styles of dance classes are available on the platform?",
      "answer": "Our platform features a variety of dance styles, including hip-hop, ballet, contemporary, salsa, and more, ensuring there’s something for every dancer, regardless of their interest or skill level."
    },
    {
      "question": "Can I find affordable dance classes through your platform?",
      "answer": "Yes, we list a range of affordable dance classes to suit different budgets, ensuring high-quality instruction is accessible to everyone, whether you're learning for fun or professional growth."
    },
    {
      "question": "How do I choose the right dance class for my child?",
      "answer": "You can compare dance schools based on their teaching style, reviews, class sizes, and the specific dance styles they offer to find the best fit for your child’s interests and learning needs."
    },
    {
      "question": "Do you offer dance classes for kids and teens?",
      "answer": "Yes, we have dance classes specifically tailored for kids and teens, with age-appropriate curriculum, certified instructors, and a fun learning environment to keep them engaged and motivated."
    },
    {
      "question": "How can I ensure the dance classes are safe for my child?",
      "answer": "All dance schools on our platform are verified for their safety protocols, instructor qualifications, and child-friendly teaching environments, ensuring a safe and supportive space for learning."
    },
    {
      "question": "What is the typical duration of a dance course?",
      "answer": "The duration of dance courses varies by style and school, but most offer both short-term workshops and long-term programs, allowing students to choose based on their goals and availability."
    },
    {
      "question": "Can I attend a trial class before enrolling in a dance course?",
      "answer": "Yes, many dance schools offer free or discounted trial classes, giving you or your child the chance to experience the teaching style and atmosphere before committing to a full course."
    }
  ],
  "libraries": [
    {
      "question": "How can I find the best library near me?",
      "answer": "Our platform allows you to search for top-rated libraries in your area, providing details about their hours, services, and user reviews to help you find the perfect spot for your reading and study needs."
    },
    {
      "question": "Are there 24-hour libraries listed on your platform?",
      "answer": "Yes, we feature libraries that offer 24-hour access, ensuring that students and book lovers can find flexible study environments at any time, day or night."
    },
    {
      "question": "Can I find libraries with convenient subscription plans?",
      "answer": "Absolutely! We provide information on libraries that offer a variety of subscription plans, allowing students and book enthusiasts to choose a plan that fits their reading habits and budget."
    },
    {
      "question": "What types of resources do the libraries offer?",
      "answer": "Our platform lists libraries that offer a wide range of resources, including books, e-books, academic journals, and online databases, catering to both casual readers and serious researchers."
    },
    {
      "question": "How can I choose the right library for my study needs?",
      "answer": "You can compare libraries based on location, hours of operation, facilities (like study rooms and Wi-Fi), and user reviews to select the best environment for your academic or personal study requirements."
    },
    {
      "question": "Do the libraries offer spaces for group study?",
      "answer": "Yes, many libraries listed on our platform provide dedicated spaces for group study, allowing students to collaborate on projects or study together in a quiet, focused setting."
    },
    {
      "question": "Can I access online resources through these libraries?",
      "answer": "Several libraries offer access to digital resources, including e-books, research databases, and online learning materials, which you can use remotely to support your studies."
    },
    {
      "question": "How do I know if a library has the specific books I need?",
      "answer": "Our platform provides information on each library’s catalog, making it easy to check whether they have the specific books or materials you're looking for before visiting."
    },
    {
      "question": "Are there libraries with student discounts or special programs?",
      "answer": "Yes, many libraries offer special subscription rates, discounts, and programs for students, making it affordable and accessible to use their services."
    },
    {
      "question": "Can I reserve study rooms or borrow books online?",
      "answer": "Many libraries featured on our platform allow you to reserve study rooms or borrow books through online systems, making it convenient to plan your visits and manage your library activities from home."
    }
  ],
  "computer-classes": [
    {
      "question": "How can I find the best computer classes near me?",
      "answer": "Our platform makes it easy to locate top-rated computer classes in your area. You can search by course type, certification options, and location to find the best match for your learning needs."
    },
    {
      "question": "Do these computer classes offer certifications?",
      "answer": "Yes, most of the computer courses listed on our platform provide certification upon completion, helping you validate your skills for career advancement or academic credit."
    },
    {
      "question": "Are there beginner-friendly coding classes available?",
      "answer": "Absolutely! We offer a wide range of beginner-friendly coding classes, including courses in popular languages like Python, Java, and HTML, designed to help students start their programming journey with ease."
    },
    {
      "question": "What types of advanced computer courses are available?",
      "answer": "You can find advanced courses in areas like data science, machine learning, cloud computing, cybersecurity, and more. These courses are aimed at students and professionals looking to deepen their expertise in cutting-edge technology."
    },
    {
      "question": "Can I enroll in online computer courses?",
      "answer": "Yes, we feature both in-person and online computer courses. You can choose flexible online classes that allow you to learn at your own pace from the comfort of your home."
    },
    {
      "question": "How can I choose the right computer course for my career?",
      "answer": "We provide detailed course descriptions, including the skills covered, duration, and career prospects, making it easier for you to select a course that aligns with your career goals."
    },
    {
      "question": "Do these courses offer flexible schedules for working professionals?",
      "answer": "Yes, many of the computer classes listed on our platform offer evening, weekend, or self-paced online classes, making it convenient for working professionals to upgrade their skills without disrupting their work schedule."
    },
    {
      "question": "Are there affordable computer classes available?",
      "answer": "Yes, we feature a variety of computer courses, ranging from affordable options for beginners to more comprehensive programs for advanced learners. You can also compare prices and find courses that fit your budget."
    },
    {
      "question": "Can parents track the progress of their child in these computer classes?",
      "answer": "Several courses offer progress tracking tools and reports for parents to monitor their child’s performance, ensuring they are on track to complete the course and gain the necessary skills."
    },
    {
      "question": "Do these computer classes provide job placement assistance?",
      "answer": "Many of the advanced computer courses include job placement support, helping students connect with potential employers, prepare for interviews, and build a career in tech."
    }
  ],
  "commerce-classes": [
    {
      "question": "What subjects are covered in commerce coaching classes?",
      "answer": "Commerce coaching classes cover subjects like Accountancy, Economics, Business Studies, and Mathematics, ensuring comprehensive support for students."
    },
    {
      "question":  (cityName:string) => `How can I find the best commerce coaching classes near me in ${cityName}?`,
      "answer": (cityName:string) => `You can use platforms like Guru Near Me to search for top-rated commerce coaching classes in ${cityName} based on location, reviews, and course offerings.`
    },
    {
      "question": "Are there specialized commerce coaching classes for 12th board exam preparation?",
      "answer": "Yes, many commerce coaching centers offer specialized classes designed to help students excel in their 12th board exams with targeted syllabus coverage and expert guidance."
    },
    {
      "question": (cityName:string) => `Do the coaching centers in ${cityName} offer trial classes before enrollment?`,
      "answer": "Many coaching centers provide trial or demo classes to help you assess the quality of teaching before enrolling in a full course."
    },
    {
      "question": (cityName:string) => `What is the fee structure for commerce coaching classes in ${cityName}?`,
      "answer": "The fee structure varies depending on the coaching center and the course you choose. On average, you can expect fees to range from INR 10,000 to INR 50,000 per year."
    },
    {
      "question": "Can commerce coaching classes help with entrance exams like CA or CS?",
      "answer": (cityName:string) => `Yes, many coaching centers in ${cityName} offer preparatory classes for professional exams like CA, CS, and CMA along with regular board exam coaching.`
    },
    {
      "question": "Are there flexible timing options available for working students or those with other commitments?",
      "answer": "Yes, several coaching centers offer flexible class timings, including weekend batches and evening classes, to accommodate students' varying schedules."
    },
    {
      "question": (cityName:string) => `What is the success rate of students from commerce coaching centers in ${cityName}?`,
      "answer": (cityName:string) => `The success rate can vary by institute, but many top commerce coaching centers in ${cityName} boast high pass percentages and a strong track record of student success in both board and entrance exams.`
    },
    {
      "question": "Do coaching centers offer online classes or only in-person sessions?",
      "answer": "Many institutes now offer both online and offline commerce coaching classes to provide flexibility and convenience for students, especially those who prefer remote learning."
    },
    {
      "question": (cityName:string) => `How do I choose the best commerce coaching center in ${cityName}?`,
      "answer": "Look for factors such as faculty experience, student reviews, batch sizes, and past performance of the coaching center to find the best fit for your educational needs."
    }
  ],
  "defence-coaching": [
    {
      "question": "What is the CDS exam, and who can apply for it?",
      "answer": "The CDS exam is conducted by the UPSC for entry into the Indian Military Academy, Naval Academy, Air Force Academy, and Officers' Training Academy. Graduates from recognized universities are eligible to apply."
    },
    {
      "question": (cityName:string) => `How can I find the best CDS coaching centers near me in ${cityName}?`,
      "answer": (cityName:string) => `You can use online platforms to search for highly rated CDS coaching centers in ${cityName} based on factors like location, course offerings, and student reviews.`
    },
    {
      "question": (cityName:string) => `Are there specialized CDS coaching programs for female candidates in ${cityName}?`,
      "answer": (cityName:string) => `Yes, many coaching centers in ${cityName} offer specialized coaching for female candidates aspiring to join the defense forces, with tailored programs and support.`
    },
    {
      "question": (cityName:string) => `What is the duration of CDS coaching programs in ${cityName}?`,
      "answer": "CDS coaching programs typically range from 3 to 6 months, depending on the institute and the level of preparation required for each candidate."
    },
    {
      "question": (cityName:string) => `Do CDS coaching centers in ${cityName} offer study material and mock tests?`,
      "answer": "Yes, most reputed CDS coaching centers provide comprehensive study material, regular mock tests, and past exam papers to help students prepare effectively."
    },
    {
      "question": (cityName:string) => `What is the success rate of CDS coaching centers in ${cityName}?`,
      "answer": (cityName:string) => `Many top CDS coaching institutes in ${cityName} have a high success rate, with a significant percentage of students clearing the exam each year. Success rates can vary, so it’s important to check the past performance of the institute.`
    },
    {
      "question": "Is there an interview and physical training preparation included in the CDS coaching?",
      "answer": "Yes, many coaching centers offer preparation not only for the written CDS exam but also for the SSB interview and the physical fitness tests required after clearing the written exam."
    },
    {
      "question": (cityName:string) => `What is the fee structure for CDS coaching in ${cityName}?`,
      "answer": "The fee for CDS coaching varies between institutes, but it generally ranges from INR 20,000 to INR 50,000 depending on the duration and level of the program."
    },
    {
      "question": (cityName:string) => `Do the CDS coaching centers in ${cityName} offer flexible batch timings?`,
      "answer": "Yes, many coaching centers offer flexible batch timings, including weekend and evening batches, to accommodate students' other commitments like college or work."
    },
    {
      "question": (cityName:string) => `How do I choose the best CDS coaching center in ${cityName}?`,
      "answer": "When selecting a CDS coaching center, consider factors like the quality of faculty, availability of mock tests, previous track record, student reviews, and whether the institute offers comprehensive training for both written exams and interviews."
    }
  ],
  "language-classes": [
    {
      "question": "What are the benefits of enrolling in an English-speaking course?",
      "answer": "English-speaking courses help improve your fluency, confidence, and communication skills, which are essential for both professional and personal growth."
    },
    {
      "question": (cityName:string) => `How can I find the best language classes near me in ${cityName}?`,
      "answer": (cityName:string) => `You can find the best language classes by searching online, reading reviews, and comparing course structures offered by different institutes in ${cityName}.`
    },
    {
      "question": (cityName:string) => `Do language classes in ${cityName} offer courses for beginners?`,
      "answer": "Yes, many language institutes offer beginner-level courses, along with intermediate and advanced levels, catering to students of all proficiency levels."
    },
    {
      "question": (cityName:string) => `Can I take online language classes in ${cityName}?`,
      "answer": (cityName:string) => `Yes, many institutes in ${cityName} offer online language courses for flexibility, allowing you to learn from the comfort of your home with expert guidance.`
    },
    {
      "question": (cityName:string) => `What languages are typically taught in language institutes in ${cityName}?`,
      "answer": "Apart from English, many language institutes offer courses in other popular languages like French, German, Spanish, and even regional languages."
    },
    {
      "question": (cityName:string) => `What is the duration of language courses in ${cityName}?`,
      "answer": "The duration of language courses varies depending on the level and the language being learned. Typically, courses last between 3 to 6 months."
    },
    {
      "question": (cityName:string) => `Are there personalized language learning programs available in ${cityName}?`,
      "answer": "Yes, many language institutes offer personalized courses that focus on the individual needs of students, such as fluency, grammar, or vocabulary improvement."
    },
    {
      "question": (cityName:string) => `What is the fee structure for language classes in ${cityName}?`,
      "answer": "The fee for language classes can vary widely depending on the institute, language, and course level, but typically ranges between INR 5,000 to INR 20,000."
    },
    {
      "question": (cityName:string) => `Do language institutes in ${cityName} provide certification upon course completion?`,
      "answer": "Yes, most reputed language institutes offer certification upon successful completion of the course, which can be beneficial for job applications or further studies."
    },
    {
      "question": "How can I improve my fluency quickly through a language course?",
      "answer": "Regular practice, active participation in conversation exercises, and attending all classes are essential for quick improvement in language fluency. Some institutes also offer intensive courses for faster learning."
    }
  ],
  "cat-coaching": [
    {
      "question": (cityName:string) => `Why should I join a CAT coaching center in ${cityName}?`,
      "answer": "CAT coaching centers provide structured learning, expert guidance, mock tests, and personalized strategies to help you crack the CAT exam and secure admission to top MBA colleges."
    },
    {
      "question": (cityName:string) => `How can I find the best CAT coaching center in ${cityName}?`,
      "answer": "To find the best CAT coaching, research online reviews, ask for recommendations from past students, and compare course structures, faculty experience, and success rates of different institutes."
    },
    {
      "question": "What is the typical duration of a CAT preparation course?",
      "answer": "The duration of CAT preparation courses ranges from 6 months to 1 year, depending on whether you choose regular, weekend, or crash courses."
    },
    {
      "question": "Are there personalized learning plans in CAT coaching?",
      "answer": "Yes, many top CAT coaching centers offer personalized learning plans, including one-on-one mentorship, tailored test-taking strategies, and focus on individual weak areas."
    },
    {
      "question": (cityName:string) => `What is the fee structure for CAT coaching in ${cityName}?`,
      "answer": (cityName:string) => `The fee for CAT coaching in ${cityName} varies by institute, typically ranging from INR 25,000 to INR 60,000 depending on the course duration and additional features like mock test series.`
    },
    {
      "question": (cityName:string) => `Do coaching centers in ${cityName} offer mock tests and test series for CAT?`,
      "answer": "Yes, most reputed CAT coaching centers offer extensive mock test series, both online and offline, to help students familiarize themselves with the exam pattern and improve their time management skills."
    },
    {
      "question": (cityName:string) => `Can I take CAT coaching online in ${cityName}?`,
      "answer": (cityName:string) => `Yes, many institutes in ${cityName} offer online coaching options, providing flexibility with live classes, recorded sessions, and online test series for students who prefer to study from home.`
    },
    {
      "question": (cityName:string) => `What is the success rate of CAT coaching centers in ${cityName}?`,
      "answer": "Success rates vary between coaching centers, but leading institutes boast impressive success stories, with many students scoring 90+ percentiles in CAT and securing seats in top MBA colleges."
    },
    {
      "question": (cityName:string) => `Do CAT coaching centers in ${cityName} provide study material?`,
      "answer": "Yes, most coaching centers offer comprehensive study materials, including books, notes, question banks, and access to online resources, ensuring that students have all the tools they need for exam preparation."
    },
    {
      "question": (cityName:string) => `Is there a crash course available for last-minute CAT preparation in ${cityName}?`,
      "answer": "Yes, many coaching centers offer crash courses that focus on revision, time management, and rapid problem-solving techniques for students preparing in the final months before the exam."
    }
  ],
  "iit-jee-coaching": [
    {
      "question": "Why is IIT-JEE coaching important for cracking the exam?",
      "answer": "IIT-JEE coaching provides expert guidance, structured learning, regular practice, and personalized strategies to help students excel in one of the toughest entrance exams in India."
    },
    {
      "question": (cityName:string) => `How can I find the best IIT-JEE Mains coaching in ${cityName}?`,
      "answer": "To find the best coaching, check online reviews, success stories, faculty qualifications, and compare features like study materials, test series, and past results of different institutes."
    },
    {
      "question": (cityName:string) => `What is the fee structure for IIT-JEE coaching in ${cityName}?`,
      "answer": (cityName:string) => `The fees for IIT-JEE coaching in ${cityName} typically range from INR 50,000 to INR 1.5 lakh per year, depending on the institute, course duration, and additional services like doubt-solving sessions and personalized mentoring.`
    },
    {
      "question": "Are there different courses available for JEE Main and JEE Advanced?",
      "answer": "Yes, many coaching institutes offer separate courses for JEE Main and JEE Advanced, with specific focus areas to help students perform well in both exams."
    },
    {
      "question": (cityName:string) => `Do coaching institutes in ${cityName} offer online classes for IIT-JEE preparation?`,
      "answer": (cityName:string) => `Yes, several coaching institutes in ${cityName} offer online courses, including live classes, recorded lectures, and online test series, allowing students to study from home with flexibility.`
    },
    {
      "question": "How early should I start IIT-JEE coaching?",
      "answer": "It is recommended to start IIT-JEE coaching from class 11, but many institutes also offer foundation courses for students from class 9 and 10 to build a strong base in physics, chemistry, and mathematics."
    },
    {
      "question": (cityName:string) => `What are the key features of IIT-JEE coaching in ${cityName}?`,
      "answer": "Key features include expert faculty, comprehensive study materials, regular mock tests, personalized attention, doubt-clearing sessions, and performance analysis to ensure thorough preparation."
    },
    {
      "question": "Do coaching institutes offer scholarships or discounts for IIT-JEE courses?",
      "answer": (cityName:string) => `Yes, many coaching institutes in ${cityName} offer scholarships and fee discounts based on merit or performance in entrance tests, helping students reduce the financial burden of preparation.`
    },
    {
      "question": "How frequently are mock tests conducted during IIT-JEE coaching?",
      "answer": "Most coaching institutes conduct regular mock tests, typically weekly or monthly, to help students assess their progress, identify weak areas, and improve their exam-taking strategies."
    },
    {
      "question": "Is personalized attention available in IIT-JEE coaching institutes?",
      "answer": "Yes, many coaching centers provide personalized attention, through small batches, individual mentorship, and targeted support to address the unique needs of each student during their preparation journey."
    }
  ],
  "gmat-coaching": [
    {
      "question": "Why should I join GMAT coaching for exam preparation?",
      "answer": "GMAT coaching provides structured study plans, expert guidance, and practice with real GMAT questions, helping students achieve higher scores and enhance their MBA or graduate school applications."
    },
    {
      "question": (cityName:string) => `How can I find the best GMAT coaching institute near me in ${cityName}?`,
      "answer": (cityName:string) => `You can find the best GMAT coaching in ${cityName} by researching online reviews, success rates, faculty expertise, study materials, and comparing coaching fees and batch sizes.`
    },
    {
      "question": "What is the typical duration of a GMAT coaching course?",
      "answer": "GMAT coaching programs usually range from 2 to 4 months, depending on the course intensity and whether students are opting for regular or fast-track batches."
    },
    {
      "question": (cityName:string) => `What is the fee structure for GMAT coaching in ${cityName}?`,
      "answer": (cityName:string) => `GMAT coaching fees in ${cityName} generally range from INR 25,000 to INR 70,000, depending on the institute, course duration, and additional services like mock tests and personalized mentoring.`
    },
    {
      "question": (cityName:string) => `Do GMAT coaching centers in ${cityName} offer online classes?`,
      "answer": (cityName:string) => `Yes, many GMAT coaching centers in ${cityName} offer online classes, including live sessions, recorded lectures, online practice tests, and personalized feedback to allow flexibility for working professionals.`
    },
    {
      "question": "What are the key areas of focus in GMAT coaching?",
      "answer": "GMAT coaching covers all sections of the exam, including quantitative reasoning, verbal reasoning, integrated reasoning, and analytical writing, with special focus on improving speed, accuracy, and test strategies."
    },
    {
      "question": "Can GMAT coaching help improve my score in the quantitative section?",
      "answer": "Yes, GMAT coaching institutes focus on mastering quantitative questions by teaching strategies for data sufficiency and problem-solving, along with extensive practice to boost confidence and performance in the exam."
    },
    {
      "question": "How many mock tests are provided during GMAT coaching?",
      "answer": (cityName:string) => `Most GMAT coaching institutes in ${cityName} offer 10 to 15 full-length mock tests, along with section-wise tests, to help students simulate the actual exam environment and improve their time management skills.`
    },
    {
      "question": "Is personalized attention provided in GMAT coaching?",
      "answer": "Yes, many institutes offer personalized attention through one-on-one doubt-clearing sessions, feedback on performance, and customized study plans to meet individual learning needs."
    },
    {
      "question": (cityName:string) => `Do coaching centers in ${cityName} offer assistance with GMAT test registration?`,
      "answer": "Yes, many GMAT coaching institutes assist with the registration process, including selecting test dates and understanding the exam format, ensuring a smooth experience for students preparing for the test."
    }
  ],
  "art-classes": [
    {
      "question": (cityName:string) => `What types of art classes are available in ${cityName}?`,
      "answer": (cityName:string) => `Art classes in ${cityName} offer a variety of options, including drawing, sketching, oil painting, acrylic painting, watercolor techniques, and mixed media art for all skill levels.`
    },
    {
      "question": "How can I find the best art classes near me?",
      "answer": "You can find the best art classes near you by checking online reviews, visiting local art studios, comparing course offerings, and considering factors like the expertise of instructors and class timings."
    },
    {
      "question": "Are art classes suitable for beginners?",
      "answer": (cityName:string) => `Yes, many art classes in ${cityName} cater to beginners by offering foundational courses in drawing and painting techniques, allowing students to learn at their own pace.`
    },
    {
      "question": (cityName:string) => `What is the average fee for art classes in ${cityName}?`,
      "answer": (cityName:string) => `The fees for art classes in ${cityName} typically range from INR 1,500 to INR 5,000 per month, depending on the type of art, duration, and expertise level of the instructor.`
    },
    {
      "question": "Can I take online art classes if I am unable to attend in person?",
      "answer": (cityName:string) => `Yes, several art studios and instructors in ${cityName} offer online art classes, where you can learn through live or recorded sessions, practice assignments, and receive feedback from expert teachers.`
    },
    {
      "question": "What materials do I need for art classes?",
      "answer": "The materials required depend on the type of class. For painting, you may need brushes, canvases, paints (oil, acrylic, or watercolor), and sketching tools. Many art institutes provide a materials list upon registration."
    },
    {
      "question": "How long does it take to learn painting or drawing in these classes?",
      "answer": "The duration to learn art varies based on individual progress, but beginner courses usually last 2 to 3 months, with more advanced courses offering continuous learning opportunities over several months."
    },
    {
      "question": (cityName:string) => `Are there specialized art classes for kids in ${cityName}?`,
      "answer": (cityName:string) => `Yes, many art classes in ${cityName} offer specialized programs for children, focusing on enhancing creativity, improving motor skills, and building artistic confidence through fun, interactive sessions.`
    },
    {
      "question": (cityName:string) => `Do art classes in ${cityName} offer certifications?`,
      "answer": "Some art institutes and professional art instructors offer certificates of completion, which can be useful for portfolio building or pursuing further studies in the arts."
    },
    {
      "question": "Can I join weekend art classes if I have a busy schedule?",
      "answer": (cityName:string) => `Yes, many art classes in ${cityName} offer weekend or flexible schedules to accommodate working professionals and students, allowing you to pursue your passion for art without disrupting your daily routine.`
    }
  ],
  "zumba-classes":  [
    {
      "question": "What are the benefits of joining Zumba classes?",
      "answer": "Zumba offers a fun way to burn calories, improve cardiovascular health, and boost overall fitness while dancing to energetic music."
    },
    {
      "question": (cityName:string) => `How do I find the best Zumba fitness classes near me in ${cityName}?`,
      "answer": "You can search online for local Zumba fitness studios, check reviews, and ask for recommendations from friends to find the best options near you."
    },
    {
      "question": "Are Zumba classes suitable for beginners?",
      "answer": "Yes, Zumba classes are designed to be inclusive for all fitness levels, including beginners. Instructors usually offer modifications to match different abilities."
    },
    {
      "question": "How often should I attend Zumba classes for fitness results?",
      "answer": "It is recommended to attend Zumba classes at least 3-4 times a week to see significant fitness results and build stamina."
    },
    {
      "question": "What should I wear to a Zumba class?",
      "answer": "Wear comfortable, breathable workout clothing and supportive shoes suitable for dance and cardio activities."
    },
    {
      "question": "How long is a typical Zumba class session?",
      "answer": "A typical Zumba class lasts around 45 minutes to 1 hour, depending on the instructor and studio schedule."
    },
    {
      "question": "Is Zumba effective for weight loss?",
      "answer": "Yes, Zumba is a high-energy cardio workout that can help burn calories and contribute to weight loss when paired with a healthy diet."
    },
    {
      "question": "Do I need any dance experience to join Zumba?",
      "answer": "No prior dance experience is needed to join Zumba classes. The routines are simple and designed to be enjoyable for everyone."
    },
    {
      "question": (cityName:string) => `What is the cost of Zumba classes in ${cityName}?`,
      "answer": (cityName:string) => `The cost of Zumba classes in ${cityName} can vary by studio, but they typically range from INR 1,000 to INR 2,500 per month. Many studios also offer trial classes or flexible payment options.`
    },
    {
      "question": (cityName:string) => `Can Zumba classes be attended online in ${cityName}?`,
      "answer": (cityName:string) => `Yes, many fitness centers in ${cityName} now offer virtual Zumba classes, allowing you to enjoy the workout from the comfort of your home.`
    }
  ],
  "competitive-exam": [
    {
      "question": "What are the benefits of joining a competitive exam coaching center?",
      "answer": "Coaching centers offer expert guidance, structured learning plans, and practice materials to help students prepare efficiently for exams."
    },
    {
      "question": (cityName:string) => `How do I choose the best competitive exam coaching near me in ${cityName}?`,
      "answer": "Look for institutes with experienced faculty, good track records, student reviews, and flexible learning options, including online classes."
    },
    {
      "question": (cityName:string) => `Which competitive exams can I prepare for in ${cityName} coaching centers?`,
      "answer": (cityName:string) => `Coaching centers in ${cityName} offer preparation for exams like UPSC, SSC, Banking, Railways, CAT, GATE, NEET, and more.`
    },
    {
      "question": (cityName:string) => `Are there online coaching options available for competitive exams in ${cityName}?`,
      "answer": "Yes, many coaching centers offer online courses for competitive exams, providing flexible schedules and access to study materials from home."
    },
    {
      "question": "How can competitive exam coaching help with time management and study strategies?",
      "answer": "Expert trainers offer personalized guidance on time management, exam techniques, and effective study strategies, maximizing your preparation efficiency."
    },
    {
      "question": (cityName:string) => `What study materials are provided by coaching centers in ${cityName}?`,
      "answer": "Most coaching centers provide comprehensive study material, including books, mock tests, previous year question papers, and access to online resources."
    },
    {
      "question": (cityName:string) => `What is the duration of competitive exam coaching programs in ${cityName}?`,
      "answer": "The duration of coaching programs varies depending on the exam, typically ranging from a few months to a year for comprehensive preparation."
    },
    {
      "question": "How important is mock testing in competitive exam preparation?",
      "answer": "Mock tests are crucial for assessing your knowledge, improving speed and accuracy, and familiarizing yourself with the exam pattern."
    },
    {
      "question": (cityName:string) => `Are there coaching centers in ${cityName} that offer special doubt-clearing sessions?`,
      "answer": "Yes, most coaching institutes offer regular doubt-clearing sessions to ensure that students fully understand complex topics."
    },
    {
      "question": (cityName:string) => `Can coaching centers in ${cityName} provide personalized learning plans for competitive exams?`,
      "answer": "Many institutes offer personalized learning plans based on your strengths, weaknesses, and specific exam requirements to optimize your preparation."
    }
  ],
  "music-classes": [
    {
      "question": "What types of music classes are offered at the music academy?",
      "answer": "We offer singing classes, piano lessons, guitar tutoring, and a variety of other instrument-based training, with options for both beginners and advanced learners."
    },
    {
      "question": "Are the music classes suitable for all age groups?",
      "answer": "Yes, our music classes cater to students of all age groups, from young children to adults, ensuring personalized attention and skill development at every stage."
    },
    {
      "question": "Do you offer trial classes for music lessons?",
      "answer": "Yes, we provide trial classes so you can experience our teaching methods and decide which instrument or class is the best fit for you."
    },
    {
      "question": "Can I learn more than one instrument at the music academy?",
      "answer": "Absolutely! You can enroll in multiple classes, such as guitar, piano, and singing lessons, depending on your interest and schedule."
    },
    {
      "question": "Are the music classes available online?",
      "answer": "Yes, we offer both in-person and online music lessons to accommodate students who prefer learning from the comfort of their homes."
    },
    {
      "question": "What qualifications do the instructors at your music academy have?",
      "answer": "Our instructors are experienced professionals and certified musicians who specialize in their respective fields, ensuring top-quality training."
    },
    {
      "question": "What is the duration of the music courses?",
      "answer": "The duration of the courses depends on the level of training. We offer short-term courses, as well as long-term programs that span several months."
    },
    {
      "question": "Is there a specific curriculum for each instrument?",
      "answer": "Yes, we have a structured curriculum for each instrument, focusing on technical skills, music theory, and practical performance to ensure holistic learning."
    },
    {
      "question": "How can I enroll in music classes at the academy?",
      "answer": (cityName:string) => `You can enroll through our website or visit our music academy in ${cityName}. We also provide phone support to assist with the enrollment process.`
    },
    {
      "question": "Do you offer performance opportunities for students?",
      "answer": "Yes, we organize regular student performances and recitals to help students build confidence and showcase their musical progress."
    }
  ]  
}
