@for (toast of toastService.toasts; track toast) {
    <ngb-toast
      [header]="toast.header" 
      [autohide]="toast.autoHide" 
      [delay]="toast.delay"
      [class]="toast.className"
      (hidden)="toastService.remove(toast)"
    >
        {{ toast.message }}
    </ngb-toast>
  }