<div class="search desktop-only">
  <div class="search__text">
    <div class="search__text--institute">
      <span><img src="assets/icons/search.png" alt="" /></span>
      <input
        class="desktop-only"
        type="search"
        placeholder="Type or Institute"
        [(ngModel)]="selectedSearchTerm"
        (ngModelChange)="onSearchTerm($event)"
        (focus)="onCategoryFocus()"
      />
      @if(showCategoryDropdown){
      <div class="search__results">
        <ng-container>
          <div class="search__results--title">Type</div>
          @for(type of categories; track type._id){
          <div class="search__results--item" (click)="onSelectCategory(type)">
            <img src="assets/icons/search-text.png" alt="" />
            <span>{{ type.type }}</span>
          </div>
          }

          <div class="search__results--title">Institute</div>
          @for(institute of institutes; track institute._id){
          <div
            class="search__results--item"
            (click)="onSelectInstitute(institute)"
          >
            <img src="assets/icons/search-text.png" alt="" />
            <span>{{ institute.name }}</span>
          </div>
          }
        </ng-container>
      </div>
      }
    </div>

    <div class="search__text--location ">
      <span><img src="assets/icons/location.png" alt="" /></span>
      <input
        type="search"
        placeholder="Location"
        [(ngModel)]="selectedLocation"
        (ngModelChange)="onSearchLocation($event)"
        (focus)="onLocationFocus()"
      />
      @if(showLocationDropdown){ @if(allCities$ | async; as allCities){
      <div class="search__location-dropdown">
        @for(city of allCities;track city._id){
        <ng-container>
          <div class="search__results--item" (click)="onSelectCity(city)">
            <img src="assets/icons/location.png" alt="Location Icon" />
            <span>{{ city.name }}</span>
          </div>
        </ng-container>
        }
      </div>
      } }
    </div>
  </div>
  <!-- <div class="search__action">
      <button class="btn btn-primary">Search</button>
    </div> -->
</div>
