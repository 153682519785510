@if(faq$ | async; as faqList){
  <div class="faq__title">{{title}}</div>
  <div ngbAccordion [closeOthers]="true">
    @for (item of faqList; track item) {
    <div ngbAccordionItem [collapsed]="item !== 'First'">
      <h2 ngbAccordionHeader>
        <button ngbAccordionButton>
         {{item.question}}
        </button>
      </h2>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody>
          <ng-template>
         {{item.answer}}
          </ng-template>
        </div>
      </div>
    </div>
    }
  </div>
}


