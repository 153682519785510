import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BlogCategoryResponse } from '../interface/blogs/blogCategory';
import { blogCategories, blogs } from '../utils/api.constant';
import { Observable, shareReplay } from 'rxjs';
import { BlogResponse } from '../interface/blogs/blog';

@Injectable({
  providedIn: 'root'
})
export class BlogService {
  
  constructor(private http: HttpClient) { }

  getBlogCategories(params: any = {}){
    return this.http.get<BlogCategoryResponse>(blogCategories, {params}).pipe(shareReplay(1))
  }

  getBlogCategoryById(categoryId: string){
    return this.http.get<any>(blogCategories+`/${categoryId}`).pipe(shareReplay(1))
  }

  getBlogs(params: any = {}){
    return this.http.get<BlogResponse>(blogs, {params}).pipe(shareReplay(1))
  }

  getBlogById(blogId: string, params: any= {}){
    return this.http.get<any>(blogs+`/${blogId}`, {params}).pipe(shareReplay(1))
  }
}
