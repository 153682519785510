import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {FormControl, FormGroup, FormsModule,ReactiveFormsModule, Validators} from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { AppFooterComponent } from "../shared/app-footer/app-footer.component";
import { AppHeaderComponent } from "../shared/app-header/app-header.component";
import { MetaService } from '../services/meta.service';

@Component({
  selector: 'app-report-issue',
  standalone: true,
  imports: [ ReactiveFormsModule, AppFooterComponent, AppHeaderComponent],
  templateUrl: './report-issue.component.html',
  styleUrl: './report-issue.component.scss'
})
export class ReportIssueComponent implements OnInit{
  orgid = new FormControl('00DHs000003XHzH')
  retURL = new FormControl('https://www.gurunearme.com/report-issue?submitted=true')
  name = new FormControl('', Validators.required)
  phone = new FormControl('', [
    Validators.required, 
    Validators.pattern('^[- +()0-9]+$'), 
    Validators.minLength(10), 
    Validators.maxLength(15)
  ])
  email = new FormControl('', [Validators.required, Validators.email])
  subject = new FormControl('', Validators.required)
  description = new FormControl('', Validators.required)
  showSubmitMessage: boolean = false

  @Input() submitted: boolean = false

  ngOnInit(): void {
    this.setMetaInfo()

    if(this.submitted){
      this.showSubmitMessage = true
    }
  }

  constructor(
    private metaService:MetaService
  ) {}

  setMetaInfo(){
    const title =
      'Report an issue with GuruNearMe';
    const description =
      'Got an issue with the content on GuruNearMe. Reach out to us to resolve your query.';
    this.metaService.setMeta(title, description);
  }

  isFormValid(){
    return this.orgid.valid && this.retURL.valid && this.description.valid && this.email.valid && this.name.valid && this.subject.valid && this.phone.valid
  }
}
