<div class="contact__title">Contact</div>
<div class="contact__subtitle">
  Got a question or query? Send your message to {{instituteName}} by filling the form below
</div>
<form [formGroup]="leadForm" >
  <div class="modal-body" class="contact__form">
    <div class="mb-3">
      <label for="name" class="form-label">Full Name</label>
      <input
        formControlName="name"
        type="text"
        class="form-control"
        placeholder="Enter your full name"
      />
    </div>
    <div class="mb-3">
      <label for="mobile" class="form-label">Mobile</label>
      <input
        formControlName="phone"
        type="text"
        class="form-control"
        placeholder="Enter mobile no"
      />
    </div>
    <div class="mb-3">
      <label for="message" class="form-label">Message</label>
      <textarea
        cols="30"
        rows="5"
        class="form-control"
        placeholder="Your message"
        formControlName="message"
      ></textarea>
    </div>
  </div>
</form>
<button type="submit" class="btn btn-primary mb-3" (click)="onSubmitRequest()">Send Enquiry</button>

