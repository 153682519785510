import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { RouterLink } from '@angular/router';
@Component({
  selector: 'app-internal-links',
  standalone: true,
  imports: [AsyncPipe,RouterLink],
  templateUrl: './internal-links.component.html',
  styleUrl: './internal-links.component.scss'
})
export class InternalLinksComponent {
  @Input() items$:Observable<any[]>;
  @Input() sectionTitle: string = 'Trending Searches'

}
