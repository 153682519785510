<app-header></app-header>
<div class="container">
  <div class="card px-3 py-2 mb-4 fs-6">
    <h1 class="text-center fs-3">Join Guru Near Me</h1>
    <form
      ngNoForm
      method="post"
      action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8&orgId=00DHs000003XHzH"
    >
      <div class="row mb-2">
        <input type="hidden" name="oid" value="00DHs000003XHzH" />
        <input
          type="hidden"
          name="retURL"
          value="https://www.gurunearme.com"
        />
        <div class="form-group col-md-6">
          <label for="first_name">First Name</label>
          <input
            type="text"
            id="first_name"
            name="first_name"
            class="form-control"
            placeholder="First Name"
          />
          <!-- <div *ngIf="contactForm.get('first_name')?.invalid && contactForm.get('first_name')?.touched" class="text-danger">
                    First Name is required.
                </div> -->
        </div>
        <div class="form-group col-md-6">
          <label for="last_name">Last Name</label>
          <input
            type="text"
            id="last_name"
            name="last_name"
            class="form-control"
            placeholder="Last Name"
          />
          <!-- <div *ngIf="contactForm.get('last_name')?.invalid && contactForm.get('last_name')?.touched" class="text-danger">
                    Last Name is required.
                </div> -->
        </div>
      </div>

      <div class="row mb-2" >
        <div class="form-group col-md-6">
          <label for="mobile">Mobile Number</label>
          <input
            type="tel"
            id="mobile"
            name="mobile"
            class="form-control"
            placeholder="Mobile Number"
          />
          <!-- <div *ngIf="contactForm.get('mobile')?.invalid && contactForm.get('mobile')?.touched" class="text-danger">
                    <div *ngIf="contactForm.get('mobile')?.errors?.['required']">Mobile Number is required.</div>
                    <div *ngIf="contactForm.get('mobile')?.errors?.['pattern']">Invalid Mobile Number.</div>
                </div> -->
        </div>
        <div class="form-group col-md-6">
          <label for="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            class="form-control"
            placeholder="Email"
          />
          <!-- <div *ngIf="contactForm.get('email')?.invalid && contactForm.get('email')?.touched" class="text-danger">
                    <div *ngIf="contactForm.get('email')?.errors?.['required']">Email is required.</div>
                    <div *ngIf="contactForm.get('email')?.errors?.['email']">Invalid Email.</div>
                </div> -->
        </div>
      </div>

      <div class="form-group mb-2">
        <label for="street">Street Address</label>
        <textarea
          id="street"
          name="street"
          class="form-control"
          placeholder="Street Address"
        ></textarea>
        <!-- <div *ngIf="contactForm.get('street')?.invalid && contactForm.get('street')?.touched" class="text-danger">
                Street Address is required.
            </div> -->
      </div>

      <div class="row mb-2">
        <div class="form-group col-md-6">
          <label for="city">City</label>
          <input
            type="text"
            id="city"
            name="city"
            class="form-control"
            placeholder="City"
          />
          <!-- <div *ngIf="contactForm.get('city')?.invalid && contactForm.get('city')?.touched" class="text-danger">
                    City is required.
                </div> -->
        </div>
        <div class="form-group col-md-6">
          <label for="state">State</label>
          <input
            type="text"
            id="state"
            name="state"
            class="form-control"
            placeholder="State"
          />
          <!-- <div *ngIf="contactForm.get('state')?.invalid && contactForm.get('state')?.touched" class="text-danger">
                    State is required.
                </div> -->
        </div>
      </div>

      <div class="form-group mb-2">
        <label for="00NHs00000mxShw">Institute Name</label>
        <input
          type="text"
          id="00NHs00000mxShw"
          name="00NHs00000mxShw"
          class="form-control"
          placeholder="Institute Name"
        />
        <!-- <div *ngIf="contactForm.get('00NHs00000mxShw')?.invalid && contactForm.get('00NHs00000mxShw')?.touched" class="text-danger">
                Institute Name is required.
            </div> -->
      </div>

      <button type="submit" class="btn btn-primary btn-block text-white">
        Submit
      </button>
    </form>
  </div>
</div>

<app-footer></app-footer>
