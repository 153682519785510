import { AfterViewInit, Component, Inject } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
  RouterOutlet
} from '@angular/router';
import { LocationService } from './services/location.service';
import { DOCUMENT } from '@angular/common';
import { ToastComponent } from './shared/toast/toast.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, ToastComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements AfterViewInit {
  constructor(
    private router: Router,
    private _route: ActivatedRoute,
    private meta: Meta,
    private locationService: LocationService,
    @Inject(DOCUMENT) private dom:Document // In SSR browser's `document` variable is not available. So, use this instead. Taken from this blog: https://www.tektutorialshub.com/angular/angular-canonical-url/
  ) {}

  async ngAfterViewInit() {
    this.router.events
      // .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        if (event instanceof NavigationStart) {
          // console.log('NavigationStart:', event.url);
        } else if (event instanceof NavigationEnd) {
          // console.log('NavigationEnd:', event.urlAfterRedirects);
          // gtag('config', 'G-JSMP4JQTYE', {
          //   page_path: event.urlAfterRedirects,
          // });

          const fragment = this._route.snapshot.fragment;
          if (fragment) {
            this.scrollToFragment(fragment);
          }

          const route = this.router.url === '/' ? '' : this.router.url

          let canonicalUrl = `https://www.gurunearme.com${route}`;
          if(this.dom.location.origin.includes('newui')){
            canonicalUrl = `https://newui.gurunearme.com${route}`;
          }

          const existingCanonical = this.dom.querySelector(
            'link[rel="canonical"]'
          );
          if (existingCanonical) {
            this.dom.head.removeChild(existingCanonical);
          }

          const link = this.dom.createElement('link');
          link.rel = 'canonical';
          link.href = canonicalUrl;
          this.dom.head.appendChild(link);

        }
      });
  }

  scrollToFragment(fragment: string) {
    const element = this.dom.getElementById(fragment);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  scrollToElement(section: any) {
    const element = this.dom.getElementById(section);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }
}