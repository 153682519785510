import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getAllInstituteTypes } from '../utils/api.constant';
import Category from '../interface/category';
import { Observable, shareReplay } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CategoryService {

  allCategories$: Observable<CategoryResponse>;

  constructor(
    private http: HttpClient
  ) {
    this.fetchAllCategories()
  }

  fetchSearchCategory(params: any = {}): Promise<any> {
    return this.http.get<any>(getAllInstituteTypes, { params }).toPromise()
   }

   fetchAllCategories() {
    this.allCategories$ = this.http
      .get<CategoryResponse>(getAllInstituteTypes).pipe(shareReplay(1))
  }
}

export interface CategoryResponse {
  success: boolean;
  message: string;
  data: Category[];
}