import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import Blog from '../interface/blogs/blog';
import { environment } from '@/environments/environment';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-blog-card',
  standalone: true,
  imports: [
    RouterLink,
    DatePipe
  ],
  templateUrl: './blog-card.component.html',
  styleUrl: './blog-card.component.scss'
})
export class BlogCardComponent {
  @Input() blogObj: Blog
  @Input() categoryId: string
  
  cmsUrl: string = environment.CMS_BASE_URL

}
