<a class="blog__card" 
    [routerLink]="'/blogs/article/'+blogObj.slug"                    
    [queryParams]="{'id': blogObj.documentId, 'typeId': categoryId}"
>
    <div class="blog__card--wrapper">
        <div class="blog__card--info">
            <div class="blog__card--info-name">
                {{blogObj.title}}
            </div>
            <div class="blog__card--info-brief">
                {{blogObj.description}}
            </div>
            <div class="blog__card--info-author">
                @if(blogObj.author?.avatar) {
                <img [src]="cmsUrl+blogObj.author.avatar.formats.thumbnail.url" alt="Author Icon" />
                }@else {
                <img src="/assets/icons/teacher-icon.png" alt="Author Icon" />
                }
                <div>
                    <span>{{blogObj.author ? blogObj.author.name: 'Anonymous'}}</span>
                    <span>{{blogObj.createdAt | date}}</span>
                </div>
            </div>
        </div>
        
        <div class="blog__card--media">
            @if(blogObj.cover){
                @if(blogObj.cover.formats.small){
                    <img [src]="cmsUrl+blogObj.cover.formats.small.url" alt="Blog Cover" />
                } @else if (blogObj.cover.formats.thumbnail) {
                    <img [src]="cmsUrl+blogObj.cover.formats.thumbnail.url" alt="Blog Cover" />
                } @else {
                    <img src="/assets/images/Placeholder.png" alt="Blog Cover" />
                }
            }@else{
                <img src="/assets/images/Placeholder.png" alt="Blog Cover" />
            }
        </div>
    </div>
</a>