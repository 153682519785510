import { response } from 'express';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class MetaService {
  constructor(
    private meta: Meta,
    private titleService: Title,
    private http: HttpClient
  ) {}

  setMeta(title: string, description: string) {
    this.titleService.setTitle(title);
    this.meta.updateTag({
      name: 'description',
      content: description,
    });
  }

  setOgTag(title: string, descriptions: string, logo: any='') {
    this.meta.updateTag({
      property: 'og:title',
      content: title,
    });
    this.meta.updateTag({
      property: 'og:description',
      content: descriptions,
    });

    if(logo){
      this.meta.updateTag({
        property: 'og:image',
        content: logo,
      });
    } else {
      this.meta.removeTag('property="og:image"')
    }

    this.meta.updateTag({ property: 'og:type', content: 'website' });
  }

  setTwitterCardTag(title: string, descriptions: string, logo: any='') {
    this.meta.updateTag({
      property: 'twitter:title',
      content: title,
    });
    this.meta.updateTag({
      property: 'twitter:description',
      content: descriptions,
    });

    if(logo){
      this.meta.updateTag({
        property: 'twitter:image',
        content: logo,
      });
    } else {
      this.meta.removeTag('property="twitter:image"')
    }
    this.meta.updateTag({ property: 'twitter:card', content: 'summary' });
  }

  getMeta() {
    return this.http.get<any>('assets/meta.json').toPromise();
  }
}
