import { MetaService } from '@/app/services/meta.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-terms',
  standalone: true,
  imports: [],
  templateUrl: './terms.component.html',
  styleUrl: './terms.component.scss',
})
export class TermsComponent implements OnInit {
  constructor(private metaService: MetaService){}

  ngOnInit(): void {
    this.setMetaInfo()
    window.scrollTo(0, 0);
  }

  setMetaInfo(){
    this.metaService.setMeta('GuruNearMe - Terms & Conditions', 'Terms & Conditions page for gurunearme.com')
  }
}
