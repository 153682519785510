import { Component, inject,Input,TemplateRef, ViewEncapsulation } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router, RouterOutlet } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { claimListing } from '../utils/api.constant';
import { HttpClient } from '@angular/common/http';
import { ToastService } from '../services/toast.service';
import { RouterLink } from '@angular/router';
@Component({
  selector: 'app-claim-listing',
  standalone: true,
  imports: [RouterOutlet,FormsModule,ReactiveFormsModule,CommonModule,RouterLink,],
  templateUrl: './claim-listing.component.html',
  styleUrl: './claim-listing.component.scss'
})
export class ClaimListingComponent {
  @Input() instituteId:string
  claimListingForm !: FormGroup ;

  constructor(private fb: FormBuilder,
    private toastService: ToastService,
    public router: Router,
  ) {}
  private modalService = inject(NgbModal);
  private http = inject(HttpClient);
  ngOnInit() {
    this.claimListingForm = this.fb.group({
      name: ['', Validators.required],
      mobile: ['', [Validators.required, Validators.pattern('^[0-9]{10}$')]],
      email: ['', [ Validators.email]],
      message: [''],
      inquiryType:['CLAIM'],
      institute:[this.instituteId]
    });
  }

  onSubmit() {
      // Make the API call
      this.http.post(claimListing, this.claimListingForm.value).subscribe({
        next: (response) => {
          this.toastService.showSuccess('Our support team will contact you shortly.', 'Thank you!')
          this.resetForm()
        },
        error: (error) => {
          this.toastService.showError('Failed:', error.message)
          this.resetForm()
        }
      });
  }

  resetForm() {
    this.claimListingForm.reset({
      inquiryType: 'CLAIM',
      institute: this.instituteId
    });
  }

  closeModal(modal: any) {
    modal.dismiss('Cross click');
    this.resetForm();
}
  
	openVerticallyCentered(content: TemplateRef<any>) {
		this.modalService.open(content, { centered: true });
	}

}