import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AppHeaderComponent } from '../shared/app-header/app-header.component';
import { AppFooterComponent } from '../shared/app-footer/app-footer.component';
import { RouterLink } from '@angular/router';
import { BlogService } from '../services/blog.service';
import { HttpParams } from '@angular/common/http';
import Blog, { BlogResponse } from '../interface/blogs/blog';
import { map, Observable, of } from 'rxjs';
import { AsyncPipe, DatePipe } from '@angular/common';
import { environment } from '@/environments/environment';
import { BlogSearchComponent } from '../blog-search/blog-search.component';
import { BlogCardComponent } from '../blog-card/blog-card.component';
import { InfiniteScrollDirective } from '../directives/infinite-scroll/infinite-scroll.directive';
import { MetaService } from '../services/meta.service';

@Component({
  selector: 'app-blog-search-results',
  standalone: true,
  imports: [
    AppHeaderComponent, 
    AppFooterComponent, 
    RouterLink,
    AsyncPipe,
    DatePipe,
    BlogSearchComponent,
    BlogCardComponent,
    InfiniteScrollDirective
  ],
  templateUrl: './blog-search-results.component.html',
  styleUrl: './blog-search-results.component.scss'
})
export class BlogSearchResultsComponent implements OnChanges{
  @Input('q') searchTerm: string

  blogs$: Observable<Blog[]> = of([])
  cmsUrl: string = environment.CMS_BASE_URL
  currentPage: number = 1
  moreBlogsRemaining: boolean = true
  loading:boolean = false

  constructor(
    private blogService: BlogService,
    private metaService: MetaService
  ){}

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['searchTerm']){
      // Reset values when search term changes
      this.blogs$ = of([])
      this.currentPage = 1
      this.moreBlogsRemaining = true

      // Fetch fresh data
      this.fetchBlogs()

      this.setMetaInfo()
    }
  }

  fetchBlogs(){
    if(this.searchTerm.trim() === ''){
      // Return empty array when nothing is searched
      this.blogs$ = of([])
      return
    }

    if(!this.moreBlogsRemaining){
      // If no more blogs remain to be fetched return
      return
    }

    this.loading = true

    // Get all the blogs for the searched term
    const params = new HttpParams()
    .set('filters[$or][0][title][$containsi]', this.searchTerm)
    .set('filters[$or][1][description][$containsi]', this.searchTerm)
    .append('populate', 'author')
    .append('populate', 'author.avatar')
    .append('populate', 'cover')
    .append('populate', 'category')
    .set('sort', 'createdAt:desc')
    .append('pagination[pageSize]', 25)
    .append('pagination[page]', this.currentPage)

    this.blogService.getBlogs(params).subscribe((response: BlogResponse) => {
      // Decide whether more data remains on the server
      // If the page fetched in `response` is the last page, then, set `moreBlogsRemaining` to false
      if(response.meta.pagination.page === response.meta.pagination.pageCount){
        this.moreBlogsRemaining = false
      }

      // Add new data to the existing list
      const newBlogs = response.data
      if (newBlogs.length) {
        // Append new blogs to the current list
        this.blogs$ = this.blogs$.pipe(
          map((existingBlogs: any[]) => [...existingBlogs, ...newBlogs])
        );
        this.currentPage++;  // Increment the page for the next fetch
      }
      this.loading = false
    });
  }

  setMetaInfo(){
    const title = `GuruNearMe Blog Search for the term "${this.searchTerm}"`
    const description = `Explore the list of blogs for the search term "${this.searchTerm}"`

    this.metaService.setMeta(title,description)
    this.metaService.setOgTag(title,description)
    this.metaService.setTwitterCardTag(title,description)
  }

}
