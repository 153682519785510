import { Component, Inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AppHeaderComponent } from '../shared/app-header/app-header.component';
import { AppFooterComponent } from '../shared/app-footer/app-footer.component';
import { Router, RouterLink } from '@angular/router';
import { BlogService } from '../services/blog.service';
import BlogCategory from '../interface/blogs/blogCategory';
import { forkJoin, map, Observable } from 'rxjs';
import Blog, { BlogResponse } from '../interface/blogs/blog';
import { HttpParams } from '@angular/common/http';
import { AsyncPipe, DatePipe, DOCUMENT, NgClass } from '@angular/common';
import { environment } from '@/environments/environment';
import { MarkdownModule } from 'ngx-markdown';
import { BlogCardComponent } from '../blog-card/blog-card.component';
import { MetaService } from '../services/meta.service';

@Component({
  selector: 'app-blog-details',
  standalone: true,
  imports: [
    AppHeaderComponent,
    AppFooterComponent,
    RouterLink,
    AsyncPipe,
    DatePipe,
    MarkdownModule,
    NgClass,
    BlogCardComponent
  ],
  templateUrl: './blog-details.component.html',
  styleUrl: './blog-details.component.scss',
})
export class BlogDetailsComponent implements OnChanges {
  @Input() id:string
  @Input() typeId: string
  @Input() isDraft:string = 'false'

  category$: Observable<BlogCategory>
  blog$: Observable<Blog>
  relatedBlogs$: Observable<Blog[]>
  breadcrumbs$: Observable<Array<any>>
  cmsUrl: string = environment.CMS_BASE_URL
  isBlogDraft: boolean = false

  constructor(
    private blogService: BlogService,
    private router: Router,
    private metaService: MetaService,
    @Inject(DOCUMENT) private dom:Document 
  ){}

  ngOnChanges(changes:SimpleChanges): void {
    // Identify if blog is draft or not
    if(changes['isDraft']){
      this.isBlogDraft = this.isDraft === 'true' ? true: false
    }

    if(changes['typeId']){
      // Get category object
      this.getCategoryDetail()
    }

    if(changes['id']){
      // Get Blog Object
      this.getBlogDetail()
    }
 
    // Generate Breadcrumbs
    this.generateBreadcrumbs()
    
    // Get Related Blogs
    this.getRelatedBlogs() 

    this.setMetaInfo()
  }

  getCategoryDetail(){
    this.category$ = this.blogService.getBlogCategoryById(this.typeId).pipe(
      map((response: any) => response.data) 
    );  
  }

  getBlogDetail(){
    let params = new HttpParams()
    .append('populate', 'author')
    .append('populate', 'author.avatar')
    .append('populate', 'cover')
    .append('populate', 'blocks')

    if(this.isBlogDraft){
      params = params.append('status', 'draft')
    }

    this.blog$ = this.blogService.getBlogById(this.id, params).pipe(
      map((response: any) => {
        return response.data
      }) 
    );  

  }

  generateBreadcrumbs(){
    this.breadcrumbs$ = forkJoin([this.category$, this.blog$]).pipe(
      map(([category, blog]) => {
          return [
              { name: 'Home', route: '/blogs' },  
              { name: category.name, route: `/blogs/category/${category.slug}`, queryParams: {typeId: category.documentId} },
              { name: blog.title }
          ];
      })
    )
  }

  getRelatedBlogs(){
    const params = new HttpParams()
    .set('filters[category][documentId]', this.typeId)
    .set('filters[documentId][$ne]', this.id)
    .set('pagination[limit]', '3') // Limit to 3 results
    .append('populate', 'author')
    .append('populate', 'author.avatar')
    .append('populate', 'cover')

    this.relatedBlogs$ = this.blogService.getBlogs(params).pipe(
      map((response: BlogResponse) => {
        return response.data
      }) 
    );
  }

  shareViaWhatsApp(blogObj:any){
    const text = blogObj.title
    const blogURL = `https://www.gurunearme.com${this.router.url}`
    const encodedText = encodeURIComponent(text+'\n'+blogURL)

    window.open(
      `https://wa.me/?text=${encodedText}`,
      '_blank'
    );
  }

  setMetaInfo(){
    this.blog$.subscribe((blogObj) => {
      const title = blogObj.title
      const description = blogObj.description

      let logo = ''

      if(blogObj.cover){
        if(blogObj.cover.formats.small){
          logo = this.cmsUrl+blogObj.cover.formats.small.url
        } else if(blogObj.cover.formats.thumbnail){
          logo = this.cmsUrl+blogObj.cover.formats.thumbnail.url
        }
      }

      this.metaService.setMeta(`GuruNearMe Blog - ${title}`,description)
      this.metaService.setOgTag(title,description,logo)
      this.metaService.setTwitterCardTag(title,description,logo)
    })

  }

}
