export const environment = {
    production: false,
    // BASE_URL: 'http://localhost:4000',
    BASE_URL: 'https://tserver.shikshatam.com',
    BUCKET_URL: 'https://shikshatambucket.blob.core.windows.net/images-dev/',
    // BASE_URL: 'https://server.shikshatam.com',
    // BUCKET_URL: 'https://shikshatambucket.blob.core.windows.net/images/',
    CMS_BASE_URL: 'https://cms.shikshatam.com',
    GA_KEY: ''
  };
  