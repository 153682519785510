import { ApplicationConfig, provideZoneChangeDetection, SecurityContext } from '@angular/core';
import { provideRouter, withComponentInputBinding, withInMemoryScrolling } from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideHttpClient, withFetch } from '@angular/common/http';
import { provideMarkdown } from 'ngx-markdown';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }), 
    provideRouter(
      routes, 
      withComponentInputBinding(), 
      withInMemoryScrolling({scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled'}) // Read here: https://angular.dev/api/router/InMemoryScrollingOptions#
    ), 
    provideClientHydration(),
    provideHttpClient(withFetch()),
    provideMarkdown({
      sanitize: SecurityContext.STYLE
    })
  ]
};
