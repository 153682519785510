
import { environment } from '@/environments/environment';

export const aggregateInstitutes =
  environment.BASE_URL + '/api/v1/aggregator/institute';
export const getCityByInstitute =
  environment.BASE_URL + '/api/v1/get/city/by/institute';
export const getAllInstituteTypes =
  environment.BASE_URL + '/api/v1/all/instituteType';
export const getAllInstitutes =
  environment.BASE_URL + '/api/v1/get/all/institute';
export const getDetailInstituteByUrlCode =
  environment.BASE_URL + '/api/v1/aggregator/institute/detail';
export const createInstituteLead =
  environment.BASE_URL + '/api/v1/create/institute/lead';
export const getPopularAndNewRisingInstitutes =
  environment.BASE_URL + '/api/v1/getPopularAndNewRisingInstitutes';
export const blogCategories = environment.CMS_BASE_URL + '/api/categories'
export const blogs = environment.CMS_BASE_URL + '/api/articles'
export const locality=environment.BASE_URL+'/api/v1/all/localities'
export const claimListing=environment.BASE_URL+'/api/v1/create/inquiry'
