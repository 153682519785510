import { Component } from '@angular/core';
import { SearchComponent } from '../search/search.component';
import { FormsModule } from '@angular/forms';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-mobile-search',
  standalone: true,
  imports: [FormsModule, AsyncPipe],
  templateUrl: './mobile-search.component.html',
  styleUrl: './mobile-search.component.scss'
})
export class MobileSearchComponent extends SearchComponent {

}
