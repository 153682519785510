export const normalizeTypeName = (inputString: String) => {
    return inputString
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^a-zA-Z0-9\-]/g, '-')
    .replace(/\-+/g, '-');
}

export const capitalize=(inputString:String)=> {
    return inputString.charAt(0).toUpperCase()+inputString.slice(1)
}

