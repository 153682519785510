import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import {
  createInstituteLead,
} from '../utils/api.constant';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CommonService {
  _http = inject(HttpClient);

  submitInstituteLead(body: any): Observable<any> {
    return this._http.post(createInstituteLead, body);
  }

}