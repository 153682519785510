<div class="empty__card">
  <div class="empty__card--wrapper">
    <div class="empty__card--media">
      <img src="/assets/images/no-data.png" alt="No data Image" />
    </div>
    <div class="empty__card--info">
      <div class="empty__card--info-title">We are working on adding institutes under "{{categoryName | titlecase }} in {{cityName}}"</div>
      <div class="empty__card--info-subtitle">
        Meanwhile, please try to search with a different query.
      </div>
      <!-- <div class="empty__card--action">
        <button class="btn btn-outline-primary">Go to Home</button>
      </div> -->
    </div>
  </div>
</div>
