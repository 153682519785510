import { Component, Inject, OnInit } from '@angular/core';
import { AppHeaderComponent } from '../shared/app-header/app-header.component';
import { AppFooterComponent } from '../shared/app-footer/app-footer.component';
import { RouterLink } from '@angular/router';
import { map, Observable } from 'rxjs';
import BlogCategory, { BlogCategoryResponse } from '../interface/blogs/blogCategory';
import { BlogService } from '../services/blog.service';
import { AsyncPipe, DatePipe, DOCUMENT } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { environment } from '@/environments/environment';
import { BlogSearchComponent } from "../blog-search/blog-search.component";
import { BlogCardComponent } from '../blog-card/blog-card.component';
import { BlogResponse } from '../interface/blogs/blog';
import { MetaService } from '../services/meta.service';

@Component({
  selector: 'app-blogs',
  standalone: true,
  imports: [
    AppHeaderComponent,
    AppFooterComponent,
    RouterLink,
    AsyncPipe,
    DatePipe,
    BlogSearchComponent,
    BlogCardComponent
],
  templateUrl: './blogs-home.component.html',
  styleUrl: './blogs-home.component.scss',
})
export class BlogsHomeComponent implements OnInit {
  categories$: Observable<BlogCategory[]>
  categoryWiseBlogs$: Observable<any[]>
  cmsUrl: string = environment.CMS_BASE_URL

  constructor(
    private blogService: BlogService,
    private metaService: MetaService,
    @Inject(DOCUMENT) private dom:Document 
  ){

  }

  ngOnInit(): void {
    const params = new HttpParams()
      .set('pagination[limit]', '1000')
      .set('sort', 'name')

    this.categories$ = this.blogService.getBlogCategories(params).pipe(
      map((response: BlogCategoryResponse) => response.data) // Extracting the 'data' field
    );

    this.generateCategoryWiseBlogList()
    this.setMetaInfo()
  }

  generateCategoryWiseBlogList(){
    const params = new HttpParams()
    .set('pagination[limit]', '30')
    .append('populate', 'author')
    .append('populate', 'author.avatar')
    .append('populate', 'cover')
    .append('populate', 'category')

    this.categoryWiseBlogs$ =  this.blogService.getBlogs(params).pipe(
      map((response: BlogResponse) => {
        const grouped = response.data.reduce((acc, article) => {
          const category = article.category
          const existingCategory = acc.find(c => c.documentId === category.documentId);
    
          if (existingCategory) {
            // Only allow 2 posts per category
            if(existingCategory.data.length < 2){
              existingCategory.data.push(article);
            }
          } else {
            acc.push({
              name: category.name,
              documentId: category.documentId,
              data: [article]
            });
          }
    
          return acc;
        }, []);
    
        return grouped;
      })
    );
  }

  setMetaInfo() {
    let logo=''
    const title =
      'Guru Near Me - Blogs';
    const description = 'Find informational content that will help you find the best teacher';
    
    if(this.dom.location.origin.includes('newui')){
       logo= `https://newui.gurunearme.com/src/assets/images/logo.png`;
    }else{
       logo= `https://www.gurunearme.com/src/assets/images/logo.png`;
    }
    
    this.metaService.setMeta(title, description);
    this.metaService.setOgTag(title,description,logo)
    this.metaService.setTwitterCardTag(title,description,logo)
  }
}
