<app-header [showHeaderSearch]=true></app-header>
<!-- @if(instituteObj){
  <div class="breadcrumb__wrapper">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="/">Home</a></li>
        <li class="breadcrumb-item"><a href="#">IIT JEE</a></li>
        <li class="breadcrumb-item active" aria-current="page">
          {{instituteObj.name}}
        </li>
      </ol>
    </nav>
  </div>
} -->
  <app-mobile-search></app-mobile-search>
@if(instituteObj){
  <div class="header border-top pt-5">
    <div class="header__info">
      <h1 class="header__info--title">
        {{instituteObj.name}}
        <button class="btn btn-sm btn-outline-primary desktop-only" (click)="shareViaWhatsApp()">
          Share <i class="fa-solid fa-share-nodes"></i>
        </button>
      </h1>
      <div class="header__info--subtitle">
        @if(instituteObj.currentStage === 'VERIFIED'){
          <div class="header__info--verified">
            <img src="assets/icons/verified-icon.svg" alt="Verified icon" />
            Verified
          </div>
        }@else {
          <app-claim-listing [instituteId]="instituteObj._id"></app-claim-listing>
        }
        <!-- <div class="header__info--rating">3.5 | 25 Reviews</div> -->
        <button class="btn btn-sm btn-outline-primary mobile-only" (click)="shareViaWhatsApp()">
          Share <i class="fa-solid fa-share-nodes"></i>
        </button>
      </div>
      <h2 class="header__info--loc fs-6">
        <span>
          <img src="assets/icons/location-icon.png" alt="Address Icon" />
        </span>
        {{instituteObj.address + ', ' + instituteObj.city.name + ', ' + instituteObj.state.name +' - ' + instituteObj.postalCode}}
      </h2>
      @if(teacherExperienceText) {
        <div class="header__info--teachers">
          <span>
            <img src="assets/icons/teacher-icon.png" alt="Teacher Icon" />
          </span>
          {{teacherExperienceText}}
        </div>
      }
    </div>
    <div class="header__action">
      <button class="btn btn-primary" [routerLink]="router.url" fragment="contactForm">Contact</button>
      @if(showDirectionsButton){
        <button class="btn btn-outline-primary" (click)="onDirectionsClick()">
          {{directionsButtonLabel}}
          <span>
            <i class="fa-solid fa-location-arrow"></i>
          </span>
        </button>
      }
    </div>
  </div>
}
@if(instituteObj && instituteObj.gallery.length > 0){
  <div class="gallery">
    <div class="carousel__wrapper">
      <ngb-carousel #instituteGallery>
        @for (item of instituteObj.gallery; track item._id) {
          <ng-template ngbSlide>
            <div class="carousel__media">
              <img 
                [src]="environment.BUCKET_URL+item.path" 
                alt="{{ instituteObj.name }} image {{ $index + 1 }}" />
            </div>
          </ng-template>
        }
      </ngb-carousel>
    </div>
    <div class="thumbnail">
      <div class="thumbnail__list">
        @for (item of instituteObj.gallery; track item._id) {

        <div class="thumbnail__item" (click)="selectImage(item)">
          <img 
            [src]="environment.BUCKET_URL+item.path"        
            alt="{{ instituteObj.name }} image {{ $index + 1 }}" 
          />
        </div>
      }
      </div>
    </div>
  </div>
}
@if(instituteObj){
  <div class="page">
    <main>
      <div class="about info__card">
        <div class="about__title">About {{instituteObj.name}}</div>
        <div class="about__description" [innerHTML]="instituteObj.briefInfo">
        </div>
        <div class="type">
          <div class="type__title">Types</div>
          <div class="type__list">
            @for (typeObj of instituteObj.type; track typeObj._id) {
              <div class="type__item">{{typeObj.type}}</div>
            }
          </div>
        </div>
        <div class="class">
          <div class="class__title">Classes</div>
          <div class="class__list">
            @for (classObj of instituteObj.classes; track classObj._id) {
              <div class="class__item">{{classObj.name}}</div>
            }
          </div>
        </div>
      </div>
      <div class="teacher info__card">
        <div class="teacher__title">
          Teachers in {{ instituteObj.name}}
        </div>
        <div class="teacher__list">
          @for (teacherObj of instituteData.teachersData; track teacherObj._id) {
            <div class="teacher__item">
              <div class="teacher__item--media">
                <img [src]="teacherObj.hasOwnProperty('profile') ? environment.BUCKET_URL+teacherObj.profile: 'assets/images/teacher-placeholder.jpeg'"  [alt]="teacherObj.mergedName" />
                @if(teacherObj.skills === 'EXPERT'){
                  <div class="teacher__item--tag">
                    <img src="assets/icons/popular-icon.png" alt="Expert Icon" />
                    Expert
                  </div>
                }
              </div>
              <div class="teacher__item--name">{{teacherObj.mergedName}}</div>
              <div class="teacher__item--degrees">
                <span>{{teacherObj.qualification}}</span>
              </div>
              <div class="teacher__item--experience">{{teacherObj.experience}} Years of Experience</div>
            </div>
          }
        </div>
      </div>
      @if(instituteData.batches.length > 0){
        <div class="batch info__card">
          <div class="batch__title">Batches</div>
          @for (entry of classMap | keyvalue; track entry.key) {
            <div class="batch__item">
              <div class="batch__item--class">
                <img src="assets/icons/class-icon.png" [alt]="entry.value+' icon'" />
                {{entry.value}}
              </div>
              @for(batchObj of batchData[entry.key];track batchObj._id){
                <div class="batch__item--class-info">
                  <div class="batch__item--class-subject">
                    <span>Subject: </span>
                    {{batchObj.subject}}
                  </div>
                  <div class="batch__item--class-name">
                    <span>Batch Name: </span>
                    {{batchObj.combinedBatchName}}
                  </div>
                  <div class="batch__item--class-timing">
                    <span>Timing: </span>
                    @if(batchObj.formattedStartTime === 'NA' || batchObj.formattedEndTime === 'NA'){
                      NA
                    }@else {
                      {{batchObj.formattedStartTime}} to {{batchObj.formattedEndTime}}
                    }
                  </div>
                </div>
              }
            </div>
          }
        </div>
      }
      <div class="location info__card">
        <div class="location__title">Location</div>
        <div class="location__info">
          <span>
            <img src="assets/icons/location-icon.png" alt="address icon" />
            {{instituteObj.address + ', ' + instituteObj.city.name + ', ' + instituteObj.state.name +' - ' + instituteObj.postalCode}}
          </span>
          @if(showDirectionsButton){
            <span (click)="onDirectionsClick()" role="button" tabindex="0">
              Get Directions
              <i class="fa-solid fa-location-arrow"></i>
            </span>
          }
        </div>
        <div class="location__map">
          <google-map 
            width="100%"
            height="450" 
            [options]="mapOptions"
          >
          <map-marker [position]="instituteLatLng" />
          </google-map>
        </div>
      </div>
      <div class="contact info__card" id="contactForm">
        <app-contact-form 
          [instituteId]="instituteObj._id"
          [instituteName]="instituteObj.name"
        ></app-contact-form>
      </div>
    </main>
    <aside>
      <div class="cta">
        <div class="cta__info">
          <div class="cta__info--title">Are you a tutor or an institute?</div>
          <div class="cta__info--subtitle">
            Join the growing network of 2000+ gurus for free and get found online
            easily online!
          </div>
          <div class="cta__info--action">
            <button class="btn btn-light" [routerLink]="'/enquiry'">Join as Guru</button>
          </div>
        </div>
      </div>
    </aside>
  </div>
}

<!-- <div class="faq">
  <app-faq 
    [faq$]="faq$"
    [title]="'FAQs about ' + instituteObj.name"
  ></app-faq>
</div> -->
<app-trending-searches 
  [sectionTitle]="'Related Categories in '+ instituteObj.city.name"
  [items$]="relatedCategories$"
  labelText="text"
></app-trending-searches>
<app-trending-searches 
  [sectionTitle]="'Related Categories in '+ instituteObj.locality.name "
  [items$]="categoriesByLocality$"
  labelText="text"
></app-trending-searches>
<app-trending-searches 
  sectionTitle="Related Institutes"   
  [items$]="relatedInstitutes$"
  labelText="text"
></app-trending-searches>
<app-footer></app-footer>
