import { Component, ElementRef, EventEmitter, HostListener, Input, Output, SimpleChanges,OnChanges, } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-filter',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './filter.component.html',
  styleUrl: './filter.component.scss'
})
export class FilterComponent implements OnChanges {
  @Input()items:any[]=[];
  @Input()label:string
  @Input()value:string
  @Input()totalItems: number = 0
  @Input()allowSearch = false

  @Output() filterChange = new EventEmitter<string>();
  @Output() itemSearch = new EventEmitter<string>();
  @Output() loadMoreItems = new EventEmitter<void>();

  showResults = false;
  showFilterDropdown = false;
  selectedId: string;
  selectedName:string
  searchTerm: string = ''

  constructor(
    private eRef: ElementRef,
  ){}


  ngOnInit(){
    if(this.value){
      const matchItems=this.items.find(item=>this.value==item._id)
      if(matchItems){
        this.selectedName=matchItems.name
        this.selectedId=matchItems._id
      }
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['items']) {
      this.selectedId=''
      this.selectedName=''
    }
  }


  showAdditionalDropdown() {
    this.showFilterDropdown = true;
  }
  
  hideAdditionalDropdown(event: any) {
    event.stopPropagation();
    this.showFilterDropdown = false;
  }

  onSelectId(item:any, event: any) {
      event.stopPropagation()

      this.selectedId=item._id
      this.selectedName=item.name||item.subType
      this.showFilterDropdown=false;
      this.emitSelectedId()
  }

  emitSelectedId() {
    this.filterChange.emit(this.selectedId);
  }

  clearSelection(event: any) {
    event.stopPropagation()

    this.selectedName ='';
    this.selectedId='';
    this.emitSelectedId()
  }
  
  @HostListener('document:click', ['$event'])
  clickOutside(event: Event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
     this.showFilterDropdown=false;
    }
  }

  onSearchChange(){
    this.itemSearch.emit(this.searchTerm);
  }

  onLoadMore(event: any){
    event.stopPropagation()
    this.loadMoreItems.emit()
  }

}
