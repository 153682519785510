import { Component, Input, OnInit, Inject } from '@angular/core';
import { AppHeaderComponent } from '../shared/app-header/app-header.component';
import { AppFooterComponent } from '../shared/app-footer/app-footer.component';
import { RouterLink } from '@angular/router';
import { BlogService } from '../services/blog.service';
import { HttpParams } from '@angular/common/http';
import Blog, { BlogResponse } from '../interface/blogs/blog';
import { map, Observable, of } from 'rxjs';
import { AsyncPipe, DatePipe, DOCUMENT } from '@angular/common';
import BlogCategory, { BlogCategoryResponse } from '../interface/blogs/blogCategory';
import { environment } from '@/environments/environment';
import { BlogSearchComponent } from '../blog-search/blog-search.component';
import { BlogCardComponent } from '../blog-card/blog-card.component';
import { InfiniteScrollDirective } from '../directives/infinite-scroll/infinite-scroll.directive';
import { MetaService } from '../services/meta.service';

@Component({
  selector: 'app-blogs-by-category',
  standalone: true,
  imports: [
    AppHeaderComponent, 
    AppFooterComponent, 
    RouterLink,
    AsyncPipe,
    DatePipe,
    BlogSearchComponent,
    BlogCardComponent,
    InfiniteScrollDirective
  ],
  templateUrl: './blogs-by-category.component.html',
  styleUrl: './blogs-by-category.component.scss',
})
export class BlogsByCategoryComponent implements OnInit{
  @Input() typeId: string

  blogs$: Observable<Blog[]> = of([])
  category$: Observable<BlogCategory>
  cmsUrl: string = environment.CMS_BASE_URL
  currentPage: number = 1
  moreBlogsRemaining: boolean = true

  constructor(
    private blogService: BlogService,
    private metaService: MetaService,
    @Inject(DOCUMENT) private dom:Document 
  ){}

  ngOnInit(): void {

    // Get category object
    this.category$ = this.blogService.getBlogCategoryById(this.typeId).pipe(
      map((response: any) => response.data) 
    );

    this.loadMoreBlogs()
    this.setMetaInfo()
  }

  loadMoreBlogs(){
    if(!this.moreBlogsRemaining){
      // If no more blogs remain to be fetched return
      return
    }

    // Get all the blogs of the category
    const params = new HttpParams()
    .set('filters[category][documentId]', this.typeId)
    .append('populate', 'author')
    .append('populate', 'author.avatar')
    .append('populate', 'cover')
    .append('sort', 'createdAt:desc')
    .append('pagination[pageSize]', 25)
    .append('pagination[page]', this.currentPage)


    this.blogService.getBlogs(params).subscribe((response: BlogResponse) => {
      // Decide whether more data remains on the server
      // If the page fetched in `response` is the last page, then, set `moreBlogsRemaining` to false
      if(response.meta.pagination.page === response.meta.pagination.pageCount){
        this.moreBlogsRemaining = false
      }

      // Add new data to the existing list
      const newBlogs = response.data
      if (newBlogs.length) {
        // Append new blogs to the current list
        this.blogs$ = this.blogs$.pipe(
          map((existingBlogs: any[]) => [...existingBlogs, ...newBlogs])
        );
        this.currentPage++;  // Increment the page for the next fetch
      }
    });
  }

  setMetaInfo() {
    this.category$.subscribe((categoryObj:any) => {
      let logo=''
      const title = `Guru Near Me - ${categoryObj.name} Blogs`;
      const description = `Find informational content about ${categoryObj.name}`
      
      if(this.dom.location.origin.includes('newui')){
        logo= `https://newui.gurunearme.com/src/assets/images/${categoryObj.name}.png`;
      }else{
        logo= `https://www.gurunearme.com/src/assets/images/${categoryObj.name}.png`;
      }
      
      this.metaService.setMeta(title, description);
      this.metaService.setOgTag(title,description,logo)
      this.metaService.setTwitterCardTag(title,description,logo)
    })
    
  }

}
