<ng-template #content let-modal>
	<div class="modal-header">
		<h4 class="modal-title">Claim Listing</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="closeModal(modal)"></button>
	</div>
	<div class="modal-body">
        <form [formGroup]="claimListingForm" (ngSubmit)="onSubmit()">
            <div class="form-row">
                <div class="form-group mb-2">
                    <label for="first_name">Name</label>
                    <input type="text" id="name" formControlName="name" class="form-control" placeholder="Name">
                    @if(claimListingForm.get('name')?.invalid && claimListingForm.get('name')?.touched){
                        <div class="text-danger">
                            Name is required.
                        </div>
                    }
                </div>
            </div>
      
            <div class="form-row">
                <div class="form-group mb-2">
                    <label for="mobile">Mobile Number</label>
                    <input type="tel" id="mobile" formControlName="mobile" class="form-control" placeholder="Mobile Number">
                    @if(claimListingForm.get('mobile')?.invalid && claimListingForm.get('mobile')?.touched){
                    <div class="text-danger">
                        @if(claimListingForm.get('mobile')?.errors?.['required']){
                        <div>Mobile Number is required.</div>
                        }
                        @if(claimListingForm.get('mobile')?.errors?.['pattern']){
                        <div>Invalid Mobile Number.</div>
                        }
                    </div>
                }
                </div>
                <div class="form-group mb-2">
                    <label for="email">Email</label>
                    <input type="email" id="email" formControlName="email" class="form-control" placeholder="Email">
                    @if(claimListingForm.get('email')?.invalid && claimListingForm.get('email')?.touched){
                    <div class="text-danger">
                        @if(claimListingForm.get('email')?.errors?.['email']){
                        <div>Invalid Email.</div>
                        }
                    </div>
                }
                </div>
            </div>
      
            <div class="form-group mb-2">
                <label for="street">Message</label>
                <textarea id="street" formControlName="message" class="form-control" placeholder="Message"></textarea>
            </div>
      
            <button type="submit" class="btn btn-primary btn-block text-white" [disabled]="claimListingForm.invalid" (click)="modal.dismiss()">Submit</button>
        </form>
	</div>
</ng-template>

<button class="btn mb-2 me-2 btn-secondary d-flex align-items-center justify-content-center gap-2 rounded-4 px-2 py-1" (click)="openVerticallyCentered(content)">
	Claim Listing
</button>
