<app-header></app-header>
<div class="hero">
  <div class="hero__header">
    <div class="hero__header--title">Find the best guru</div>
    <h1 class="hero__header--subtitle">
      Best Coaching Centre Near Me
    </h1>
  </div>
  <app-search 
    [showAllCategorySection]="showAllCategorySection" 
    class="d-flex flex-column align-items-center justify-content-center"
  ></app-search>
  <div class="stats">500+ Teachers & Institutes | 20+ Categories</div>
  <div class="character__left">
    <img src="assets/images/male-character.png" alt="Male Character" />
  </div>
  <div class="character__right">
    <img src="assets/images/female-character.png" alt="Female Character" />
  </div>
</div>
<div class="categories__wrapper">
  @for(item of hobbiesSectionData; track item){
    <div class="categories__grp">
      <div class="categories__grp--header">
        <div class="categories__grp--header-info">
          <div class="categories__grp--title">{{item.title}}</div>
          <div class="categories__grp--subtitle">
            {{item.subtitle}}
          </div>
        </div>
      </div>
      <div class="categories__grp--list">
        @for(item of item.items;track item.label){
          <a class="categories__item" [routerLink]="item.route" [queryParams]="item.queryParams">
            <div class="categories__item--media">
              <img [src]="'assets/images/' + item.label + '.png'" [alt]="item.label + ' Icon'" />
            </div>
            <div class="categories__item--label">{{ item.label }}
            </div>
          </a>
        }
      </div>
    </div>
}
</div>
@if(popularInstitutes$ | async; as popularInstitutes){
  <div class="popular__list">
    <div class="popular__card">
      <div class="popular__card--media">
        <img src="assets/icons/popular-icon.png" alt="Popular Icon " />
      </div>
      <div class="popular__card--info">
        <div class="popular__card--info-title">Popular now</div>
        <div class="popular__card--info-subtitle">
          Choose from over 200+ teachers and institutes
        </div>
      </div>
    </div>
    <div class="institute__list">
      @for(item of popularInstitutes; track item._id; let  i = $index){
        <div class="institute__card" > 
          <div class="institute__card--type">
            @if(item.typeChip>''){
              <span>{{item.typeChip}}</span>
          }
          @if(item.moreTypes.count>0){
            <span title={{item.moreTypes.title}}>+{{item.moreTypes.count}} more</span>
          }
          </div>

          <a class="institute__card--media" [routerLink]="'/institute/'+item.urlCode">
            <img 
              [src]="item.gallery.length > 0 ? SHIKSHATAMBUCKET_URL + item.gallery[0].path : 'assets/images/Placeholder.png'" 
              alt={{item.name}} 
              />
          </a>
          <a class="institute__card--info" [routerLink]="'/institute/'+item.urlCode">
            <div class="institute__card--info-name">
              {{item.name}}
            </div>
            @if(getTeacherAndExperienceText(item)){
              <div class="institute__card--info--teachers">
                {{getTeacherAndExperienceText(item)}}  
              </div>
            }
            <div class="institute__card--info-loc">
              <span>
              <img src="assets/icons/location-icon.png" alt="Address Icon" />
              </span>
              {{item.address + ', ' + item.cityName }}
            </div>
          </a>
        </div>
      }
    </div>
  </div>
}
@if(risingInstitutes$ | async; as risingInstitutes){
  <div class="new__list">
    <div class="new__card">
      <div class="new__card--media">
        <img src="assets/icons/new-icon.png" alt="New Rising Icon" />
      </div>
      <div class="new__card--info">
        <div class="new__card--info-title">New & Rising</div>
        <div class="new__card--info-subtitle">
          Choose from over 200+ teachers and institutes
        </div>
      </div>
    </div>
    <div class="institute__list">
      @for(item of risingInstitutes; track item._id){
        <div class="institute__card">
          <div class="institute__card--type">
            @if(item.typeChip>''){
            <span>{{item.typeChip}}</span>
            }
            @if(item.moreTypes.count>0){
              <span title={{item.moreTypes.title}}>+{{item.moreTypes.count}} more</span>
            }
            </div>
          <a class="institute__card--media" [routerLink]="'/institute/'+item.urlCode">
            <img 
              [src]="item.gallery.length > 0 ? SHIKSHATAMBUCKET_URL + item.gallery[0].path : 'assets/images/placeholder.png'" 
              alt={{item.name}} 
              />
          </a>
          <a class="institute__card--info" [routerLink]="'/institute/'+item.urlCode">
            <div class="institute__card--info-name">
              {{item.name}}
            </div>
            @if(getTeacherAndExperienceText(item)){
              <div class="institute__card--info--teachers">
                {{getTeacherAndExperienceText(item)}}  
              </div>
            }
            <div class="institute__card--info-loc">
              <span>
              <img src="assets/icons/location-icon.png" alt="Address Icon" />
              </span>
              {{item.address + ', ' + item.cityName }}
            </div>
          </a>
        </div>
      }
    </div>
  </div>
}
<div class="cta">
  <!-- <img src="assets/images/join-as-guru-cta-banner.png" alt="join-as-guru-banner" /> -->
  <div class="cta__info">
    <div class="cta__info--title">Are you a tutor or an institute?</div>
    <div class="cta__info--subtitle">
      Join the growing network of 2000+ gurus for free and get found online
      easily online!
    </div>
    <div class="cta__info--action">
      <button class="btn btn-light" [routerLink]="'/enquiry'">Join as Guru</button>
    </div>
  </div>
</div>

<div class="faq">
  <app-faq 
    [faq$]="faq$"
    title="FAQs about Guru Near Me"
  ></app-faq>
</div>
<app-internal-links
  [items$]="internalLinksItems$"
></app-internal-links>
<app-footer></app-footer>