<app-header></app-header>
<div class="container">
  <div class="card px-3 py-2 mb-4 fs-6">
    <h1 class="text-center fs-3">Raise a complaint or issue with GuruNearMe</h1>
    <form
        action="https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8&orgId=00DHs000003XHzH"
        method="POST"
        ngNoForm
      >
        <input 
            type="hidden" 
            name="orgid" 
            value="00DHs000003XHzH" 
            [formControl]="orgid"
        />
        <input
          type="hidden"
          name="retURL"
          value="https://www.gurunearme.com/report-issue?submitted=true"
          [formControl]="retURL"
        />

        <div class="form-group mb-2">
          <label for="name">Contact Name</label>
          <input
            id="name"
            class="form-control"
            maxlength="80"
            name="name"
            size="20"
            type="text"
            required
            [formControl]="name"
          />
          @if(name.invalid && name.touched){
            <div class="text-danger">Name is required.</div>
          }
        </div>

        <div class="form-group">
          <label for="phone">Phone</label>
          <input
            id="phone"
            class="form-control"
            maxlength="15"
            minlength="10"
            name="phone"
            size="20"
            type="tel"
            required
            [formControl]="phone"
          />
          @if(phone.invalid && phone.touched){
            <div class="text-danger">Please provide a valid phone no.</div>
          }
        </div>

        <div class="form-group mb-2">
          <label for="email">Email</label>
          <input
            id="email"
            class="form-control"
            maxlength="80"
            name="email"
            size="20"
            required
            type="text"
            [formControl]="email"
          />
          @if(email.invalid && email.touched){
            <div class="text-danger">Please provide a valid email id.</div>
          }
        </div>

        <div style="display: none" class="form-group">
          <label for="status">Status</label>
          <select value="New" id="status" class="form-control" name="status">
            <option value="">--None--</option>
            <option value="On Hold">On Hold</option>
            <option value="Escalated">Escalated</option>
            <option value="Closed">Closed</option>
            <option selected value="New">New</option>
          </select>
        </div>

        <div class="form-group mb-2">
          <label for="subject">Subject</label>
          <input
            id="subject"
            class="form-control"
            maxlength="80"
            name="subject"
            size="20"
            type="text"
            required
            [formControl]="subject"
          />
          @if(subject.invalid && subject.touched){
            <div class="text-danger">Subject is required.</div>
          }
        </div>

        <div class="form-group mb-2">
          <label for="description">Description</label>
          <textarea
            id="description"
            class="form-control"
            name="description"
            required
            [formControl]="description"
          ></textarea>
          @if(description.invalid && description.touched){
            <div class="text-danger">Description is required.</div>
          }
        </div>

        @if(showSubmitMessage){
          <div class="text-success mt-2">Thank you for submitting your complaint! We appreciate your feedback and will address it promptly.</div>
        }

        <div class="mt-3">
          <button type="submit" class="btn btn-primary" name="submit" [disabled]="!isFormValid()">
            Submit
          </button>
        </div>
      </form>
  </div>
</div>

<app-footer></app-footer>
