import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  latitude: Number = 0.0;
  longitude: Number = 0.0;
  permissionState: string = 'prompt'; // possible value are 'granted', 'denied', or 'prompt'

  constructor() { }

  locateUser(): Promise<GeolocationPosition> {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position: GeolocationPosition) => {
            this.latitude = position.coords.latitude;
            this.longitude = position.coords.longitude;
            this.permissionState = 'granted';
            resolve(position);
          },
          (error) => {
            if (error.code === 1) {
              this.permissionState = 'denied';
              reject('You have denied location access to this site')
            }else{
              reject(error.message)
            }
            if(error.code===3){
              reject('Unable to fetch your location.')
            }
          },
          {
            enableHighAccuracy: false,
            timeout: 8000,
            maximumAge: 7 * 24 * 60 * 60 * 1000,
          }
        );
      } else {
        reject('Geolocation feature is not supported in this browser');
      }
    });
  }
}

interface Position {
  coords: Coordinates;
}

interface Coordinates {
  latitude: Number;
  longitude: Number;
}
