import { AsyncPipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-trending-searches',
  standalone: true,
  imports: [RouterLink, AsyncPipe],
  templateUrl: './trending-searches.component.html',
  styleUrl: './trending-searches.component.scss'
})
export class TrendingSearchesComponent {
  @Input() items$:Observable<any[]>;
  @Input() labelText!:string
  @Input() sectionTitle: string = 'Trending Searches'

}
