<div class="additional">
    <span (click)="showAdditionalDropdown()">
     {{selectedName ? selectedName: label }}
     @if(selectedName){
     <i (click)="clearSelection($event)" class="fa fa-times cross-icon"></i>
     }
     @if(showFilterDropdown){
      <div class="additional--dropdown">
        @if(allowSearch){
          <div class="additional--dropdown-search" >
            <input type="search" placeholder="Search" [(ngModel)]="searchTerm" (ngModelChange)="onSearchChange()">
          </div>
        }
        @for(item of items; track item._id){
          <div class="additional--dropdown-item" (click)="onSelectId(item, $event)">
            <div>{{item.name || item.subType}}</div>
          </div>
        }
        @if(items.length < totalItems){
          <div class="additional--dropdown-load-more" (click)="onLoadMore($event)">
            <div>Load More</div>
          </div>
        }
      </div>
    }
    </span>
  
  </div>
