
<!-- desktop view -->
<div class="search">
  <div class="search__text">
    <div class="search__text--institute">
      <span><img src="assets/icons/search.png" alt="Search Icons" /></span>
      <!-- For mobile input box -->
      <input
        class="mobile-only"
        type="search"
        [(ngModel)]="selectedSearchTerm"
        (ngModelChange)="onSearchTerm($event)"
        placeholder="Type or Institute"
        (focus)="openMobileSearchForm()"
      />
      <input
        class="desktop-only"
        type="search"
        placeholder="Type or Institute"
        [(ngModel)]="selectedSearchTerm"
        (ngModelChange)="onSearchTerm($event)"
        (focus)="onCategoryFocus()"
      />
      @if(showCategoryDropdown){
        <div class="search__results desktop-only">
          <ng-container>
            <div class="search__results--title">Types</div>
            @for(type of categories; track type._id){
              <ng-container >
                <div
                  class="search__results--item"
                  (click)="onSelectCategory(type)"
                >
                  <img src="assets/icons/search-text.png" alt="Search Icon" />
                  <span>{{ type.type }}</span>
                </div>
              </ng-container>
            }
            <div class="search__results--title">Institutes</div>
            @for(institute of institutes; track institute._id){
              <ng-container>
                <div
                  class="search__results--item"
                  (click)="onSelectInstitute(institute)"
                >
                  <img src="assets/icons/search-text.png" alt="Search Icon" />
                  <span>{{ institute.name }}</span>
                </div>
              </ng-container>
            }
          </ng-container>
        </div>
      }
    </div>
  
    <div class="search__text--location desktop-only">
      <span><img src="assets/icons/location.png" alt="Location Icon" /></span>
      <input
        type="search"
        placeholder="Location"
        [(ngModel)]="selectedLocation"
        (ngModelChange)="onSearchLocation($event)"
        (focus)="onLocationFocus()"
      />
      @if(showLocationDropdown){
        @if(allCities$ | async; as allCities){
          <div class="search__location-dropdown">
            @for(city of allCities;track city._id){
              <ng-container>
                <div class="search__results--item" (click)="onSelectCity(city)">
                  <img src="assets/icons/location.png" alt="Location Icon" />
                  <span>{{ city.name }}</span>
                </div>
              </ng-container>
            }
          </div>
        }
      }
    </div>
  </div>
  <div class="search__action">
    <button class="btn btn-primary" [disabled]="loading" (click)="onSearch()">    
      @if(loading){<span class="spinner-border" style="width: 1.2rem; height: 1.2rem; border-width: 0.2em;"></span>}
      @else{<span>Search</span>}
    </button>
  </div>
</div>

<!--Mobile-View -->
@if(showMobileView){
  <div class="search__results mobile-only">
    <div class="search__mobile--header">
      <div
      class="search__mobile--header-close"
      (click)="showMobileView = false; (null)"
    >
      <img src="assets/icons/close-icon.png" alt="Close Icon" />
      <!-- <span>Close</span> -->
    </div>
      <div class="search__mobile--header-action">
        <button class="btn btn-primary" [disabled]="loading" (click)="onSearch()">
          @if(loading){<span class="spinner-border" style="width: 1.2rem; height: 1.2rem; border-width: 0.2em;"></span>} 
          @else{<span>Search</span>}
         </button>
      </div>
    </div>
    <div class="search__mobile">
      <div class="search__mobile--institute">
        <span><img src="assets/icons/search.png" alt="Search Icon" /></span>
        <input
          type="search"
          placeholder="Select Type or institute"
          [(ngModel)]="selectedSearchTerm"
          (ngModelChange)="onSearchTerm($event)"
          (focus)="onCategoryFocus()"
        />
      </div>
      <div class="search__mobile--location">
        <span><img src="assets/icons/location.png" alt="Location Icon" /></span>
        <input
          type="search"
          placeholder="Location"
          [(ngModel)]="selectedLocation"
          (ngModelChange)="onSearchLocation($event)"
          (focus)="onLocationFocus()"
        />
      </div>
    </div>
    @if(showCategoryDropdown){
      <div class="search__results--list">
        <div class="search__results--title">Types</div>
        @for(type of categories; track type._id){
          <ng-container>
            <div
              class="search__results--item"
              (click)="onSelectCategory(type)"
            >

              <img src="assets/icons/search-text.png" alt="Search Icon" />
              <span>{{ type.type }}</span>
            </div>
          </ng-container>
        }
        <div class="search__results--title">Institutes</div>
        @for(institute of institutes; track institute._id){
          <ng-container>
            <div
              class="search__results--item"
              (click)="onSelectInstitute(institute)"
            >
              <img src="assets/icons/search-text.png" alt="Search Icon" />
              <span>{{ institute.name }}</span>
            </div>
          </ng-container>
      }
      </div>
    }

    @if(showLocationDropdown){
      <ng-container>
        @if(allCities$ | async; as allCities){
          <div class="search__results--title">Location</div>
          @for(city of allCities; track city._id){
            <ng-container>
              <div
                class="search__results--item"
                (click)="onSelectCity(city)"
              >
                <img src="assets/icons/search-text.png" alt="Search Icon" />
                <span>{{ city.name }}</span>
              </div>
            </ng-container>
          }
        }
      </ng-container>
    }
  </div>
}

@if(showAllCategorySection){
  @if(allCategories$ | async; as allCategories){
    <div class="types">
      @for(type of allCategories.data; track type._id){
        <div class="types__item" (click)="showMobileView=true">
          <div class="types__item--icon">
            <img [src]="'assets/icons/' + type.type+ '.png'" [alt]="type.type + ' icon'" />
          </div>
          <div class="types__item--label" (click)="onSelectCategory(type)">
            {{ type.type }}
          </div>
        </div>
      }
    </div>
  }
}
