<div class="institute__card">
  <div class="institute__card--type">
  @if(getTypeDetails().typeChip>''){
  <span>{{ getTypeDetails().typeChip }}</span>
  }
  @if(getTypeDetails().count >''){
  <span [title]="getTypeDetails().title">+{{ getTypeDetails().count }} more</span>
  }
  </div>
  <div class="institute__card--media">
    <img [src]="instituteLogo" alt={{instituteName}}/>
  </div>
  <div class="institute__card--info">
    <div class="institute__card--info-name">{{instituteName}}</div>
    <div class="institute__card--info-teacher">
   {{teacherCountAndExperience}}
    </div>
    <div class="institute__card--info-loc">
      <span>
        <img src="assets/icons/location-icon.png" alt="Location Icon" />
      </span>
      {{instituteAddress}} 
    </div>
  </div>
</div>
