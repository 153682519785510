import { Component, inject, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from '@/app/services/common.service';
import { ToastService } from '@/app/services/toast.service';

@Component({
  selector: 'app-contact-form',
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: './contact-form.component.html',
  styleUrl: './contact-form.component.scss',
})
export class ContactFormComponent implements OnInit {
  @Input() instituteId!: string; 
  @Input() instituteName: string
  
  leadForm!: FormGroup;
  _formBuilder = inject(FormBuilder);
  _common = inject(CommonService);
  private _modalService = inject(NgbModal);

  constructor(private toastService: ToastService){}

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    this.leadForm = this._formBuilder.group({
      name: new FormControl(null, [Validators.required]),
      phone: new FormControl(null, [
        Validators.required,
        Validators.pattern('^[6-9][0-9]{9}$'),
      ]),
      message: new FormControl(null, [Validators.required]),
    });
  }

  onSubmitRequest() {
    if (this.leadForm.controls['name'].invalid) {
      this.toastService.showError('Please enter a valid name')
      return;
    }
    if (this.leadForm.controls['phone'].invalid) {
      this.toastService.showError('Please enter a valid phone no')
      return;
    }
    if (this.leadForm.controls['message'].invalid) {
      this.toastService.showError('Please enter a valid message')
      return;
    }
    
    this.leadForm.value.institute = this.instituteId;
    this._common.submitInstituteLead(this.leadForm.value).subscribe({
      next: (_) => {
        this.leadForm.reset();
        this.toastService.showSuccess('Institute will contact you soon.', 'Thank you!');
        this.close();
      },
      error: (_) => {
        alert(_);
      },
    });
  }

  close() {
    this._modalService.dismissAll();
  }
}
