<header>
  <div class="branding" [routerLink]="'/'">
    <img src="assets/images/logo.png" alt="Guru Near Me Logo" />
  </div>
  <div class="navigation" [ngClass]="showMenu ? '' : 'desktop-only'">
    @if(showHeaderSearch){
    <app-header-search></app-header-search>
    }
    <a class="navigation__item" [routerLink]="'/'">Home</a>
    <!-- <div class="navigation__item" [routerLink]="'/institutes'">
      Find Institues
    </div> -->
    <a class="navigation__item" [routerLink]="'/blogs'">Blogs</a>
    <a class="navigation__item" [routerLink]="'/report-issue'">Report Issue</a>
    <a class="navigation__item" [routerLink]="'/enquiry'">
      <button class="btn btn-secondary">Join as Guru</button>
    </a>
  </div>
  <div class="menu-toggle" (click)="toggleMenu()">
    <img
      [src]="
        showMenu
          ? 'assets/icons/close-icon.png'
          : 'assets/icons/hamburger-icon.png'
      "
      alt="Close Icon"
    />
  </div>
</header>

