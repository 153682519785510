<main>
  <div
    class="OutlineElement Ltr SCXW213894907 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <h1
      class="Paragraph SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: center;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW213894907 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 22pt;
          line-height: 36.6917px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Privacy Policy</span
        ></span
      ><span
        class="EOP SCXW213894907 BCX0"
        data-ccp-props='{"201341983":0,"335551550":2,"335551620":2,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 22pt;
          line-height: 36.6917px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        >&nbsp;</span
      >
    </h1>
  </div>
  <div
    class="OutlineElement Ltr SCXW213894907 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: center;
        text-indent: 0px;
      "
    >
      <span
        class="EOP SCXW213894907 BCX0"
        data-ccp-props='{"201341983":0,"335551550":2,"335551620":2,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 22pt;
          line-height: 36.6917px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW213894907 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW213894907 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshtam</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >IT</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;Private Limited (&ldquo;us&rdquo;, &ldquo;we&rdquo;, or
          &ldquo;</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&rdquo;, which also includes its affiliates) is the author and
          publisher of the internet resource www.</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >shikshatam</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >.com (&ldquo;Website&rdquo;) on the world wide web as well as the
          software and applications provided by&nbsp;</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >, including but not limited to the mobile application &lsquo;</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&rsquo;</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;(</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >together with the Website, referred to as the
          &ldquo;Services&rdquo;).</span
        ></span
      ><span
        class="EOP SCXW213894907 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW213894907 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        class="EOP SCXW213894907 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW213894907 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW213894907 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >This privacy policy (&quot;Privacy Policy&quot;) explains how we
          collect, use, share, disclose and protect Personal information about
          the Users of the Services, including the&nbsp;</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Teachers/Institutes</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;(as defined in the Terms of Use, which may be accessed via the
          following weblink https://</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >shikshatam</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >.com/privacy (the &ldquo;Terms of Use&rdquo;)), the End-Users (as
          defined in the Terms of Use), and the visitors of Website (jointly and
          severally referred to as &ldquo;you&rdquo; or &ldquo;Users&rdquo; in
          this Privacy Policy). We created this Privacy Policy to demonstrate
          our commitment to the protection of your privacy and your personal
          information. Your use of and access to the Services is subject to this
          Privacy Policy and our Terms of Use. Any capitalized term used but not
          defined in this Privacy Policy shall have the meaning attributed to it
          in our Terms of Use.</span
        ></span
      ><span
        class="EOP SCXW213894907 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW213894907 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        class="EOP SCXW213894907 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW213894907 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW213894907 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >BY USING THE SERVICES OR BY OTHERWISE GIVING US YOUR INFORMATION, YOU
          WILL BE DEEMED TO HAVE READ, UNDERSTOOD AND AGREED TO THE PRACTICES
          AND POLICIES OUTLINED IN THIS PRIVACY POLICY AND AGREE TO BE BOUND BY
          THE PRIVACY POLICY. YOU HEREBY CONSENT TO OUR COLLECTION, USE AND
          SHARING, DISCLOSURE OF YOUR INFORMATION AS DESCRIBED IN THIS PRIVACY
          POLICY. WE RESERVE THE RIGHT TO CHANGE, MODIFY, ADD OR DELETE PORTIONS
          OF THE TERMS OF THIS PRIVACY POLICY, AT OUR SOLE DISCRETION, AT ANY
          TIME. IF YOU DO NOT AGREE WITH THIS PRIVACY POLICY AT ANY TIME, DO NOT
          USE ANY OF THE SERVICES OR GIVE US ANY OF YOUR INFORMATION. IF YOU USE
          THE SERVICES ON BEHALF OF SOMEONE ELSE (SUCH AS YOUR CHILD) OR AN
          ENTITY (SUCH AS YOUR EMPLOYER), YOU REPRESENT THAT YOU ARE AUTHORISED
          BY SUCH INDIVIDUAL OR ENTITY TO (I) ACCEPT THIS PRIVACY POLICY ON SUCH
          INDIVIDUAL&rsquo;S OR ENTITY&rsquo;S BEHALF, AND (II) CONSENT ON
          BEHALF OF SUCH INDIVIDUAL OR ENTITY TO OUR COLLECTION, USE AND
          DISCLOSURE OF SUCH INDIVIDUAL&rsquo;S OR ENTITY&rsquo;S INFORMATION AS
          DESCRIBED IN THIS PRIVACY POLICY.</span
        ></span
      ><span
        class="EOP SCXW213894907 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW213894907 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        class="EOP SCXW213894907 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW213894907 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        class="EOP SCXW213894907 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW213894907 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        class="EOP SCXW213894907 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW213894907 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW213894907 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >1.WHY THIS PRIVACY POLICY?</span
        ></span
      ><span
        class="EOP SCXW213894907 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW213894907 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        class="EOP SCXW213894907 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW213894907 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW213894907 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >This Privacy Policy is published in compliance with, inter
          alia:</span
        ></span
      ><span
        class="EOP SCXW213894907 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW213894907 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        class="EOP SCXW213894907 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW213894907 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW213894907 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Section 43A of the Information Technology Act, 2000;</span
        ></span
      ><span
        class="EOP SCXW213894907 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW213894907 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW213894907 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Regulation 4 of the Information Technology (Reasonable Security
          Practices and Procedures and Sensitive Personal Information) Rules,
          2011 (the &ldquo;SPI Rules&rdquo;);</span
        ></span
      ><span
        class="EOP SCXW213894907 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW213894907 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW213894907 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Regulation 3(1) of the Information Technology (Intermediaries
          Guidelines) Rules, 2011.</span
        ></span
      ><span
        class="EOP SCXW213894907 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW213894907 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW213894907 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >This Privacy Policy states the following:</span
        ></span
      ><span
        class="EOP SCXW213894907 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW213894907 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        class="EOP SCXW213894907 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW213894907 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW213894907 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >The type of information collected from the Users, including Personal
          Information (as defined in paragraph 2 below) and Sensitive Personal
          Data or Information (as defined in paragraph 2 below) relating to an
          individual;</span
        ></span
      ><span
        class="EOP SCXW213894907 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="SCXW213894907 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <div
      class="OutlineElement Ltr SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px;
        padding: 0px;
        user-select: text;
        clear: both;
        cursor: text;
        overflow: visible;
        position: relative;
        direction: ltr;
      "
    >
      <p
        class="Paragraph SCXW213894907 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px 0px 10.6667px;
          padding: 0px;
          user-select: text;
          overflow-wrap: break-word;
          white-space: pre-wrap;
          font-weight: normal;
          font-style: normal;
          vertical-align: baseline;
          font-kerning: none;
          background-color: transparent;
          color: windowtext;
          text-align: left;
          text-indent: 0px;
        "
      >
        <span
          data-contrast="auto"
          lang="EN-US"
          class="TextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            font-variant-ligatures: none !important;
            font-size: 12pt;
            line-height: 20.5042px;
            font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
              sans-serif;
          "
          ><span
            class="NormalTextRun SCXW213894907 BCX0"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
            "
            >The purpose, means and modes of collection, usage, processing,
            retention and destruction of such information; and</span
          ></span
        ><span
          class="EOP SCXW213894907 BCX0"
          data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            font-size: 12pt;
            line-height: 20.5042px;
            font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
              sans-serif;
          "
          >&nbsp;</span
        >
      </p>
    </div>
    <div
      class="OutlineElement Ltr SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px;
        padding: 0px;
        user-select: text;
        clear: both;
        cursor: text;
        overflow: visible;
        position: relative;
        direction: ltr;
      "
    >
      <p
        class="Paragraph SCXW213894907 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px 0px 10.6667px;
          padding: 0px;
          user-select: text;
          overflow-wrap: break-word;
          white-space: pre-wrap;
          font-weight: normal;
          font-style: normal;
          vertical-align: baseline;
          font-kerning: none;
          background-color: transparent;
          color: windowtext;
          text-align: left;
          text-indent: 0px;
        "
      >
        <span
          data-contrast="auto"
          lang="EN-US"
          class="TextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            font-variant-ligatures: none !important;
            font-size: 12pt;
            line-height: 20.5042px;
            font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
              sans-serif;
          "
          ><span
            class="NormalTextRun SCXW213894907 BCX0"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
            "
            >How and to whom&nbsp;</span
          ><span
            class="NormalTextRun SCXW213894907 BCX0"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
            "
            >Shikshatam</span
          ><span
            class="NormalTextRun SCXW213894907 BCX0"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
            "
            >&nbsp;will disclose such information.</span
          ></span
        ><span
          class="EOP SCXW213894907 BCX0"
          data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            font-size: 12pt;
            line-height: 20.5042px;
            font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
              sans-serif;
          "
          >&nbsp;</span
        >
      </p>
    </div>
    <div
      class="OutlineElement Ltr SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px;
        padding: 0px;
        user-select: text;
        clear: both;
        cursor: text;
        overflow: visible;
        position: relative;
        direction: ltr;
      "
    >
      <p
        class="Paragraph SCXW213894907 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px 0px 10.6667px;
          padding: 0px;
          user-select: text;
          overflow-wrap: break-word;
          white-space: pre-wrap;
          font-weight: normal;
          font-style: normal;
          vertical-align: baseline;
          font-kerning: none;
          background-color: transparent;
          color: windowtext;
          text-align: left;
          text-indent: 0px;
        "
      >
        <span
          data-contrast="auto"
          lang="EN-US"
          class="TextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            font-variant-ligatures: none !important;
            font-size: 12pt;
            line-height: 20.5042px;
            font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
              sans-serif;
          "
          ><span
            class="NormalTextRun SCXW213894907 BCX0"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
            "
            >2.COLLECTION OF PERSONAL INFORMATION</span
          ></span
        ><span
          class="EOP SCXW213894907 BCX0"
          data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            font-size: 12pt;
            line-height: 20.5042px;
            font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
              sans-serif;
          "
          >&nbsp;</span
        >
      </p>
    </div>
    <div
      class="OutlineElement Ltr SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px;
        padding: 0px;
        user-select: text;
        clear: both;
        cursor: text;
        overflow: visible;
        position: relative;
        direction: ltr;
      "
    >
      <p
        class="Paragraph SCXW213894907 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px 0px 10.6667px;
          padding: 0px;
          user-select: text;
          overflow-wrap: break-word;
          white-space: pre-wrap;
          font-weight: normal;
          font-style: normal;
          vertical-align: baseline;
          font-kerning: none;
          background-color: transparent;
          color: windowtext;
          text-align: left;
          text-indent: 0px;
        "
      >
        <span
          data-contrast="auto"
          lang="EN-US"
          class="TextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            font-variant-ligatures: none !important;
            font-size: 12pt;
            line-height: 20.5042px;
            font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
              sans-serif;
          "
          ><span
            class="NormalTextRun SCXW213894907 BCX0"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
            "
            >Generally some of the Services require us to know who you are so
            that we can best meet your needs. When you access the Services, or
            through any interaction with us via emails, telephone calls or other
            correspondence, we may ask you to voluntarily provide us with
            certain information that personally identifies you or could be used
            to personally identify you. You hereby consent to the collection of
            such information by&nbsp;</span
          ><span
            class="NormalTextRun SCXW213894907 BCX0"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
            "
            >Shikshatam</span
          ><span
            class="NormalTextRun SCXW213894907 BCX0"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
            "
            >. Without prejudice to the generality of the above, information
            collected by us from you may include (but is not limited to) the
            following:</span
          ></span
        ><span
          class="EOP SCXW213894907 BCX0"
          data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            font-size: 12pt;
            line-height: 20.5042px;
            font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
              sans-serif;
          "
          >&nbsp;</span
        >
      </p>
    </div>
    <div
      class="ListContainerWrapper SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px;
        padding: 0px;
        user-select: text;
        position: relative;
      "
    >
      <ul
        class="BulletListStyle1 SCXW213894907 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          list-style-type: disc;
          cursor: text;
          font-family: verdana;
          overflow: visible;
        "
      >
        <li
          data-leveltext=""
          data-font="Symbol"
          data-listid="1"
          data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"hybridMultilevel"}'
          aria-setsize="-1"
          data-aria-posinset="1"
          data-aria-level="1"
          class="OutlineElement Ltr SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px 0px 0px 24px;
            padding: 0px;
            user-select: text;
            clear: both;
            cursor: text;
            overflow: visible;
            position: relative;
            direction: ltr;
            display: block;
            font-size: 12pt;
            font-family: Arial, Arial_MSFontService, sans-serif;
            vertical-align: baseline;
          "
        >
          <p
            class="Paragraph SCXW213894907 BCX0"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              overflow-wrap: break-word;
              white-space: pre-wrap;
              font-weight: normal;
              font-style: normal;
              vertical-align: baseline;
              font-kerning: none;
              background-color: transparent;
              color: windowtext;
              text-align: left;
              text-indent: 0px;
            "
          >
            <span
              data-contrast="auto"
              lang="EN-US"
              class="TextRun SCXW213894907 BCX0"
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                font-variant-ligatures: none !important;
                font-size: 12pt;
                line-height: 20.5042px;
                font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
                  sans-serif;
              "
              ><span
                class="NormalTextRun SCXW213894907 BCX0"
                style="
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                "
                >contact data (such as your email address and phone
                number);</span
              ></span
            ><span
              class="EOP SCXW213894907 BCX0"
              data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                font-size: 12pt;
                line-height: 20.5042px;
                font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
                  sans-serif;
              "
              >&nbsp;</span
            >
          </p>
        </li>
      </ul>
    </div>
    <div
      class="ListContainerWrapper SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px;
        padding: 0px;
        user-select: text;
        position: relative;
      "
    >
      <ul
        class="BulletListStyle1 SCXW213894907 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          list-style-type: disc;
          cursor: text;
          font-family: verdana;
          overflow: visible;
        "
      >
        <li
          data-leveltext=""
          data-font="Symbol"
          data-listid="1"
          data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"hybridMultilevel"}'
          aria-setsize="-1"
          data-aria-posinset="2"
          data-aria-level="1"
          class="OutlineElement Ltr SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px 0px 0px 24px;
            padding: 0px;
            user-select: text;
            clear: both;
            cursor: text;
            overflow: visible;
            position: relative;
            direction: ltr;
            display: block;
            font-size: 12pt;
            font-family: Arial, Arial_MSFontService, sans-serif;
            vertical-align: baseline;
          "
        >
          <p
            class="Paragraph SCXW213894907 BCX0"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              overflow-wrap: break-word;
              white-space: pre-wrap;
              font-weight: normal;
              font-style: normal;
              vertical-align: baseline;
              font-kerning: none;
              background-color: transparent;
              color: windowtext;
              text-align: left;
              text-indent: 0px;
            "
          >
            <span
              data-contrast="auto"
              lang="EN-US"
              class="TextRun SCXW213894907 BCX0"
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                font-variant-ligatures: none !important;
                font-size: 12pt;
                line-height: 20.5042px;
                font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
                  sans-serif;
              "
              ><span
                class="NormalTextRun SCXW213894907 BCX0"
                style="
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                "
                >demographic data (such as your gender, your date of birth and
                your pin code);</span
              ></span
            ><span
              class="EOP SCXW213894907 BCX0"
              data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                font-size: 12pt;
                line-height: 20.5042px;
                font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
                  sans-serif;
              "
              >&nbsp;</span
            >
          </p>
        </li>
      </ul>
    </div>
    <div
      class="ListContainerWrapper SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px;
        padding: 0px;
        user-select: text;
        position: relative;
      "
    >
      <ul
        class="BulletListStyle1 SCXW213894907 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          list-style-type: disc;
          cursor: text;
          font-family: verdana;
          overflow: visible;
        "
      >
        <li
          data-leveltext=""
          data-font="Symbol"
          data-listid="1"
          data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"hybridMultilevel"}'
          aria-setsize="-1"
          data-aria-posinset="3"
          data-aria-level="1"
          class="OutlineElement Ltr SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px 0px 0px 24px;
            padding: 0px;
            user-select: text;
            clear: both;
            cursor: text;
            overflow: visible;
            position: relative;
            direction: ltr;
            display: block;
            font-size: 12pt;
            font-family: Arial, Arial_MSFontService, sans-serif;
            vertical-align: baseline;
          "
        >
          <p
            class="Paragraph SCXW213894907 BCX0"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              overflow-wrap: break-word;
              white-space: pre-wrap;
              font-weight: normal;
              font-style: normal;
              vertical-align: baseline;
              font-kerning: none;
              background-color: transparent;
              color: windowtext;
              text-align: left;
              text-indent: 0px;
            "
          >
            <span
              data-contrast="auto"
              lang="EN-US"
              class="TextRun SCXW213894907 BCX0"
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                font-variant-ligatures: none !important;
                font-size: 12pt;
                line-height: 20.5042px;
                font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
                  sans-serif;
              "
              ><span
                class="NormalTextRun SCXW213894907 BCX0"
                style="
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                "
                >data regarding your usage of the services and history of the
                appointments made by or with you through the use of
                Services;</span
              ></span
            ><span
              class="EOP SCXW213894907 BCX0"
              data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                font-size: 12pt;
                line-height: 20.5042px;
                font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
                  sans-serif;
              "
              >&nbsp;</span
            >
          </p>
        </li>
      </ul>
    </div>
    <div
      class="ListContainerWrapper SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px;
        padding: 0px;
        user-select: text;
        position: relative;
      "
    >
      <ul
        class="BulletListStyle1 SCXW213894907 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          list-style-type: disc;
          cursor: text;
          font-family: verdana;
          overflow: visible;
        "
      >
        <li
          data-leveltext=""
          data-font="Symbol"
          data-listid="1"
          data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Symbol","469769242":[8226],"469777803":"left","469777804":"","469777815":"hybridMultilevel"}'
          aria-setsize="-1"
          data-aria-posinset="4"
          data-aria-level="1"
          class="OutlineElement Ltr SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px 0px 0px 24px;
            padding: 0px;
            user-select: text;
            clear: both;
            cursor: text;
            overflow: visible;
            position: relative;
            direction: ltr;
            display: block;
            font-size: 12pt;
            font-family: Arial, Arial_MSFontService, sans-serif;
            vertical-align: baseline;
          "
        >
          <p
            class="Paragraph SCXW213894907 BCX0"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              overflow-wrap: break-word;
              white-space: pre-wrap;
              font-weight: normal;
              font-style: normal;
              vertical-align: baseline;
              font-kerning: none;
              background-color: transparent;
              color: windowtext;
              text-align: left;
              text-indent: 0px;
            "
          >
            <span
              data-contrast="auto"
              lang="EN-US"
              class="TextRun SCXW213894907 BCX0"
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                font-variant-ligatures: none !important;
                font-size: 12pt;
                line-height: 20.5042px;
                font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
                  sans-serif;
              "
              ><span
                class="NormalTextRun SCXW213894907 BCX0"
                style="
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                "
                >other information that you voluntarily choose to provide to us
                (such as information shared by you with us through emails or
                letters) including any images and other documents/files.</span
              ></span
            ><span
              class="EOP SCXW213894907 BCX0"
              data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                font-size: 12pt;
                line-height: 20.5042px;
                font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
                  sans-serif;
              "
              >&nbsp;</span
            >
          </p>
        </li>
      </ul>
    </div>
    <div
      class="OutlineElement Ltr SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px;
        padding: 0px;
        user-select: text;
        clear: both;
        cursor: text;
        overflow: visible;
        position: relative;
        direction: ltr;
      "
    >
      <p
        class="Paragraph SCXW213894907 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px 0px 10.6667px;
          padding: 0px;
          user-select: text;
          overflow-wrap: break-word;
          white-space: pre-wrap;
          font-weight: normal;
          font-style: normal;
          vertical-align: baseline;
          font-kerning: none;
          background-color: transparent;
          color: windowtext;
          text-align: left;
          text-indent: 0px;
        "
      >
        <span
          data-contrast="auto"
          lang="EN-US"
          class="TextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            font-variant-ligatures: none !important;
            font-size: 12pt;
            line-height: 20.5042px;
            font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
              sans-serif;
          "
          ><span
            class="NormalTextRun SCXW213894907 BCX0"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
            "
            >The information collected from you by&nbsp;</span
          ><span
            class="NormalTextRun SCXW213894907 BCX0"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
            "
            >Shikshatam</span
          ><span
            class="NormalTextRun SCXW213894907 BCX0"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
            "
            >&nbsp;may constitute &lsquo;personal information&rsquo; or
            &lsquo;sensitive personal data or information&rsquo; under the SPI
            Rules.</span
          ></span
        ><span
          class="EOP SCXW213894907 BCX0"
          data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            font-size: 12pt;
            line-height: 20.5042px;
            font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
              sans-serif;
          "
          >&nbsp;</span
        >
      </p>
    </div>
    <div
      class="OutlineElement Ltr SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px;
        padding: 0px;
        user-select: text;
        clear: both;
        cursor: text;
        overflow: visible;
        position: relative;
        direction: ltr;
      "
    >
      <p
        class="Paragraph SCXW213894907 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px 0px 10.6667px;
          padding: 0px;
          user-select: text;
          overflow-wrap: break-word;
          white-space: pre-wrap;
          font-weight: normal;
          font-style: normal;
          vertical-align: baseline;
          font-kerning: none;
          background-color: transparent;
          color: windowtext;
          text-align: left;
          text-indent: 0px;
        "
      >
        <span
          class="EOP SCXW213894907 BCX0"
          data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            font-size: 12pt;
            line-height: 20.5042px;
            font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
              sans-serif;
          "
          >&nbsp;</span
        >
      </p>
    </div>
    <div
      class="OutlineElement Ltr SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px;
        padding: 0px;
        user-select: text;
        clear: both;
        cursor: text;
        overflow: visible;
        position: relative;
        direction: ltr;
      "
    >
      <p
        class="Paragraph SCXW213894907 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px 0px 10.6667px;
          padding: 0px;
          user-select: text;
          overflow-wrap: break-word;
          white-space: pre-wrap;
          font-weight: normal;
          font-style: normal;
          vertical-align: baseline;
          font-kerning: none;
          background-color: transparent;
          color: windowtext;
          text-align: left;
          text-indent: 0px;
        "
      >
        <span
          data-contrast="auto"
          lang="EN-US"
          class="TextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            font-variant-ligatures: none !important;
            font-size: 12pt;
            line-height: 20.5042px;
            font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
              sans-serif;
          "
          ><span
            class="NormalTextRun SCXW213894907 BCX0"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
            "
            >&ldquo;Personal Information&rdquo; is defined under the SPI Rules
            to mean any information that relates to a natural person, which,
            either directly or indirectly, in combination with&nbsp;</span
          ><span
            class="NormalTextRun SCXW213894907 BCX0"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
            "
            >other information available or likely to be available to a body
            corporate, is capable of identifying such person.</span
          ></span
        ><span
          class="EOP SCXW213894907 BCX0"
          data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            font-size: 12pt;
            line-height: 20.5042px;
            font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
              sans-serif;
          "
          >&nbsp;</span
        >
      </p>
    </div>
    <div
      class="OutlineElement Ltr SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px;
        padding: 0px;
        user-select: text;
        clear: both;
        cursor: text;
        overflow: visible;
        position: relative;
        direction: ltr;
      "
    >
      <p
        class="Paragraph SCXW213894907 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px 0px 10.6667px;
          padding: 0px;
          user-select: text;
          overflow-wrap: break-word;
          white-space: pre-wrap;
          font-weight: normal;
          font-style: normal;
          vertical-align: baseline;
          font-kerning: none;
          background-color: transparent;
          color: windowtext;
          text-align: left;
          text-indent: 0px;
        "
      >
        <span
          class="EOP SCXW213894907 BCX0"
          data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            font-size: 12pt;
            line-height: 20.5042px;
            font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
              sans-serif;
          "
          >&nbsp;</span
        >
      </p>
    </div>
    <div
      class="OutlineElement Ltr SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px;
        padding: 0px;
        user-select: text;
        clear: both;
        cursor: text;
        overflow: visible;
        position: relative;
        direction: ltr;
      "
    >
      <p
        class="Paragraph SCXW213894907 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px 0px 10.6667px;
          padding: 0px;
          user-select: text;
          overflow-wrap: break-word;
          white-space: pre-wrap;
          font-weight: normal;
          font-style: normal;
          vertical-align: baseline;
          font-kerning: none;
          background-color: transparent;
          color: windowtext;
          text-align: left;
          text-indent: 0px;
        "
      >
        <span
          data-contrast="auto"
          lang="EN-US"
          class="TextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            font-variant-ligatures: none !important;
            font-size: 12pt;
            line-height: 20.5042px;
            font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
              sans-serif;
          "
          ><span
            class="NormalTextRun SCXW213894907 BCX0"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
            "
            >The SPI Rules further define &ldquo;Sensitive Personal Data or
            Information&rdquo; of a person to mean personal information about
            that person relating to:</span
          ></span
        ><span
          class="EOP SCXW213894907 BCX0"
          data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            font-size: 12pt;
            line-height: 20.5042px;
            font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
              sans-serif;
          "
          >&nbsp;</span
        >
      </p>
    </div>
    <div
      class="OutlineElement Ltr SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px;
        padding: 0px;
        user-select: text;
        clear: both;
        cursor: text;
        overflow: visible;
        position: relative;
        direction: ltr;
      "
    >
      <p
        class="Paragraph SCXW213894907 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px 0px 10.6667px;
          padding: 0px;
          user-select: text;
          overflow-wrap: break-word;
          white-space: pre-wrap;
          font-weight: normal;
          font-style: normal;
          vertical-align: baseline;
          font-kerning: none;
          background-color: transparent;
          color: windowtext;
          text-align: left;
          text-indent: 0px;
        "
      >
        <span
          class="EOP SCXW213894907 BCX0"
          data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            font-size: 12pt;
            line-height: 20.5042px;
            font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
              sans-serif;
          "
          >&nbsp;</span
        >
      </p>
    </div>
    <div
      class="OutlineElement Ltr SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px;
        padding: 0px;
        user-select: text;
        clear: both;
        cursor: text;
        overflow: visible;
        position: relative;
        direction: ltr;
      "
    >
      <p
        class="Paragraph SCXW213894907 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px 0px 10.6667px;
          padding: 0px;
          user-select: text;
          overflow-wrap: break-word;
          white-space: pre-wrap;
          font-weight: normal;
          font-style: normal;
          vertical-align: baseline;
          font-kerning: none;
          background-color: transparent;
          color: windowtext;
          text-align: left;
          text-indent: 0px;
        "
      >
        <span
          data-contrast="auto"
          lang="EN-US"
          class="TextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            font-variant-ligatures: none !important;
            font-size: 12pt;
            line-height: 20.5042px;
            font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
              sans-serif;
          "
          ><span
            class="NormalTextRun SCXW213894907 BCX0"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
            "
            >passwords;</span
          ></span
        ><span
          class="EOP SCXW213894907 BCX0"
          data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            font-size: 12pt;
            line-height: 20.5042px;
            font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
              sans-serif;
          "
          >&nbsp;</span
        >
      </p>
    </div>
    <div
      class="OutlineElement Ltr SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px;
        padding: 0px;
        user-select: text;
        clear: both;
        cursor: text;
        overflow: visible;
        position: relative;
        direction: ltr;
      "
    >
      <p
        class="Paragraph SCXW213894907 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px 0px 10.6667px;
          padding: 0px;
          user-select: text;
          overflow-wrap: break-word;
          white-space: pre-wrap;
          font-weight: normal;
          font-style: normal;
          vertical-align: baseline;
          font-kerning: none;
          background-color: transparent;
          color: windowtext;
          text-align: left;
          text-indent: 0px;
        "
      >
        <span
          data-contrast="auto"
          lang="EN-US"
          class="TextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            font-variant-ligatures: none !important;
            font-size: 12pt;
            line-height: 20.5042px;
            font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
              sans-serif;
          "
          ><span
            class="NormalTextRun SCXW213894907 BCX0"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
            "
            >financial information such as bank accounts, credit and debit card
            details or other payment instrument details;</span
          ></span
        ><span
          class="EOP SCXW213894907 BCX0"
          data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            font-size: 12pt;
            line-height: 20.5042px;
            font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
              sans-serif;
          "
          >&nbsp;</span
        >
      </p>
    </div>
    <div
      class="OutlineElement Ltr SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px;
        padding: 0px;
        user-select: text;
        clear: both;
        cursor: text;
        overflow: visible;
        position: relative;
        direction: ltr;
      "
    >
      <p
        class="Paragraph SCXW213894907 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px 0px 10.6667px;
          padding: 0px;
          user-select: text;
          overflow-wrap: break-word;
          white-space: pre-wrap;
          font-weight: normal;
          font-style: normal;
          vertical-align: baseline;
          font-kerning: none;
          background-color: transparent;
          color: windowtext;
          text-align: left;
          text-indent: 0px;
        "
      >
        <span
          data-contrast="auto"
          lang="EN-US"
          class="TextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            font-variant-ligatures: none !important;
            font-size: 12pt;
            line-height: 20.5042px;
            font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
              sans-serif;
          "
          ><span
            class="NormalTextRun SCXW213894907 BCX0"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
            "
            >physical, physiological and mental health condition;</span
          ></span
        ><span
          class="EOP SCXW213894907 BCX0"
          data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            font-size: 12pt;
            line-height: 20.5042px;
            font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
              sans-serif;
          "
          >&nbsp;</span
        >
      </p>
    </div>
    <div
      class="OutlineElement Ltr SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px;
        padding: 0px;
        user-select: text;
        clear: both;
        cursor: text;
        overflow: visible;
        position: relative;
        direction: ltr;
      "
    >
      <p
        class="Paragraph SCXW213894907 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px 0px 10.6667px;
          padding: 0px;
          user-select: text;
          overflow-wrap: break-word;
          white-space: pre-wrap;
          font-weight: normal;
          font-style: normal;
          vertical-align: baseline;
          font-kerning: none;
          background-color: transparent;
          color: windowtext;
          text-align: left;
          text-indent: 0px;
        "
      >
        <span
          data-contrast="auto"
          lang="EN-US"
          class="TextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            font-variant-ligatures: none !important;
            font-size: 12pt;
            line-height: 20.5042px;
            font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
              sans-serif;
          "
          ><span
            class="NormalTextRun SCXW213894907 BCX0"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
            "
            >sexual orientation;</span
          ></span
        ><span
          class="EOP SCXW213894907 BCX0"
          data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            font-size: 12pt;
            line-height: 20.5042px;
            font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
              sans-serif;
          "
          >&nbsp;</span
        >
      </p>
    </div>
    <div
      class="OutlineElement Ltr SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px;
        padding: 0px;
        user-select: text;
        clear: both;
        cursor: text;
        overflow: visible;
        position: relative;
        direction: ltr;
      "
    >
      <p
        class="Paragraph SCXW213894907 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px 0px 10.6667px;
          padding: 0px;
          user-select: text;
          overflow-wrap: break-word;
          white-space: pre-wrap;
          font-weight: normal;
          font-style: normal;
          vertical-align: baseline;
          font-kerning: none;
          background-color: transparent;
          color: windowtext;
          text-align: left;
          text-indent: 0px;
        "
      >
        <span
          data-contrast="auto"
          lang="EN-US"
          class="TextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            font-variant-ligatures: none !important;
            font-size: 12pt;
            line-height: 20.5042px;
            font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
              sans-serif;
          "
          ><span
            class="NormalTextRun SCXW213894907 BCX0"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
            "
            >medical records and history;</span
          ></span
        ><span
          class="EOP SCXW213894907 BCX0"
          data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            font-size: 12pt;
            line-height: 20.5042px;
            font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
              sans-serif;
          "
          >&nbsp;</span
        >
      </p>
    </div>
    <div
      class="OutlineElement Ltr SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px;
        padding: 0px;
        user-select: text;
        clear: both;
        cursor: text;
        overflow: visible;
        position: relative;
        direction: ltr;
      "
    >
      <p
        class="Paragraph SCXW213894907 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px 0px 10.6667px;
          padding: 0px;
          user-select: text;
          overflow-wrap: break-word;
          white-space: pre-wrap;
          font-weight: normal;
          font-style: normal;
          vertical-align: baseline;
          font-kerning: none;
          background-color: transparent;
          color: windowtext;
          text-align: left;
          text-indent: 0px;
        "
      >
        <span
          data-contrast="auto"
          lang="EN-US"
          class="TextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            font-variant-ligatures: none !important;
            font-size: 12pt;
            line-height: 20.5042px;
            font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
              sans-serif;
          "
          ><span
            class="NormalTextRun SCXW213894907 BCX0"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
            "
            >biometric information;</span
          ></span
        ><span
          class="EOP SCXW213894907 BCX0"
          data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            font-size: 12pt;
            line-height: 20.5042px;
            font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
              sans-serif;
          "
          >&nbsp;</span
        >
      </p>
    </div>
  </div>
  <div
    class="OutlineElement Ltr SCXW213894907 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW213894907 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >information received by body corporate under lawful contract or
          otherwise;</span
        ></span
      ><span
        class="EOP SCXW213894907 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW213894907 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW213894907 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >visitor details as provided at the time of registration or
          thereafter; and</span
        ></span
      ><span
        class="EOP SCXW213894907 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW213894907 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW213894907 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >call data records.</span
        ></span
      ><span
        class="EOP SCXW213894907 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW213894907 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW213894907 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;will be free to use, collect and disclose information that is
          freely available in the public domain without your consent.</span
        ></span
      ><span
        class="EOP SCXW213894907 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW213894907 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        class="EOP SCXW213894907 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW213894907 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW213894907 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >3.PRIVACY STATEMENTS</span
        ></span
      ><span
        class="EOP SCXW213894907 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW213894907 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW213894907 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >3.1ALL USERS NOTE:</span
        ></span
      ><span
        class="EOP SCXW213894907 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW213894907 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW213894907 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >This section applies to all users.</span
        ></span
      ><span
        class="EOP SCXW213894907 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW213894907 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        class="EOP SCXW213894907 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW213894907 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW213894907 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >3.1.1Accordingly, a condition of each User&rsquo;s use of and access
          to the Services is their acceptance of the Terms of Use, which also
          involves acceptance of the terms of this Privacy Policy. Any User that
          does not agree with any provisions of the same has the option to
          discontinue the Services provided by&nbsp;</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;immediately.</span
        ></span
      ><span
        class="EOP SCXW213894907 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW213894907 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW213894907 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >3.1.2An indicative list of information that&nbsp;</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;may require you to provide to enable your use of the Services
          is provided in the Schedule annexed to this Privacy Policy.</span
        ></span
      ><span
        class="EOP SCXW213894907 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW213894907 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW213894907 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >3.1.3All the information provided to&nbsp;</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;by a User, including Personal Information or any Sensitive
          Personal Data or Information, is voluntary. You understand
          that&nbsp;</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;may use certain information of yours, which has been designated
          as Personal Information or &lsquo;Sensitive Personal Data or
          Information&rsquo; under the SPI Rules, (a) for the purpose of
          providing you the Services, (b) for commercial&nbsp;</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >purposes and in an aggregated or non-personally identifiable form for
          research, statistical analysis and business intelligence purposes, (c)
          for sale or transfer of such research, statistical or intelligence
          data in an aggregated or non-personally identifiable form to third
          parties and affiliates (d) for communication purpose so as to provide
          You a better way of booking appointments and for obtaining feedback in
          relation to the&nbsp;</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Teacher&rsquo;</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >s and their practice, (e) debugging customer support related
          issues,.. (f) for the purpose of contacting you to complete any
          transaction if you do not complete a transaction after having provided
          us with your contact information in the course of completing such
          steps that are designed for completion of the transaction.&nbsp;</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;also reserves the right to use information provided by or about
          the End-User for the following purposes:</span
        ></span
      ><span
        class="EOP SCXW213894907 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW213894907 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW213894907 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Identifying You.</span
        ></span
      ><span
        class="EOP SCXW213894907 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW213894907 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW213894907 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Publishing such information on the Website.</span
        ></span
      ><span
        class="EOP SCXW213894907 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW213894907 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW213894907 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Contacting End-Users for offering new products or services.</span
        ></span
      ><span
        class="EOP SCXW213894907 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW213894907 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW213894907 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Contacting End-Users for taking product and Service feedback.</span
        ></span
      ><span
        class="EOP SCXW213894907 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW213894907 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW213894907 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Analyzing software usage patterns for improving product design and
          utility.</span
        ></span
      ><span
        class="EOP SCXW213894907 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW213894907 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW213894907 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Analyzing anonymized practice information for commercial use.</span
        ></span
      ><span
        class="EOP SCXW213894907 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW213894907 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW213894907 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Processing payment instructions including those through independent
          third party service providers such as payment gateways, banking and
          financial institutions, pre-paid instrument and wallet providers for
          processing of payment transaction or deferral of payment
          facilities.</span
        ></span
      ><span
        class="EOP SCXW213894907 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW213894907 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW213894907 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >If you have voluntarily provided your Personal Information
          to&nbsp;</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;for any of the purposes stated above, you hereby consent to
          such collection and use of such information by&nbsp;</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >. However,&nbsp;</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;shall not contact You on Your telephone number(s) for any
          purpose including those mentioned in this sub-section 4.1(iii), if
          such telephone number is registered with the Do Not Call registry
          (&ldquo;DNC Registry&rdquo;) under the PDPA without your express,
          clear and un-ambiguous written consent.</span
        ></span
      ><span
        class="EOP SCXW213894907 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW213894907 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        class="EOP SCXW213894907 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW213894907 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW213894907 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >3.1.4</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Collection, use and disclosure of information which has been
          designated as Personal Information or Sensitive Personal Data or
          Information&rsquo; under the SPI Rules requires your express consent.
          By affirming your assent to this Privacy Policy, you provide your
          consent to such use, collection and disclosure as required under
          applicable law.</span
        ></span
      ><span
        class="EOP SCXW213894907 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW213894907 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW213894907 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >3.1.5</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;Shikshatam</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;does not control or endorse the content, messages or
          information found in any Services and, therefore,&nbsp;</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;specifically disclaims any liability with regard to the
          Services and any actions resulting from your participation in any
          Services, and you agree that you waive any claims against&nbsp;</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;relating to same, and to the extent such waiver may be
          ineffective, you agree to release any claims against&nbsp;</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;relating to the same.</span
        ></span
      ><span
        class="EOP SCXW213894907 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW213894907 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW213894907 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >3.1.6</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >You are responsible for maintaining the accuracy of the information
          you submit to us, such as your contact information provided as part of
          account registration. If your personal information changes, you may
          correct, delete inaccuracies, or amend information by making the
          change on our member information page or by contacting us
          through&nbsp;</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >support</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >"&#64;"shikshatam.com</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >.com. We will make good faith efforts to make requested changes in
          our then active databases as soon as reasonably practicable. If you
          provide any information that is untrue, inaccurate, out of date or
          incomplete (or becomes untrue, inaccurate, out of date or incomplete),
          or&nbsp;</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;has reasonable grounds to suspect that the information provided
          by you is untrue, inaccurate, out of date or incomplete,&nbsp;</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;may, at its sole discretion, discontinue the provision of the
          Services to you. There may be circumstances where&nbsp;</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;will not correct, delete or update your Personal Data,
          including (a) where the Personal Data is opinion data that is kept
          solely for evaluative purpose; and (b) the Personal Data is in
          documents related to a prosecution if all proceedings relating to the
          prosecution have not been completed.</span
        ></span
      ><span
        class="EOP SCXW213894907 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW213894907 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW213894907 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >3.1.7</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >If you wish to cancel your account or request that we no longer use
          your information to provide you Services, contact us through
          support"&#64;"shikshatam.com</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >.com . We will retain your information for as long as your account
          with the Services is active and as needed to provide you the Services.
          We shall not retain such information for longer than is required for
          the purposes for which the information may lawfully be used or is
          otherwise required under any other law for the time being in force.
          After a period of time, your data may be anonymized and aggregated,
          and then may be held by us as long as necessary for us to provide our
          Services effectively, but our use of the anonymized data will be
          solely for analytic purposes. Please note that your withdrawal of
          consent, or cancellation of account may result in&nbsp;</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;being unable to provide you with its Services or to terminate
          any existing relationship&nbsp;</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;may have with you.</span
        ></span
      ><span
        class="EOP SCXW213894907 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW213894907 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW213894907 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >3.1.8</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >If you wish to opt-out of receiving non-essential communications such
          as promotional and marketing-related information regarding the
          Services, please send us an email at
          support"&#64;"shikshatam.com</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >.com.</span
        ></span
      ><span
        class="EOP SCXW213894907 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW213894907 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW213894907 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >3.1.9</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;Shikshatam</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;may require the User to pay with a credit card, wire transfer,
          debit card or cheque for Services for which subscription amount(s)
          is/are payable.&nbsp;</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp; will collect such User&rsquo;s credit card number and/or other
          financial institution information such as bank account numbers and
          will use that information for the billing and payment processes,
          including but not limited to the use and disclosure of such credit
          card number and information to third parties as necessary to complete
          such billing operation. Verification of credit information, however,
          is accomplished solely by the User through the authentication process.
          User&rsquo;s credit-card/debit card details are transacted upon secure
          sites of approved payment gateways which are digitally under
          encryption, thereby providing the highest possible degree of care as
          per current technology. However,&nbsp;</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;provides you an option not to save your payment details. User
          is advised, however, that internet technology is not full proof safe
          and User should exercise discretion on using the same.</span
        ></span
      ><span
        class="EOP SCXW213894907 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW213894907 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW213894907 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >3.1.10</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Due to the communications standards on the Internet, when a User or
          the End-User or anyone who visits the Website,&nbsp;</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;automatically receives the URL of the site from which anyone
          visits.&nbsp;</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;also receives the Internet Protocol (IP) address of each
          User&rsquo;s computer (or the proxy server a User used to access the
          World Wide Web), User&rsquo;s computer operating system and type of
          web browser the User is using, email patterns, as well as the name of
          User&rsquo;s ISP. This information is used to analyze overall trends
          to help&nbsp;</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;improve its Service. The linkage between User&rsquo;s IP
          address and User&rsquo;s personally identifiable information is not
          shared with or disclosed to third parties. Notwithstanding the
          above,&nbsp;</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;may share and/or disclose some of the aggregate findings (not
          the specific data) in anonymized form (i.e., non-personally
          identifiable) with advertisers, sponsors, investors, strategic
          partners, and others in order to help grow its business.</span
        ></span
      ><span
        class="EOP SCXW213894907 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW213894907 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW213894907 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >3.1.11</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >The Website uses temporary cookies to store certain (that is not
          sensitive personal data or information) that is used by&nbsp;</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;and its service providers for the technical administration of
          the Website, research and development, and for User administration. In
          the course of serving advertisements or optimizing services to its
          Users,&nbsp;</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;may allow authorized third parties to place or recognize a
          unique cookie on the User&rsquo;s browser. The cookies however, do not
          store any Personal Information of the User. You may adjust your
          internet browser to disable cookies. If cookies are disabled you may
          still use the Website, but the Website may be limited in the use of
          some of the features.</span
        ></span
      ><span
        class="EOP SCXW213894907 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW213894907 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW213894907 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >3.1.12</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >A User may have limited access to the Website without creating an
          account on the Website. Unregistered Users can make appointments with
          the&nbsp;</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >teacher</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >s by providing their name and phone number. In order to have access
          to all the features and benefits on our Website, a User must first
          create an account on our Website. To create an account, a User is
          required to provide the following information, which such User
          recognizes and expressly acknowledges is Personal Information allowing
          others, including&nbsp;</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >, to identify the User: name, User ID, email address, country,
          ZIP/postal code, age, phone number, password chosen by the User and
          valid financial account information. Other information requested on
          the registration page, including the ability to receive promotional
          offers from&nbsp;</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >, is optional.&nbsp;</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;may, in future, include other optional requests for information
          from the User to help&nbsp;</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;to customize the Website to deliver personalized information to
          the User.</span
        ></span
      ><span
        class="EOP SCXW213894907 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW213894907 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW213894907 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >3.1.13</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;Shikshatam</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;does not exercise control over the sites displayed as search
          results or links from within its Services. These other sites may place
          their own cookies or other files on the Users&rsquo; computer, collect
          data or solicit personal information from the Users, for
          which&nbsp;</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;is not responsible or liable. Accordingly,&nbsp;</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;does not make any representations concerning the privacy
          practices or policies of such third parties or terms of use of such
          websites, nor does&nbsp;</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;guarantee the accuracy, integrity, or quality of the
          information, data, text, software, sound, photographs, graphics,
          videos, messages or other materials available on such websites. The
          inclusion or exclusion does not imply any endorsement by&nbsp;</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;of the website, the website&apos;s provider, or the information
          on the website. If you decide to visit a third party website linked to
          the Website, you&nbsp;</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >do this entirely at your own risk.&nbsp;</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;encourages the User to read the privacy policies of that
          website.</span
        ></span
      ><span
        class="EOP SCXW213894907 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW213894907 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW213894907 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >3.1.14The Website may enable User to communicate with other Users or
          to post information to be accessed by others, whereupon other Users
          may collect such data. Such Users, including any moderators or
          administrators, are not authorized&nbsp;</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;representatives or agents, and their opinions or statements do
          not necessarily reflect those of&nbsp;</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >, and they are not authorized to bind&nbsp;</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;to any contract.&nbsp;</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;hereby expressly disclaims any liability for any reliance or
          misuse of such information that is made available by Users or visitors
          in such a manner.</span
        ></span
      ><span
        class="EOP SCXW213894907 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW213894907 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW213894907 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >3.1.15</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;Shikshatam</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;does not collect information about the visitors of the Website
          from other sources, such as public records or bodies, or private
          organisations, save and except for the purposes of registration of the
          Users (the collection, use, storage and disclosure of which each End
          User must agree to under the Terms of Use in order for&nbsp;</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;to effectively render the Services).</span
        ></span
      ><span
        class="EOP SCXW213894907 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW213894907 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW213894907 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >3.1.16</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;Shikshatam</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;maintains a strict &quot;No-Spam&quot; policy, which means
          that&nbsp;</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;does not intend to sell, rent or otherwise give your e-mail
          address to a third party without your consent.</span
        ></span
      ><span
        class="EOP SCXW213894907 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW213894907 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW213894907 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >3.1.17</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;Shikshatam</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;has implemented best international market practices and
          security policies, rules and technical measures to protect the
          personal data that it has under its control from unauthorised access,
          improper use or disclosure, unauthorised modification and unlawful
          destruction or accidental loss. However, for any data loss or theft
          due to unauthorized access to the User&rsquo;s electronic devices
          through which the User avails the Services,&nbsp;</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;shall not be held liable for any loss whatsoever incurred by
          the User.</span
        ></span
      ><span
        class="EOP SCXW213894907 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW213894907 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW213894907 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >3.1.18</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;Shikshatam</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;implements reasonable security practices and procedures and has
          a comprehensive documented information security programme and
          information security policies that contain managerial, technical,
          operational and physical security control measures that are
          commensurate with respect to the information being collected and the
          nature of&nbsp;</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&rsquo;s business.</span
        ></span
      ><span
        class="EOP SCXW213894907 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW213894907 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        data-contrast="auto"
        lang="EN-US"
        class="TextRun SCXW213894907 BCX0"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-variant-ligatures: none !important;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >3.1.19</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;Shikshatam</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >&nbsp;takes your right to privacy very seriously and other than as
          specifically stated in this Privacy Policy, will only disclose your
          Personal Information in the event it is required to do so by law,
          rule, regulation, law enforcement agency, governmental official, legal
          authority or similar requirements or when&nbsp;</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >Shikshatam</span
        ><span
          class="NormalTextRun SCXW213894907 BCX0"
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
          "
          >, in its sole discretion, deems it necessary in order to protect its
          rights or the rights of others, to prevent harm to persons or
          property, to fight fraud and credit risk, or to enforce or apply the
          Terms of Use.</span
        ></span
      ><span
        class="EOP SCXW213894907 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 12pt;
          line-height: 20.5042px;
          font-family: Arial, Arial_EmbeddedFont, Arial_MSFontService,
            sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
  <div
    class="OutlineElement Ltr SCXW213894907 BCX0"
    style="
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: transparent;
      margin: 0px;
      padding: 0px;
      user-select: text;
      clear: both;
      cursor: text;
      overflow: visible;
      position: relative;
      direction: ltr;
      color: rgb(0, 0, 0);
      font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      font-weight: 400;
      letter-spacing: normal;
      orphans: 2;
      text-align: start;
      text-indent: 0px;
      text-transform: none;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      white-space: normal;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      class="Paragraph SCXW213894907 BCX0"
      style="
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0px 0px 10.6667px;
        padding: 0px;
        user-select: text;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-weight: normal;
        font-style: normal;
        vertical-align: baseline;
        font-kerning: none;
        background-color: transparent;
        color: windowtext;
        text-align: left;
        text-indent: 0px;
      "
    >
      <span
        class="EOP SCXW213894907 BCX0"
        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          font-size: 11pt;
          line-height: 18.3458px;
          font-family: Aptos, Aptos_EmbeddedFont, Aptos_MSFontService,
            sans-serif;
        "
        >&nbsp;</span
      >
    </p>
  </div>
</main>
