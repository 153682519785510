@if(items$ | async; as items){
  <div class="trending">
    <div class="trending__title">{{sectionTitle}}</div>
    <div class="trending__grp--list">
      @for(item of [].constructor(6);track $index){
        <div class="trending__grp">
          @for( item of items.slice($index * 5, ($index + 1) * 5);track item){
            <ng-container>
              <a class="trending__grp--item" [routerLink]="item.route" [queryParams]="item.queryParams">{{ item[labelText] }}</a>
            </ng-container>
          }
        </div>
      }
    </div>
  </div>
}
