import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import{aggregateInstitutes, getDetailInstituteByUrlCode, getPopularAndNewRisingInstitutes} from '@/app/utils/api.constant'
import { lastValueFrom, map, Observable, shareReplay } from 'rxjs';
import Batch from '../interface/batch';
import Teacher from '../interface/teacher';
import Institute from '../interface/institute';

@Injectable({ providedIn: 'root' })
export class InstituteService {
  popularInstitutes$:Observable<any[]>
  risingInstitutes$: Observable<any[]>
   
  constructor(
    private http:HttpClient
  ) {
    this.getPopularAndRising()
  }

  getPopularAndRising() {
    const response$ = this.http.get<any>(getPopularAndNewRisingInstitutes).pipe(shareReplay(1))

    this.popularInstitutes$ = response$.pipe(map(response => response.popularInstitutes))
    this.risingInstitutes$ = response$.pipe(map(response => response.newRisingInstitutes))
  }

  aggregateInstitutes(params:any) {
    return this.http.get<any>(aggregateInstitutes,{params})
  }

  async getInstituteDetail(urlCode: string){
    let httpParams = new HttpParams({fromObject:{urlCode}});

    const responseObservable =  this.http
    .get<InstituteByUrlCodeResponse>(getDetailInstituteByUrlCode, {
      params: httpParams,
    })

    const response = await lastValueFrom(responseObservable)

    return response.data
  }

  
getDistanceText(distance: number){
  let returnValue = '';
  if (distance === -1) {
    returnValue = 'Distance not available';
  } else if (distance > 1) {
    returnValue = 'Approx ' + distance.toFixed(2) + ' kms away';
  } else if (distance === 1) {
    returnValue = 'Approx 1 km away';
  } else {
    returnValue = 'Less than 1 km away'; 
  }
  return returnValue;
}


  getTeacherAndExperienceText(teachersCount=0, minExperience = 0, maxExperience=0){
    let returnValue = ''
    if(teachersCount){
      returnValue = returnValue + `${teachersCount} Teachers`
    }

    if(minExperience && maxExperience){
      if(minExperience === maxExperience){
        returnValue = returnValue + ` | ${minExperience} Yrs Experience`
      } else if(minExperience < maxExperience) {
        returnValue = returnValue + ` | ${minExperience} - ${maxExperience} Yrs Experience`
      } else {
        // If someone mistakenly sends min value as max, reverse the order.
        returnValue = returnValue + ` | ${maxExperience} - ${minExperience} Yrs Experience`

      }
    }

    return returnValue
  }


}

export interface InstituteByUrlCodeResponse {
  success: boolean;
  message: string;
  data: InstituteByUrlCode;
}

export interface InstituteByUrlCode {
  institute: Institute;
  teachersData: Teacher[];
  experienceMin: number;
  batches: Batch[];
  experienceMax: number;
  experienceText?: string;
}


