<footer>
  <div class="footer">
    <div class="footer__left">
      <div class="footer__branding">
        <div class="footer__branding--logo">Guru Near Me</div>
        <h2 class="footer__branding--info">
          Near By Coaching Centre, Best Coaching Centre Near Me and Excellent Tutorials.
        </h2>
      </div>
      <div class="footer__contact">
        <div class="footer__contact--title">Contact Us</div>
        <div class="footer__contact--info">
          <div class="footer__contact--info">
            Shikshatam IT Private Limited, 649, 650, Main Rd, above Lenskart Showroom, Sector A, Mahalaxmi Nagar, Indore, Madhya Pradesh 452010
          </div>
          <a href="tel:+919179328236">(+91) 91793-28236</a> | <a href="mailto:support@shikshatam.com">support&#64;shikshatam.com</a>
        </div>
      </div>
    </div>
    <div class="footer__right">
      <div class="footer__social">
        <div class="footer__social--title">Follow Us On Social Media</div>
        <div class="footer__social--list">
          <a class="footer__social--item" href="https://www.facebook.com/profile.php?id=61561980560946"  target="_blank">
            <img src="assets/icons/facebook.png" alt="Facebook Icon" />
          </a>
          <a class="footer__social--item" href="https://www.linkedin.com/company/shikshatam-it-private-limited"  target="_blank">
            <img src="assets/icons/linkedin.png" alt="Linkedin Icon" />
          </a>
          <a class="footer__social--item" href="https://www.instagram.com/shikshatam/?igsh=MXBvenNhN2tpOHNlYg%3D%3D"  target="_blank">
            <img src="assets/icons/instagram.png" alt="Instagram Icon" />
          </a>
          <a class="footer__social--item" href="https://twitter.com/Shikshatam"  target="_blank">
            <img src="assets/icons/twitter.png" alt="Twitter Icon" />
          </a>
          <a class="footer__social--item" href="https://www.youtube.com/channel/UCYUQ0oigexB51K6ZiY4GhWw"  target="_blank">
            <img src="assets/icons/youtube.png" alt="Youtube Icon" />
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="footer__copyright">
    <div class="footer__copyright--info">
      Copyright © {{currentYear}} Shikshatam IT Private Limited | All rights reserved.
    </div>
    <div class="footer__copyright--links">
      <a class="footer__copyright--links-item" routerLink="/privacy">Privacy</a>
      <a class="footer__copyright--links-item" routerLink="/terms">Terms</a>
    </div>
  </div>
  <div class="fab" (click)="reachOutViaWhatsApp()">
    <i class="fa-brands fa-whatsapp fa-2xl"></i>
  </div>
</footer>
