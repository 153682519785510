import { CommonModule } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component } from '@angular/core';
import {FormsModule,ReactiveFormsModule} from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { ToastService } from '@/app/services/toast.service';
import { AppFooterComponent } from "../shared/app-footer/app-footer.component";
import { AppHeaderComponent } from "../shared/app-header/app-header.component";
import { MetaService } from '../services/meta.service';

@Component({
  selector: 'app-enquiry',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, AppFooterComponent, AppHeaderComponent],
  templateUrl: './enquiry.component.html',
  styleUrl: './enquiry.component.scss'
})
export class EnquiryComponent {
  contactForm !: FormGroup ;

  constructor(private fb: FormBuilder,
    private http: HttpClient,
    private toastService: ToastService,
    private metaService:MetaService
  ) {}

  ngOnInit() {
    this.contactForm = this.fb.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      mobile: ['', [Validators.required, Validators.pattern('^[0-9]{10}$')]],
      email: ['', [Validators.email]],
      street: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      '00NHs00000mxShw': ['', Validators.required],
      '00NHs00000mxSi9' : ['', Validators.required],
       oid: ['00DHs000003XHzH'],
      
    });
    this.setMetaInfo()
  }
 
 

  onSubmit() {
    console.log('Submit button clicked');
    if (this.contactForm.valid) {
    //   const payload =new HttpParams({ fromObject: this.contactForm.value }).toString();
    //   this.createInstitute(payload).subscribe({
    //     next:(response) => {
    //       this.toastService.showError('Submitted')
    //     },
    //     error: (error) => {
    //       this.toastService.showError('Failed:', error.message)
    //       console.log(error.message,'error message')
    //     },
    // })
      console.log('Form Submitted!', this.contactForm.value);
    } else {
      this.contactForm.markAllAsTouched();
    }
  }
  
  setMetaInfo() {
    const title =
      'Join GuruNearMe Coaching | Get Customer Support and Educational Help';
    const description =
      'Contact GuruNearMe for Coaching and Tutoring services. Reach out for personalized educational help and expert guidance in choosing the best coaching options for your needs.';
    this.metaService.setMeta(title, description);
  }

}
