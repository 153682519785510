import { MetaService } from '@/app/services/meta.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy',
  standalone: true,
  imports: [],
  templateUrl: './privacy.component.html',
  styleUrl: './privacy.component.scss',
})
export class PrivacyComponent implements OnInit {
  constructor(private metaService: MetaService){}

  ngOnInit(): void {
    this.setMetaInfo()
    window.scrollTo(0, 0);
  }

  setMetaInfo(){
    this.metaService.setMeta('GuruNearMe - Privacy', 'Privacy page for gurunearme.com')
  }
}
