import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { getCityByInstitute, locality } from '../utils/api.constant';
import City from '../interface/city';
import { map, Observable, of, shareReplay } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CityService {
  _http = inject(HttpClient);
  allCities$: Observable<City[]> = of([]);
  allLocality$: Observable<any[]> = of([]);

  constructor() {
    this.fetchAllCities()
  }

  fetchAllCities() {
    this.allCities$ = this._http.get<any>(getCityByInstitute)
      .pipe(shareReplay(1))
      .pipe(
        map(response => response.data),
        map(cityList => {
          return [
            {
              name: 'Near Me', id: 123,
              _id: 'near-me',
              state_id: 0,
              state_code: '',
              state_name: '',
              country_id: 0,
              country_code: '',
              country_name: '',
              latitude: 0,
              longitude: 0,
              wikiDataId: ''
            },
            ...cityList
          ]
        })
      )
  }

  fetchlocality(params: any = {}){
  return this._http.get<any>(locality,{params})
  }
}