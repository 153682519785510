<div class="search">
    <div class="search__text">
        <span><img src="assets/icons/search.png" alt="blog-search-icon" /></span>
        <input 
            type="search" 
            placeholder="Search" 
            [(ngModel)]="searchTerm"
            (keydown.enter)="onSearchClick()"
        />
    </div>
    <div class="search__action">
        <button class="btn btn-primary" (click)="onSearchClick()">    
            @if(loading){
                <span class="spinner-border" style="width: 1.2rem; height: 1.2rem; border-width: 0.2em;"></span>
            }
            @else{
                <span>Search</span>
            }
        </button>
    </div>
</div>