<app-header></app-header>
<div class="hero">
  <div class="hero__header">
    <h1 class="hero__header--title">{{h1Tag}}</h1>
    @for(h2 of h2Tag; track h2){
      <h2 class="hero__header--subtitle">
        {{h2}}
      </h2>
      <!-- <h2 class="hero__header--subtitle">
        🌟 Choose from 2000+ teachers and institutes near you!
      </h2>
      <h2 class="hero__header--subtitle">
        ✅ Experienced and well versed teachers and institutes around you!
      </h2>
      <h2 class="hero__header--subtitle">
        🎖️ Top teachers and institutes in Indore
      </h2> -->
    }
    <app-search 
      [longitude]="longitude"
      [latitude]="latitude"
      class="d-flex" 
      [loading]="loading"
      [alreadySelectedCategoryId]="typeId"
      [alreadySelectedCityId]="cityRouteParam === 'near-me' ? 'near-me' : cityId"
    ></app-search>
    <div class="additional">
      @if(cityRouteParam !='near-me' ){
      <span class="additional--label">
        <i class="fa-solid fa-filter"></i>
        Filter By :</span
      > 
      }
      
      <div class="filter">
        @if(localities.length>0){
        <app-filter
        [items]="localities"
        [totalItems]="totalLocalities"
        [value]="localityId"
        label="Locality"
        [allowSearch]="true"
        (filterChange)="handleSelectedLocalityId($event)"
        (itemSearch)="handleLocalitySearch($event)"
        (loadMoreItems)="handleLocalityLoadMore()"
        ></app-filter>
        }
        @if(classes.length > 0){
          <app-filter
          [items]="classes"
          label="Classes"
          (filterChange)="handleSelectedClassId($event)"
          ></app-filter>
        }
        @if(subTypes.length > 0){
          <app-filter
          [items]="subTypes"
          label="SubTypes"
          (filterChange)="handleSelectedSubTypeId($event)"
          ></app-filter>
        }
      </div>
    </div>
  </div>
  <div class="character__right">
    <img src="assets/images/listing-character.png" alt="Listing Character" />
  </div>
</div>

<div class="page">
  <main>
    <div [classList]="allInstitutes.length === 0 ? 'justify-content-center institute__list': 'institute__list'">
      @if(!loading && allInstitutes.length === 0){
        <app-no-data-found
          [cityName]="cityName"
          [categoryName]="categoryName"
        ></app-no-data-found>
      }
      @else{
        @for(item of allInstitutes;track item._id){
          <div class="institute__card">
            <!-- <div class="institute__card--type">
              <span>IIT-JEE</span>
              <span>NEET</span>
              <span>+2 more</span>
            </div> -->
            <a class="institute__card--wrapper" [routerLink]="'/institute/'+item.urlCode">
              <div class="institute__card--media">
                <img [src]="item.instituteLogo.length > 0 ? SHIKSHATAMBUCKET_URL + item.instituteLogo : 'assets/images/Placeholder.png'" alt={{item.instituteName}} />
                @if(item.currentStage==='VERIFIED'){
                  <div class="institute__card--verified">
                    <img src="/assets/icons/verified-icon.svg" alt="verified Icon" />
                    Verified
                  </div>
                }
              </div>
              <div class="institute__card--info">
                <div class="institute__card--info-name">
                  {{item.instituteName}}
                </div>
                <div class="institute__card--info-loc">
                  <span class="mt-1">
                    <img src="/assets/icons/location-icon.png" alt="Location Icon" />
                  </span>
                {{item.instituteAddress}}
                </div>
                @if(getTeacherAndExperienceText(item)){
                  <div class="institute__card--info-teacher">
                    <span class="mt-1" >
                      <img src="/assets/icons/teacher-icon.png" alt="Teacher Icon" />
                    </span>
                    {{getTeacherAndExperienceText(item)}}
                  </div>
                }
                <div class="institute__card--action desktop-only">
                  <button class="btn btn-primary" [routerLink]="'/institute/'+item.urlCode" fragment="contactForm">Contact</button>
                  <button class="btn btn-outline-primary" (click)="onDirectionsClick(item.coordinates)">
                    {{getDistances(item.distance)}}
                    <span>
                      <i class="fa-solid fa-location-arrow"></i>
                    </span>
                  </button>
                
                </div>
              </div>
            </a>
            <div class="institute__card--action mobile-only">   
              <button class="btn btn-primary" [routerLink]="'/institute/'+item.urlCode" fragment="contactForm">Contact</button>
              <button class="btn btn-outline-primary" (click)="onDirectionsClick(item.coordinates)">
                {{getDistances(item.distance)}}
                <span>
                  <i class="fa-solid fa-location-arrow"></i>
                </span>
              </button>
            </div>
          </div>
        }
    }
    </div>
  </main>
  <aside>
    <div class="popular">
      <div class="popular__title">Popular in your Area</div>
      <div class="popular__list">
        <ngb-carousel>
          @for (item of popularInstitutes; track item._id) {
            <ng-template ngbSlide>
              <app-popular-card [routerLink]="'/institute/'+item.urlCode"
              [instituteName]="item.instituteName"
              [instituteAddress]="item.instituteAddress"
              [instituteLogo]="SHIKSHATAMBUCKET_URL + item.instituteLogo"
              [teacherCountAndExperience]="getTeacherAndExperienceText(item)"
              [typeList]="item.type"
              ></app-popular-card>
            </ng-template>
          }
        </ngb-carousel>
      </div>
      @if(trendingSearchItems$ | async; as items){
        <div class="trending">
          <div class="trending__title">Trending Searches</div>
          <div class="trending__list">
            @for(item of items.slice(0,6); track item.route){
              <div
                class="trending__list--item"
                [routerLink]="item.route" [queryParams]="item.queryParams"
              >
                {{item.name}}
              </div>
            }

          </div>
        </div>
      }
    </div>
    <div class="cta">
      <div class="cta__info">
        <div class="cta__info--title">Are you a tutor or an institute?</div>
        <div class="cta__info--subtitle">
          Join the growing network of 2000+ gurus for free and get found online
          easily online!
        </div>
        <div class="cta__info--action">
          <button class="btn btn-light" [routerLink]="'/enquiry'" routerLinkActive="router-link-active" >Join as Guru</button>
        </div>
      </div>
    </div>
  </aside>
</div>

@if(faq$ | async;){
  <div class="faq">
    <app-faq 
      [faq$]="faq$"
      [title]="'FAQs about ' + categoryName + ' in ' + cityName"
    ></app-faq>
  </div>
}

<app-trending-searches [items$]="trendingSearchItems$"
labelText="name"></app-trending-searches>
<app-footer></app-footer>
